import { ChatsList, ChatsListItem } from '../models/CommunicationActionPanel';

export const setLastMessageToRead = (
  id: number,
  recentChats: ChatsList,
  setRecentChats: React.Dispatch<React.SetStateAction<ChatsList>>
) => {
  const currentChat = recentChats.filter(
    (chat: ChatsListItem) => chat.chatsId === id
  );
  if (currentChat[0].unreadMessageCount === 0) return;

  const updatedRecentChatsList = recentChats.map((chat: any) => {
    if (chat.chatsId === id)
      return { ...currentChat[0], unreadMessageCount: 0 };
    return chat;
  });
  setRecentChats(updatedRecentChatsList);
};
