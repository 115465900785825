/**Standard Avamae Endpoint Response**/
export interface ApiResponse<T> {
  details: T;
  status: '1' | '0';
  errors: any;
}

/* Shared */

export interface Response<T> {
  data: T | null;
  error: { message: string | null | undefined } | null;
}

export class BaseApi {
  public static isResponseSuccessful = (response: ApiResponse<any>) =>
    response.status === '1';

  public static successResponse = function<T>(data: T): Response<T> {
    return {
      data,
      error: null,
    };
  };

  public static errorResponse = (message: string): Response<null> => {
    return {
      data: null,
      error: {
        message,
      },
    };
  };
}
