import React from 'react';
import { getIn } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import { setMilliseconds } from 'date-fns';

const Input = ({
  label,
  field,
  placeholder,
  form: { setFieldValue, errors, touched },
  icon,
  additionalOnChangeHandler,
  selected,
}: any) => {
  const errorMessage = getIn(errors, field.name);
  const fieldTouched = getIn(touched, field.name);

  return (
    <div className="AvailabilityInput">
      <div className="AvailabilityInput__field">
        <label htmlFor={field.name}>{label}</label>
        <DatePicker
          {...field}
          placeholderText={placeholder}
          dateFormat="h:mm aa dd/MM/yyyy"
          selected={field.value ? new Date(field.value) : selected}
          onSelect={val => {
            setFieldValue(field.name, setMilliseconds(val, 0));
          }}
          onChange={(val: any) => {
            setFieldValue(field.name, setMilliseconds(val, 0));
            if (additionalOnChangeHandler) {
              additionalOnChangeHandler(field.name, val);
            }
          }}
          showTimeSelect
          minTime={setHours(setMinutes(new Date(), 0), 9)}
          maxTime={setHours(setMinutes(new Date(), 0), 18)}
          autoComplete="off"
        ></DatePicker>
        {/* <pre>{JSON.stringify(field, null, 2)}</pre> */}
        {icon ? <img className="AvailabilityInput__icon" src={icon} /> : ''}
      </div>
      {fieldTouched && <div className="error">{errorMessage}</div>}
    </div>
  );
};

export default Input;
