export enum CallTypes {
  VIDEO = 'Video',
  VOICE = 'Voice',
}

export interface IPagination {
  pageNumber: number;
  pageSize: number;
}

export class CallsParser {
  public static buildJoinRoomPayload = (
    selectedChatId: number,
    type: string
  ) => ({ chatsid: selectedChatId?.toString(), type });

  public static createRoom = (data: any, room: any, callType: CallTypes) => ({
    room,
    title: data.title,
    imageUrl: data.image,
    callStartDate: data.callStartDate,
    type: callType,
    callsId: data.callsId,
  });

  public static parseError = (errors: any) => {
    let error: string;
    switch (errors[0].messageCode) {
      default:
        error = 'Sorry, something went wrong. Please try again';
    }
    return error;
  };
}
