export type EventActionsItem = {
  actionCode: string;
  actionDetails: {
    ButtonText: string;
    LinkUrl: string;
  };
  actionType: string;
};

export type EventItem = {
  eventsId: number;
  title?: string;
  description?: string | null;
  eventType?: {
    code: string;
    displayName: string;
  };
  eventActions?: EventActionsItem[];
  startDate: string;
  endDate: string;
  status?: string;
  addDate?: string;
  participants?: {
    siteUsersId: number;
    name: string;
    entityTypeCode: string;
    windowsTimeZoneId: string;
  }[];
  labels?: any[];
  bOwner?: boolean;
};

export type AvailableSlotItem = {
  startDate: Date;
  endDate: Date;
  notes: string;
  timeZone: string;
  id: number;
};

/*State*/
type State = {
  events: EventItem[];
  updateEvents: boolean;
  availableSlots: AvailableSlotItem[] | null;
  hideEvents?: boolean;
};

const initialState: State = {
  events: [],
  updateEvents: true,
  availableSlots: [],
  hideEvents: false,
};

/* ACTIONS */
const SET_EVENTS_LIST = 'app/calendar/SET_EVENTS_LIST';
const SET_HIDE_EVENTS = 'app/calendar/SET_HIDE_EVENTS';
const SET_UPDATE_EVENTS = 'app/calendar/SET_UPDATE_EVENTS';
const SET_AVAILABLE_SLOTS = 'app/calendar/SET_AVAILABLE_SLOTS';

type Action =
  | {
      type: typeof SET_EVENTS_LIST;
      payload: EventItem[];
    }
  | {
      type: typeof SET_UPDATE_EVENTS;
      payload: boolean;
    }
  | {
      type: typeof SET_AVAILABLE_SLOTS;
      payload: AvailableSlotItem[];
    }
  | {
      type: typeof SET_HIDE_EVENTS;
      payload: boolean;
    };

/* REDUCER */
export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case SET_EVENTS_LIST:
      return {
        ...state,
        events: action.payload,
      };
    case SET_UPDATE_EVENTS:
      return {
        ...state,
        updateEvents: action.payload,
      };
    case SET_HIDE_EVENTS:
      return {
        ...state,
        hideEvents: action.payload,
      };
    case SET_AVAILABLE_SLOTS:
      return {
        ...state,
        availableSlots: action.payload,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS */
export const setUpdateEvents = (payload: boolean): Action => ({
  type: SET_UPDATE_EVENTS,
  payload,
});

export const setHideEvents = (payload: boolean): Action => ({
  type: SET_HIDE_EVENTS,
  payload,
});

export const setEventsList = (payload: EventItem[]): Action => ({
  type: SET_EVENTS_LIST,
  payload,
});

export const setAvailableSlots = (payload: AvailableSlotItem[]): Action => ({
  type: SET_AVAILABLE_SLOTS,
  payload,
});

/* SELECTORS */
export const selectEvents = (app: any) => app.calendar.events;

export const selectUpdateEvents = (app: any) => app.calendar.updateEvents;

export const selectHideEvents = (app: any) => app.calendar.hideEvents;

export const selectAvailableSlots = (app: any) => app.calendar.availableSlots;
