// import React, { useState } from 'react';
import React, { useState } from 'react';
import { translations } from '../helpers/translations';
import RightSideMenu from './RightSideMenu';
import { FormikValues } from 'formik';

type Props = {
  isOpen: boolean;
  closeMenu: () => void;
  signOut: () => {};
  CustomForm: React.ComponentType<any>;
  customFormDetails: { title: string; addBtn: string };
  showMenu: boolean;
};

const CustomMeetingFormWrapper: React.FC<Props> = ({
  isOpen,
  closeMenu,
  signOut,
  customFormDetails,
  CustomForm,
  showMenu,
}) => {
  const { title, addBtn } = customFormDetails;
  // const formikSubmit = useRef<FormikValues | null>(null);
  const [formikRef, setFormikRef] = useState<FormikValues | null>(null);

  return (
    <>
      <RightSideMenu
        title={title}
        isOpen={isOpen}
        disabled={false}
        buttonText1={translations.general_messages.cancel ?? ''}
        buttonText2={addBtn ?? ''}
        buttonType2="button"
        buttonAction2={formikRef !== null ? formikRef.submitForm : undefined}
        button2Loading={false}
        errors={[]}
        showMenu={showMenu}
      >
        <CustomForm setFormikRef={setFormikRef} />
      </RightSideMenu>
    </>
  );
};

export default CustomMeetingFormWrapper;
