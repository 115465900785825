export function deepGet(obj: any, path: string) {
  const segments = [path];
  let i = 0;
  let val = obj;
  for (; i < segments.length; i++) {
    if (val === undefined) {
      return val;
    }
    val = val[segments[i]];
  }
  return val;
}

export type Errors = Error[];

type Error = {
  fieldName: string;
  messageCode: string;
};

export const getGeneralErrors = (
  errors: Errors,
  errorCodes: any,
  initialValues: any,
  setFieldError: any,
  nestedValues: null | string = null
) => {
  function isObjKey<T>(key: any, obj: T): key is keyof T {
    return key in obj;
  }
  if (nestedValues) {
    errors.map((e: Error) => {
      const index = (
        parseInt(e.fieldName.split("[")[1].split("]")[0]) + 1
      ).toString();
      const field = nestedValues + index;
      const subfield = e.fieldName.split(".")[1];
      const fiedlName = field + "." + subfield;

      const message =
        isObjKey(field, initialValues) && errorCodes[e.messageCode];
      setFieldError(fiedlName, message);
    });

    const filterGeneralErrors = errors.filter(
      (e: Error) =>
        !isObjKey(
          nestedValues +
            (parseInt(e.fieldName.split("[")[1].split("]")[0]) + 1).toString(),
          initialValues
        )
    );

    const errorArray = filterGeneralErrors.map((e: Error) => e.messageCode);

    const generalErrors = errorArray.map((e: string) => {
      if (isObjKey(e, errorCodes)) return errorCodes[e];
      return null;
    });
    return generalErrors;
  } else {
    errors.map((e: Error) => {
      const message =
        isObjKey(e.fieldName, initialValues) && errorCodes[e.messageCode];
      setFieldError(e.fieldName, message);
    });

    const filterGeneralErrors = errors.filter(
      (e: Error) => !isObjKey(e.fieldName, initialValues)
    );

    const errorArray = filterGeneralErrors.map((e: Error) => e.messageCode);

    const generalErrors = errorArray.map((e: string) => {
      if (isObjKey(e, errorCodes)) return errorCodes[e];
      return null;
    });
    return generalErrors;
  }
};

export const getRequestGeneralErrors = (errors: any, errorCode: any) => {
  function isObjKey<T>(key: any, obj: T): key is keyof T {
    return key in obj;
  }
  return errors
    .map((e: any) => e.messageCode)
    .map((e: string) => {
      if (isObjKey(e, errorCode)) return errorCode[e];
      return e;
    });
};
//Pound symbol is not displaying correctly
export const fixPoundSymbol = (data: any) => {
  function isObjKey<T>(key: any, obj: T): key is keyof T {
    return key in obj;
  }

  let details: any = data.details;

  if (isObjKey("GrossAmount", data.details)) {
    details = {
      ...details,
      GrossAmount: `£${data.details["GrossAmount"].slice(1)}`,
    };
  }
  if (isObjKey("TaxAmount", data.details)) {
    details = {
      ...details,
      TaxAmount: `£${data.details["TaxAmount"].slice(1)}`,
    };
  }
  return details;
};

export const getErrorsFromFormBuilder = (
  errors: Errors,
  errorCodes: any,
  initialValues: any,
  setFieldError: any
) => {
  function isObjKey<T>(key: any, obj: T): key is keyof T {
    return key in obj;
  }

  errors.map((e: Error) => {
    const message =
      isObjKey(e.fieldName, initialValues) && errorCodes[e.messageCode];
      setFieldError(
      e.fieldName.charAt(0).toLowerCase() + e.fieldName.slice(1),
      message
    );
  });

  const filterGeneralErrors = errors.filter(
    (e: Error) => !isObjKey(e.fieldName, initialValues)
  );

  const errorArray = filterGeneralErrors.map((e: Error) => e.messageCode);

  const generalErrors = errorArray.map((e: string) => {
    if (isObjKey(e, errorCodes)) return errorCodes[e];
    return null;
  });
  return generalErrors;
};
