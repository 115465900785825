export const translations = {
  days: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
  },
  periods: {
    day: 'Day',
    week: 'Week',
  },
  meetings: {
    cancel_meeting: 'Cancel Meeting',
    new_meeting: 'New Meeting',
    update_meeting: 'Update Meeting',
    add_availability: 'Add Availability',
    add_edit_availability: 'Add/Edit Availability',
    update_availability: 'Update Availability',
    availability_added: 'Availability Added',
    availability_updated: 'Availability Updated',
  },
  months: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
  },
  errors: {
    something_went_wrong_try_again: 'Something went wrong, please try again',
    required: 'Required',
    invalid_date: 'Invalid Date',
  },
  general_messages: {
    cancel: 'Cancel',
    apply: 'Apply',
    add_note: 'Add Note',
    decline: 'Decline',
    type_your_message_here: 'Type Your Message Here',
    type_your_search_here: 'Type Your Search Here',
    update_message: 'Update Message',
    accepted: 'Accepted',
    declined: 'Declined',
    requested: 'Requested',
    accept: 'Accept',
    title: 'Title',
    start_date: 'Start Date',
    end_date: 'End Date',
    communication_type: 'Communication Type',
    voice: 'Voice',
    video: 'Video',
    contacts: 'Contacts',
    present: 'Present',
    with: 'with',
    mediator_present: 'Mediator Present',
  },
};
