import { formatISO } from 'date-fns';
import { EventDetails } from '../components/models';

export type CreateAvailabilityRequest = {
  notes: string;
  StartDate: string;
  EndDate: string;
  AvailabilityType: string;
  timeZone: string;
};

const parseAvailabilityRequestSlots = (availableSlots: any) => {
  return availableSlots.map((slot: any) => {
    return {
      StartDate: `${formatISO(slot.startDate).slice(0, 19)}Z`,
      EndDate: `${formatISO(slot.endDate).slice(0, 19)}Z`,
      notes: '',
      AvailabilityType: 'Available',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
  });
};

export const newParseAvailabilityRequest = (availableSlots: any) => {
  const parsedSlots = parseAvailabilityRequestSlots(availableSlots);
  const overallStartDate = new Date().toISOString();
  const overallEndDate = new Date(
    new Date().getTime() + 5 * 525600 * 60000
  ).toISOString();
  return {
    availabilitiesStartDate: overallStartDate,
    availabilitiesEndDate: overallEndDate,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    createAvailabilityDtos: parsedSlots,
  };
};

export const parseEventsDetails = (events: EventDetails[]) => {
  // Code for merging cooldown periods with their associated event
  let associatedEvents: any = {};
  events.forEach(({ eventType, labels, endDate }) => {
    if (eventType.code === 'CoolDownPeriod') {
      const associatedEventId =
        Array.isArray(labels) && labels[0] && parseInt(labels[0].value);
      const associatedEvent = events.find(
        ({ eventsId }) => eventsId === associatedEventId
      );
      if (associatedEvent) {
        associatedEvent.endDate = endDate;
      }
      associatedEvents = {
        ...associatedEvents,
        // Only add property if associatedEvent is defined
        ...(associatedEvent ? { associatedEventId: associatedEvent } : {}),
      };
    }
  });
  // If event has an associated cooldown period, return the event merged with its cooldown period and delete the cooldown period as a separate event
  return events
    .map(event => {
      if (event.eventType.code === 'CoolDownPeriod') {
        return;
      }
      if (event.eventsId in associatedEvents) {
        return associatedEvents[event.eventsId];
      } else {
        return event;
      }
    })
    .filter(event => event);
};
