import React from 'react';
import {
  selectToastBackgroundColor,
  selectToastMessage,
  selectToastStatus,
} from '../reducers/toast';
import { getItemFromLocalStorage } from '../helpers/localStorage';
import { decodeHTML } from '../helpers/formatting';
import '../styles/Toast.scss';

type ToastProps = {
  useSelector: any;
};

const Toast: React.FC<ToastProps> = ({ useSelector }) => {
  const toastStatus = useSelector(selectToastStatus);
  const toastMessage = useSelector(selectToastMessage);
  const backgroundColor = useSelector(selectToastBackgroundColor);
  const accessibility = getItemFromLocalStorage('accessibility');

  function colorToClassname(c: string): string {
    switch (c) {
      case 'green':
        return accessibility ? '#245624' : '#84c779';
      case 'red':
        return accessibility ? '#903c3c' : '#c77979';
      default:
        return c;
    }
  }

  return (
    <div
      className={`CalendarToast ${
        toastStatus && toastMessage ? 'Show' : 'Hide'
      }`}
      style={{ backgroundColor: colorToClassname(backgroundColor) }}
    >
      <h4
        className="Message"
        dangerouslySetInnerHTML={{ __html: decodeHTML(toastMessage) }}
      />
    </div>
  );
};

export default Toast;
