import React, { useEffect } from 'react';
import { ColorSchema, Endpoints, FormTypes } from '../models/types';
import { selectShowActionsPanel, setSelectedChatId } from '../reducers/chats';
import Chat from './Chat';
import CommunicationActionPanel from './CommunicationActionPanel';

type Props = {
  pagePath?: string;
  instance: any;
  baseURL: string;
  signalRBaseURL: string;
  accessToken: string | null;
  callSignalRConnection?: any;
  endpoints: Endpoints;
  sendMessageFormType: FormTypes;
  sendMessageAdditionalParams?: any;
  colorSchema: ColorSchema;
  videoCall: boolean;
  phoneCall: boolean;
  allowAttachments: boolean;
  attachmentMaxSizeInMB?: number;
  attachmentTypesAllowed?: string[];
  hasContactList: boolean;
  urlChatId: number | null;
  useDispatch: any;
  useSelector: any;
  chatHeaderButtonHandler?: any;
  chatHeaderToggleHandler?: any;
};

const Communications: React.FC<Props> = ({
  pagePath,
  instance,
  baseURL,
  signalRBaseURL,
  accessToken,
  callSignalRConnection,
  endpoints,
  sendMessageFormType,
  sendMessageAdditionalParams,
  colorSchema,
  videoCall,
  phoneCall,
  allowAttachments,
  attachmentMaxSizeInMB,
  attachmentTypesAllowed,
  hasContactList,
  urlChatId,
  useDispatch,
  useSelector,
  chatHeaderButtonHandler,
  chatHeaderToggleHandler,
}) => {
  const dispatch = useDispatch();
  const showPanel = useSelector(selectShowActionsPanel);

  useEffect(() => {
    if (urlChatId) {
      dispatch(setSelectedChatId(urlChatId));
    }

    return () => {
      dispatch(setSelectedChatId(null));
    };
  }, []);

  return (
    <div className="CommunicationsModule MainContainer">
      <section className={`ChatContainer ${showPanel ? 'Hidden' : 'Visible'}`}>
        <Chat
          instance={instance}
          baseURL={baseURL}
          signalRBaseURL={signalRBaseURL}
          accessToken={accessToken}
          callSignalRConnection={callSignalRConnection}
          endpoints={endpoints}
          sendMessageFormType={sendMessageFormType}
          sendMessageAdditionalParams={sendMessageAdditionalParams}
          colorSchema={colorSchema}
          videoCall={videoCall}
          phoneCall={phoneCall}
          allowAttachments={allowAttachments}
          attachmentMaxSizeInMB={attachmentMaxSizeInMB}
          attachmentTypesAllowed={attachmentTypesAllowed}
          pagePath={pagePath}
          useDispatch={useDispatch}
          useSelector={useSelector}
          chatHeaderButtonHandler={chatHeaderButtonHandler}
          chatHeaderToggleHandler={chatHeaderToggleHandler}
        />
      </section>
      <section className={`PanelContainer ${showPanel ? 'Visible' : 'Hidden'}`}>
        <CommunicationActionPanel
          instance={instance}
          baseURL={baseURL}
          pagePath={pagePath}
          endpoints={endpoints}
          colorSchema={colorSchema}
          hasContactList={hasContactList}
          urlChatId={urlChatId}
          useDispatch={useDispatch}
          useSelector={useSelector}
        />
      </section>
    </div>
  );
};

export default Communications;
