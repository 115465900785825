import { Action } from 'redux';
import { CallsApi } from '../api/CallsApi';
import { CallsParser, CallTypes } from '../models/Calls';
import { storeInLocalStorage } from './localStorage';

const { connect } = require('twilio-video');

export const joinCall = async (
  instance: any,
  baseURL: string,
  selectedChatId: number,
  type: CallTypes,
  setRoomInstance: (room: any) => Action
) => {
  const result = await CallsApi.joinRoom(
    instance,
    baseURL,
    selectedChatId,
    type
  );
  if (result.data) {
    const roomName = result.data.roomName;
    const twillioToken = result.data.token;

    getRoom(twillioToken, roomName, type)
      .then((room: any) => {
        const roomInstance = CallsParser.createRoom(result.data, room, type);
        setRoomInstance(roomInstance);
        storeCallInLocalStorage(result.data, type);
      })
      .catch(err => {
        //TODO:handle error
      });
  }
  if (result.error) {
  }
};

const getRoom = async (
  twillioToken: string,
  roomName: string,
  callType: CallTypes
) =>
  await connect(twillioToken, {
    name: roomName,
    audio: true,
    video: callType === CallTypes.VIDEO ? true : false,
  });

const storeCallInLocalStorage = (data: any, callType: CallTypes) =>
  storeInLocalStorage(
    {
      roomName: data.roomName,
      callsId: data.callsId,
      callType: callType,
    },
    'ongoingCall'
  );
