import React, { useState } from 'react';
import { selectEvents, setEventsList } from '../reducers/calendar';
import { EventItem } from '../models/types';
import { Spinner } from './Spinner';
import Modal from './Modal';
import { translations } from '../helpers/translations';
type Props = {
  id: number;
  closeModal: () => void;
  isOpen: boolean;
  cancelMeetingUrl: string;
  signOut: () => {};
  instance: any;
  useDispatch: any;
  useSelector: any;
};

const CancelMeetingModal: React.FC<Props> = ({
  id,
  closeModal,
  isOpen,
  cancelMeetingUrl,
  signOut,
  instance,
  useDispatch,
  useSelector,
}) => {
  const dispatch = useDispatch();
  const events = useSelector(selectEvents);

  const [generalError, setGeneralError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const cancelMeeting = () => {
    if (generalError) setGeneralError('');

    setIsLoading(true);

    instance
      .post(cancelMeetingUrl, { eventsid: id })
      .then((res: any) => {
        if (res.status === 200) {
          if (res.data.status === '1') {
            const updatedEventsList = events.filter(
              (e: EventItem) => e.eventsId !== id
            );
            dispatch(setEventsList(updatedEventsList));
            setIsLoading(false);
            closeModal();
          }
        }
      })
      .catch((err: any) => {
        console.log(err);
        if (err.response && err.response.status === 403) {
          dispatch(signOut());
        }
        setIsLoading(false);
        setGeneralError(
          translations.errors.something_went_wrong_try_again ?? ''
        );
      });
  };

  return (
    <Modal
      header={'Cancel meeting'}
      buttonText1={translations.general_messages.cancel ?? ''}
      buttonText2={translations.general_messages.apply ?? ''}
      colorButton2="red"
      closeModal={closeModal}
      action={cancelMeeting}
      isOpen={isOpen}
      loading={isLoading ? <Spinner color={'white'} size={10} /> : null}
    >
      <div>
        <p style={{ marginBottom: 0 }}>
          {translations.meetings.cancel_meeting ?? ''}
        </p>
        {generalError && <div className="GeneralError">{generalError}</div>}
      </div>
    </Modal>
  );
};

export default CancelMeetingModal;
