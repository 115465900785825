import React, { ChangeEvent, useState } from 'react';

type ToggleSwitchProps = {
  onChange: (e: any) => any;
  externalValue: boolean;
};

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  onChange,
  children,
  externalValue,
}) => {
  const [isChecked, setIsChecked] = useState(externalValue);
  // Update checked to default to value returned by endpoint

  return (
    <div className="Section ToggleSection">
      <div className="Text">{children}</div>

      <div className="ToggleContainer">
        <label className="ToggleSwitch">
          <input
            checked={isChecked}
            type="checkbox"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              onChange(!isChecked);
              setIsChecked(checked => !checked);
            }}
          />
          <span></span>
        </label>
      </div>
    </div>
  );
};

export default ToggleSwitch;
