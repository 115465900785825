import React from 'react';
import { ThreeBounce } from 'better-react-spinkit';
import { getItemFromLocalStorage } from '../helpers/localStorage';

interface SpinnerProps {
  size?: number;
  color?: string | 'white' | 'blue' | 'grey';
  scaleStart?: number;
  scaleEnd?: number;
  positionAbsolute?: boolean;
  style?: { [key: string]: string | number };
}

const Spinner: React.FC<SpinnerProps> = ({
  size = 10,
  color = 'grey',
  positionAbsolute,
  style,
}) => {
  const accessibility = getItemFromLocalStorage('accessibility');

  function colorToClassname(c: string | 'white' | 'blue' | 'grey'): string {
    switch (c) {
      case 'blue':
        return accessibility ? '#1d51b9' : '#3069de';
      case 'white':
        return '#ffffff';
      case 'grey':
        return '#37434f';
      default:
        return '';
    }
  }

  return (
    <div
      className={positionAbsolute ? 'Spinner Absolute' : 'Spinner'}
      style={style ?? {}}
    >
      <ThreeBounce size={size} color={colorToClassname(color)} />
    </div>
  );
};

export { Spinner };
