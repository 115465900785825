import React, { useState } from 'react';
import { setEventsList } from '../reducers/calendar';
import { useOnClickOutside } from '@avamae/hooks';
import {
  getMaximumNumberOfOverlappingMeetings,
  getMeetingWidth,
  getNumberOfSlotsPerMeeting,
  updatedMeetingStatus,
  isCurrentMetting,
  getNumberOfOverlappingMeetingsStartingBefore,
} from '../helpers/calendarViews';
import { EventActionsItem, EventItem } from '../models/types';
import DeclineForm from './DeclineForm';
import NewMeetingForm from './NewMeetingForm';
import CancelMeetingModal from './CancelMeetingModal';
import VideoIcon from '../assets/Icon_VideoCall_01.png';
import PhoneIcon from '../assets/Icon_PhoneCall_01.png';
import RequestedIcon from '../assets/Icon_Tag_01.png';
import AcceptedIcon from '../assets/Icon_Tag_04.png';
import DeclinedIcon from '../assets/Icon_Tag_02.png';
import MoreIcon from '../assets/Icon_BtnUpdate.png';
import { translations } from '../helpers/translations';
type Props = {
  event: EventItem;
  events: EventItem[];
  acceptMeetingUrl: string;
  signOut: () => {};
  cancelMeetingUrl: string;
  createUrl: string;
  searchContactsUrl: string;
  editUrl: string;
  declineMeetingUrl: string;
  instance: any;
  useDispatch: any;
  useSelector: any;
};

const MeetingNotification: React.FC<Props> = ({
  event,
  events,
  acceptMeetingUrl,
  cancelMeetingUrl,
  signOut,
  createUrl,
  searchContactsUrl,
  editUrl,
  declineMeetingUrl,
  instance,
  useDispatch,
  useSelector,
}) => {
  const dispatch = useDispatch();
  const minIntervalInMinutes = 30;
  const minSlotIntervalHeigth = 90;
  const [isDeclineFormOpen, setDeclineFormOpen] = useState<boolean>(false);
  const [isMoreOptionsMenuOpen, setIsMoreOptionsMenuOpen] = useState<boolean>(
    false
  );
  const [isNewMeetingPanelOpen, setIsNewMeetingPanelOpen] = useState<boolean>(
    false
  );
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);

  const currentMetting = isCurrentMetting(event.startDate, event.endDate);

  const toogleDeclineFormOpen = () =>
    setDeclineFormOpen(prevState => !prevState);

  const toogleMoreOptionsMenu = () =>
    setIsMoreOptionsMenuOpen(prevState => !prevState);

  const toogleNewMeetingPanel = () =>
    setIsNewMeetingPanelOpen(prevState => !prevState);

  const toogleCancelModal = () => setIsCancelModalOpen(prevState => !prevState);

  const bind = useOnClickOutside(
    () => isMoreOptionsMenuOpen && toogleMoreOptionsMenu()
  );

  const numberOfSlotsPerMeeting = getNumberOfSlotsPerMeeting(
    event.startDate,
    event.endDate,
    minIntervalInMinutes
  );
  const meetingHeight = numberOfSlotsPerMeeting * minSlotIntervalHeigth;

  const maxNumberOfOverlappingMeetings = getMaximumNumberOfOverlappingMeetings(
    event.startDate,
    event.endDate,
    minIntervalInMinutes,
    events
  );

  const meetingOrder = getNumberOfOverlappingMeetingsStartingBefore(
    event.eventsId,
    event.startDate,
    events,
    []
  );

  const meetingWidth = getMeetingWidth(maxNumberOfOverlappingMeetings);
  const participantsNames = event.participants.map((p: any) => p.name);

  const leftPosition = 100 / (maxNumberOfOverlappingMeetings + 1);

  const acceptMeeting = () => {
    const bodyObj = {
      eventsId: event.eventsId,
    };
    instance
      .post(acceptMeetingUrl, bodyObj)
      .then((res: any) => {
        if (res.status === 200) {
          if (res.data.status === '1') {
            const updatedList = updatedMeetingStatus(
              events,
              'Accepted',
              event.eventsId
            );
            dispatch(setEventsList(updatedList));
          }
        }
      })
      .catch((err: any) => {
        console.log(err);
        if (err.response && err.response.status === 403) {
          dispatch(signOut());
        }
      });
  };

  return (
    <div
      className="MeetingNotification"
      style={{
        height: `${meetingHeight}px`,
        width: `${meetingWidth}%`,
        left: `${meetingOrder === 0 ? 0 : leftPosition * meetingOrder}%`,
      }}
    >
      <div className="MettingInfo">
        {event.eventType.code === 'VIDEO' && (
          <img src={VideoIcon} alt="Video call" />
        )}

        {event.eventType.code === 'VOICE' && (
          <img src={PhoneIcon} alt="Phone call" />
        )}

        <div className="MeetingTitle">
          <span>{event.title}</span>
          <span>
            {translations.general_messages.with +
              ' ' +
              participantsNames.join(', ')}
          </span>
        </div>
        <div className="More" {...bind}>
          {event.status !== 'Pending' && (
            <>
              <img src={MoreIcon} alt="More" onClick={toogleMoreOptionsMenu} />
              {isMoreOptionsMenuOpen && (
                <div className="MoreOptionsMenu">
                  {event.bOwner && (
                    <div onClick={toogleNewMeetingPanel}>
                      {translations.meetings.update_meeting ?? ''}
                    </div>
                  )}
                  <div onClick={toogleCancelModal}>
                    {translations.meetings.cancel_meeting ?? ''}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {event.status && (
        <div className="Status">
          {event.status === 'Accepted' && (
            <div className="Accepted">
              <img src={AcceptedIcon} alt="Requested" />
              {translations.general_messages.accepted ?? ''}
            </div>
          )}
          {event.status === 'Requested' && (
            <div className="Requested">
              <img src={RequestedIcon} alt="Requested" />
              {translations.general_messages.requested ?? ''}
            </div>
          )}
          {event.status === 'Declined' && (
            <div className="Declined">
              <img src={DeclinedIcon} alt="Declined" />
              {translations.general_messages.declined ?? ''}
            </div>
          )}
        </div>
      )}
      {event.status === 'Pending' && (
        <div className="Request">
          <div
            className={translations.general_messages.accept ?? ''}
            onClick={() => acceptMeeting()}
          >
            <span> {translations.general_messages.accept ?? ''}</span>
          </div>
          <div
            className={translations.general_messages.decline ?? ''}
            onClick={() => toogleDeclineFormOpen()}
          >
            <span> {translations.general_messages.decline ?? ''}</span>
          </div>
        </div>
      )}
      {(event.status === 'Accepted' || event.bOwner) &&
        event.eventActions.length > 0 &&
        currentMetting &&
        event.eventActions.map((a: EventActionsItem, k: number) => {
          if (a.actionType === 'LinkButton') {
            return (
              <a
                href={a.actionDetails.LinkUrl}
                target="_blank"
                className="CalendarLinkButton"
                key={k}
              >
                {a.actionDetails.ButtonText}
              </a>
            );
          }
          return '';
        })}
      {isDeclineFormOpen && (
        <DeclineForm
          id={event.eventsId}
          isOpen={isDeclineFormOpen}
          disabled={false}
          closeMenu={toogleDeclineFormOpen}
          declineMeetingUrl={declineMeetingUrl}
          signOut={signOut}
          instance={instance}
          useDispatch={useDispatch}
          useSelector={useSelector}
        />
      )}
      {isNewMeetingPanelOpen && (
        <NewMeetingForm
          isOpen={isNewMeetingPanelOpen}
          // disabled={false}
          closeMenu={toogleNewMeetingPanel}
          action={'edit'}
          id={event.eventsId}
          signOut={signOut}
          createUrl={createUrl}
          editUrl={editUrl}
          searchContactsUrl={searchContactsUrl}
          instance={instance}
          useDispatch={useDispatch}
          useSelector={useSelector}
        />
      )}
      {isCancelModalOpen && (
        <CancelMeetingModal
          id={event.eventsId}
          closeModal={toogleCancelModal}
          isOpen={isCancelModalOpen}
          cancelMeetingUrl={cancelMeetingUrl}
          signOut={signOut}
          instance={instance}
          useDispatch={useDispatch}
          useSelector={useSelector}
        />
      )}
    </div>
  );
};

export default MeetingNotification;
