import React from 'react';
import Calendar, { CalendarProps } from './components/Calendar';
import { useToast } from './hooks/useToast';

import calendar, {
  selectEvents,
  setAvailableSlots,
  setEventsList,
} from './reducers/calendar';
import calendarRightMenu, {
  selectRightMenuEventId,
  setCloseMenu,
  setOpenMenu,
} from './reducers/calendarRightMenu';
import toast from './reducers/toast';

export { toast };
export { calendar };
export { calendarRightMenu };

export {
  setAvailableSlots,
  setEventsList,
  selectEvents,
  setCloseMenu,
  setOpenMenu,
  selectRightMenuEventId,
  useToast,
};

export const CalendarBookingModule: React.FC<CalendarProps> = ({
  instance,
  useDispatch,
  useSelector,
  endpoints,
  setAvailabilityOnly,
  signOut,
  CustomForm,
  customFormDetails,
  CustomSlotTile,
  CustomMeetingTile,
  selectCustomAvailableSlots,
  selectCustomMeetingSlots,
  toggleSideMenuStatus,
  selectSideMenuStatus,
  externalEventsFetch,
  externalAvailabilitiesFetch,
  bookableMeetings,
  mainButtonText,
  hideEvents,
  responsiveButton,
}) => {
  return (
    <Calendar
      useDispatch={useDispatch}
      useSelector={useSelector}
      instance={instance}
      endpoints={endpoints}
      setAvailabilityOnly={setAvailabilityOnly}
      signOut={signOut}
      CustomForm={CustomForm}
      customFormDetails={customFormDetails}
      CustomSlotTile={CustomSlotTile}
      CustomMeetingTile={CustomMeetingTile}
      selectCustomAvailableSlots={selectCustomAvailableSlots}
      selectCustomMeetingSlots={selectCustomMeetingSlots}
      toggleSideMenuStatus={toggleSideMenuStatus}
      selectSideMenuStatus={selectSideMenuStatus}
      externalEventsFetch={externalEventsFetch}
      externalAvailabilitiesFetch={externalAvailabilitiesFetch}
      bookableMeetings={bookableMeetings}
      mainButtonText={mainButtonText}
      hideEvents={hideEvents}
      responsiveButton={responsiveButton}
    />
  );
};
