import { AvailableSlotItem } from '../reducers/calendar';

export type AvailabilitiesEndpointResponse = {
  addDate: string;
  availabilitiesId: number;
  availabilityType: number;
  endDate: string;
  notes: string;
  startDate: string;
};

export const parseAvailableSlots = (
  availabilitiesEndpointResponse: AvailabilitiesEndpointResponse[]
): AvailableSlotItem[] => {
  return availabilitiesEndpointResponse.map(
    ({
      availabilitiesId,
      endDate,
      notes,
      startDate,
    }: AvailabilitiesEndpointResponse) => ({
      id: availabilitiesId,
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      notes,
    })
  );
};
