import React from 'react';
import { Endpoints } from '../models/types';
import Attachment from './Attachment';

type Props = {
  attachments: any;
  instance: any;
  baseURL: string;
  endpoints: Endpoints;
  useDispatch: any;
  useSelector: any;
};

const AttachmentsContainer: React.FC<Props> = ({
  attachments,
  instance,
  baseURL,
  endpoints,
  useSelector,
  useDispatch,
}) => {
  return (
    <div className="Attachments">
      {attachments.map((attachment: any, index: number) => (
        <Attachment
          attachment={attachment}
          index={index}
          instance={instance}
          baseURL={baseURL}
          getAttachmentUrl={endpoints.chats.getMessageAttachment}
          useDispatch={useDispatch}
          useSelector={useSelector}
        />
      ))}
    </div>
  );
};

export default AttachmentsContainer;
