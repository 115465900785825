import React from 'react';
import '../styles/SearchBox.scss';

type Props = {
  handleSearchTermChange?: (value: string) => void;
  handleSearchSubmit?: () => void;
  handleKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  clearSearch?: boolean;
  handleClearSearch?: () => void;
  searchTerm?: string;
  placeholder?: string;
  readOnly?: boolean;
};

const SearchBox: React.FC<Props> = ({
  handleSearchTermChange,
  clearSearch,
  handleSearchSubmit,
  handleKeyUp,
  handleClearSearch,
  searchTerm,
  placeholder,
  readOnly = false,
}) => {
  return (
    <div className="SearchBoxContainer">
      <input
        className="SearchBox"
        type="text"
        placeholder={placeholder ?? ''}
        value={searchTerm && searchTerm}
        onChange={e =>
          handleSearchTermChange &&
          handleSearchTermChange(e.currentTarget.value)
        }
        onKeyUp={e => handleKeyUp && handleKeyUp(e)}
        disabled={readOnly}
      />
      {!clearSearch && (
        <div className="SubmitSearch" onClick={handleSearchSubmit}></div>
      )}
      {clearSearch && (
        <div
          className="ClearSearch"
          onClick={handleClearSearch && handleClearSearch}
        ></div>
      )}
    </div>
  );
};

export default SearchBox;
