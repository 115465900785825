import { ISentMessageResponse } from '../models/Chats';

export const sortMessages = (messages: ISentMessageResponse[]) => {
  return messages.sort((a: ISentMessageResponse, b: ISentMessageResponse) => {
    if (a.addDate > b.addDate) return -1;
    if (a.addDate < b.addDate) return 1;
    return 0;
  });
};

export default sortMessages;
