/*State*/
type State = {
  toastStatus: boolean;
  toastMessage: string;
  backgroundColor: string;
};

const initialState: State = {
  toastStatus: false,
  toastMessage: '',
  backgroundColor: 'green',
};

/* ACTIONS */

const SET_TOAST_MESSAGE = 'app/toast/SET_TOAST_MESSAGE';
const TOOGLE_TOAST = 'app/toast/TOOGLE_TOAST';
const SET_TOAST_BACKGROUND_COLOR = 'app/toast/SET_TOAST_BACKGROUND_COLOR';

type Action =
  | {
      type: typeof SET_TOAST_MESSAGE;
      payload: string;
    }
  | {
      type: typeof TOOGLE_TOAST;
    }
  | {
      type: typeof SET_TOAST_BACKGROUND_COLOR;
      payload: string;
    };

/* REDUCER */
export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case SET_TOAST_MESSAGE:
      return {
        ...state,
        toastMessage: action.payload,
      };
    case TOOGLE_TOAST:
      return {
        ...state,
        toastStatus: !state.toastStatus,
      };
    case SET_TOAST_BACKGROUND_COLOR:
      return {
        ...state,
        backgroundColor: action.payload,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS */
export const setToastMessage = (payload: string): Action => ({
  type: SET_TOAST_MESSAGE,
  payload,
});

export const toggleToast = (): Action => ({
  type: TOOGLE_TOAST,
});

export const setToastBackgroundColor = (payload: string): Action => ({
  type: SET_TOAST_BACKGROUND_COLOR,
  payload,
});

/* SELECTORS */
export const selectToastStatus = (app: any) => app.toast.toastStatus;

export const selectToastMessage = (app: any) => app.toast.toastMessage;

export const selectToastBackgroundColor = (app: any) =>
  app.toast.backgroundColor;
