import React, { useState } from 'react';
// import { useSelector } from 'react-redux';
import { INITIAL_CONTACTS_PAGINATION } from '../../appConstants';

//icons
import SearchIcon from '../../assets/Icon_Search_01.png';
import ClearIcon from '../../assets/Icon_Search_02.png';
import { IPagination } from '../../models/Calls';
// import { selectTranslations } from '../../reducers/translations';

interface ISearchBoxProps {
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  setUpdateChatsList: React.Dispatch<React.SetStateAction<boolean>>;
  setPagination: React.Dispatch<React.SetStateAction<IPagination>>;
}

const SearchBox: React.FC<ISearchBoxProps> = ({
  searchTerm,
  setSearchTerm,
  setUpdateChatsList,
  setPagination,
}) => {
  const [showClearSearch, setShowClearSearch] = useState<boolean>(false);
  // const translations = useSelector(selectTranslations);

  const handleSearchTermChange = (value: string) => {
    if (value === '') {
      return handleClearSearch();
    }
    setSearchTerm(value);
  };

  const handleSearchSubmit = () => {
    if (!searchTerm) return;
    setShowClearSearch(true);
    setPagination(INITIAL_CONTACTS_PAGINATION);
    setUpdateChatsList(true);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (searchTerm) handleSearchSubmit();
    }
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setShowClearSearch(false);
    setPagination(INITIAL_CONTACTS_PAGINATION);
    setUpdateChatsList(true);
  };

  return (
    <div className="SearchBoxContainer1">
      <input
        className="SearchBox"
        type="text"
        // placeholder={
        //   translations?.searchBoxPlaceholder ?? 'Type your search here'
        // }
        placeholder={'Type your search here'}
        value={searchTerm && searchTerm}
        onChange={e =>
          handleSearchTermChange &&
          handleSearchTermChange(e.currentTarget.value)
        }
        onKeyUp={e => handleKeyUp && handleKeyUp(e)}
      />
      {!showClearSearch && (
        <div className="SubmitSearch" onClick={handleSearchSubmit}>
          <img src={SearchIcon} alt="search" />
        </div>
      )}
      {showClearSearch && (
        <div className="ClearSearch" onClick={handleClearSearch}>
          <img src={ClearIcon} alt="clear search" />
        </div>
      )}
    </div>
  );
};

export default SearchBox;
