import React from 'react';
import { getDate } from 'date-fns';
import { Day } from '../models/types';

type Props = {
  weekDays: Day[];
  weekViewTimeSlotMinWidth: number;
};
const WeekHeader: React.FC<Props> = ({
  weekDays,
  weekViewTimeSlotMinWidth,
}) => {
  return (
    <div className="WeekHeader">
      <div className="HourColumn"></div>
      {weekDays.map((day: any, i: number) => (
        <div
          className="DayHeader"
          key={i}
          style={{
            minWidth: `${
              // day.overlappingMeetings
              //   ? day.overlappingMeetings * weekViewTimeSlotMinWidth
              //   : weekViewTimeSlotMinWidth
              weekViewTimeSlotMinWidth
            }px`,
          }}
        >
          <span>{getDate(day.date)}</span>
          <span>{day.weekDayName}</span>
        </div>
      ))}
    </div>
  );
};

export default WeekHeader;
