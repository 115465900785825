import React from 'react';
import { selectChatId } from '../reducers/chats';
import { Attachment } from '../models/types';
import { downloadFile } from '../helpers/downloadFile';
import { Spinner } from './ui/Spinner';

//icons
import DownloadIcon from '../assets/Icon_Action_02.png';
import { CustomError } from '../models/CustomError';

type Props = {
  attachment: Attachment;
  index: number;
  instance: any;
  baseURL: string;
  getAttachmentUrl: string | undefined;
  useDispatch: any;
  useSelector: any;
};

const Attachment: React.FC<Props> = ({
  attachment,
  index,
  instance,
  baseURL,
  getAttachmentUrl,
  useSelector,
  useDispatch,
}) => {
  const selectedChatId = useSelector(selectChatId);

  const { displayFileName, fileName } = attachment;

  const downloadAttachment = () => {
    if (!selectedChatId) return;
    if (!getAttachmentUrl) {
      throw new CustomError('Please provide the getAttachmentUrl prop');
    }
    downloadFile(
      instance,
      `${baseURL}${getAttachmentUrl}?chatsId=${selectedChatId}&filename=${fileName}`,
      displayFileName,
      document.getElementById(`spinner${fileName}`),
      document.getElementById(`icon${fileName}`)
    );
  };

  return (
    <div className="AttachmentContainer" key={index}>
      <div className="Attachment">
        <span>{displayFileName}</span>
        <img
          src={DownloadIcon}
          alt="Download"
          style={{ display: 'block' }}
          id={`icon${fileName}`}
          onClick={downloadAttachment}
        />
        <div id={`spinner${fileName}`} style={{ display: 'none' }}>
          <Spinner size={5} color={'#757575'} />
        </div>
      </div>
    </div>
  );
};

export default Attachment;
