import { Field, FieldArray, Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useToast } from '../hooks/useToast';
import RightSideMenu from './RightSideMenu';
import { translations } from '../helpers/translations';
import { newParseAvailabilityRequest } from '../helpers/calendarModuleParser';
import {
  AvailableSlotItem,
  selectAvailableSlots,
  setAvailableSlots,
} from '../reducers/calendar';
import uniqid from 'uniqid';
import AvailabilityFormDateTimeField from './AvailabilityFormDateTimeField';
import { useSelector } from 'react-redux';
import { parseAvailableSlots } from './availabilityApi';
import { getStringTime } from '../helpers/calendarViews';
import { utcToZonedTime } from 'date-fns-tz';
import { formatISO, parseISO } from 'date-fns';
import { setCloseMenu } from '../reducers/calendarRightMenu';

type Props = {
  isOpen: boolean;
  signOut: () => {};
  action: 'create' | 'edit';
  id?: number;
  createUrl: string;
  editUrl: string;
  detailsUrl: string;
  searchContactsUrl: string;
  instance: any;
  useDispatch: any;
  useSelector: any;
};

const CalendarSidePanel: React.FC<Props> = ({
  isOpen,
  // disabled,
  action,
  id,
  createUrl,
  editUrl,
  detailsUrl,
  signOut,
  instance,
  useDispatch,
}) => {
  const dispatch = useDispatch();
  const initialValues: { bookingSlots: AvailableSlotItem[] } = {
    bookingSlots: parseAvailableSlots(useSelector(selectAvailableSlots)),
  };
  const [generalErrors, setGeneralErrors] = useState<string[]>([]);
  const [endingTimeStart, setEndingTimeStart] = useState<Date | null>(null);
  const handleToast = useToast(
    translations.meetings.availability_updated,
    'green',
    3000,
    useDispatch
  );

  const validationSchema = Yup.object().shape({
    bookingSlots: Yup.array().of(
      Yup.object().shape({
        startDate: Yup.date()
          .typeError('Please enter a valid date')
          .required('Please enter a valid date')
          .when(
            'endTime',
            (endTime: Date, schema: any) =>
              endTime &&
              schema.max(endTime, 'Please select a time before the End Time')
          ),
        // .min(
        //   new Date(new Date().getTime() + 30 * 60000),
        //   'Please pick a start time at least 30 minutes in the future'
        // ),

        endDate: Yup.date()
          .typeError('Please enter a valid date')
          .required('Please enter a valid date')
          .min(
            Yup.ref('startDate'),
            'Please select a time after the Start Time'
          ), // these constraints take precedence
      })
    ), // these constraints are shown if and only if inner constraints are satisfied
  });

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const convertTimezone = (isoString: string) => {
    const date = new Date(isoString);
    const tzString = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return utcToZonedTime(date, tzString);
  };

  const handleSubmitForm = (values: any, formik: FormikHelpers<any>) => {
    const bodyObj = newParseAvailabilityRequest(values.bookingSlots);

    let generalErrorMessage: string[] = [];

    instance
      .post(createUrl, bodyObj)
      .then((res: any) => {
        if (res.status === 200) {
          if (res.data.status === '1') {
            let availabilitySlots;

            if (res.data.details.createdAvailabilityDtos) {
              availabilitySlots = res.data.details.createdAvailabilityDtos.map(
                ({ endDate, id, notes, startDate }: any) => ({
                  startDate,
                  endDate,
                  notes,
                  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  id,
                })
              );
            } else {
              availabilitySlots = [];
            }
            dispatch(setAvailableSlots(availabilitySlots));
            dispatch(setCloseMenu());
            handleToast();
          }
        }
        if (res.status === 400) {
          if (
            res.data.errors.some(
              (error: { fieldName: string; messageCode: string }) =>
                error.messageCode === 'Availability_Overlap'
            )
          ) {
            generalErrorMessage = [
              'Some of your chosen availability slots overlap, please select again',
            ];
          }
          generalErrorMessage =
            generalErrorMessage.length === 0
              ? [translations.errors.something_went_wrong_try_again ?? '']
              : generalErrorMessage;
          setGeneralErrors(generalErrorMessage);
        }
      })
      .catch((err: any) => {
        console.log(err);
        if (err && err.status === 403) {
          dispatch(signOut());
        }

        generalErrorMessage =
          generalErrorMessage.length === 0
            ? [translations.errors.something_went_wrong_try_again ?? '']
            : generalErrorMessage;
        setGeneralErrors(generalErrorMessage);
        formik.setSubmitting(false);
      });
  };

  const setEndingTimeRange = (fieldName: string, value: Date) => {
    setEndingTimeStart(value);
  };

  if (initialValues) {
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values, formik) => handleSubmitForm(values, formik)}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values, errors, isSubmitting }) => (
          <Form onKeyDown={onKeyDown}>
            <RightSideMenu
              title={translations.meetings.add_edit_availability ?? ''}
              isOpen={isOpen}
              buttonText1={translations.general_messages.cancel ?? ''}
              buttonText2={translations.general_messages.apply ?? ''}
              errors={generalErrors}
            >
              <div className="NewMeetingPanel">
                <>
                  <FieldArray name="bookingSlots">
                    {({ insert, remove, push }) => {
                      return (
                        <div className="Form__container">
                          {values.bookingSlots &&
                            values.bookingSlots.length > 0 &&
                            values.bookingSlots.map(
                              (slot: any, index: number) => (
                                <div className="row" key={index}>
                                  <div className="col">
                                    <Field
                                      name={`bookingSlots.${index}.startDate`}
                                      label={
                                        translations.general_messages
                                          .start_date ?? ''
                                      }
                                      placeholder={'dd / mm / yyyy'}
                                      component={AvailabilityFormDateTimeField}
                                      additionalOnChangeHandler={
                                        setEndingTimeRange
                                      }
                                    />
                                  </div>
                                  <div className="col">
                                    <Field
                                      name={`bookingSlots.${index}.endDate`}
                                      label={
                                        translations.general_messages
                                          .end_date ?? ''
                                      }
                                      placeholder={'dd / mm / yyyy'}
                                      component={AvailabilityFormDateTimeField}
                                      selected={endingTimeStart ?? new Date()}
                                    />
                                  </div>
                                  <div className="col">
                                    <button
                                      type="button"
                                      className="Button__secondary Delete"
                                      onClick={() => remove(index)}
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              )
                            )}
                          <button
                            type="button"
                            className="secondary"
                            onClick={() =>
                              push({ startDate: '', endDate: '', id: uniqid() })
                            }
                          >
                            Add Slot
                          </button>
                        </div>
                      );
                    }}
                  </FieldArray>
                </>
              </div>
            </RightSideMenu>
          </Form>
        )}
      </Formik>
    );
  }
  return <div></div>;
};

export default CalendarSidePanel;
