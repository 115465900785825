import { Store } from './rootReducers';

export type State = {
  translations: { [key: string]: string } | null;
};

const initialState: State = {
  translations: null,
};

// Actions
const SET_TRANSLATIONS = 'app/translations/SET_TRANSLATIONS';

// Reducer
type Action = {
  type: typeof SET_TRANSLATIONS;
  payload: { [key: string]: string };
};

export const translations = (state = initialState, action: Action): State => {
  switch (action.type) {
    case SET_TRANSLATIONS:
      return {
        ...state,
        translations: { ...state.translations, ...action.payload },
      };
    default:
      return state;
  }
};

// Action creators
export const setTranslations = (payload: { [key: string]: string }) => ({
  type: SET_TRANSLATIONS,
  payload,
});

// Selectors
export const selectTranslations = (store: Store) =>
  store.translations.translations;
