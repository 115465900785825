import React, { useRef, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import {
  selectChatId,
  selectChatsSignalRConnection,
  selectFooterHeight,
  setFooterHeight,
  setShowLastMessage,
} from '../reducers/chats';
import { ChatsApi } from '../api/ChatsApi';
import { FooterHeight } from '../appConstants';
import { ChatDetails, ChatsParser, MessageDetails } from '../models/Chats';
import { ColorSchema, Endpoints, FormTypes } from '../models/types';
import {
  checkForDuplicate,
  isAttachmentTypeAndSizeAllowed,
} from '../helpers/attachments';
import FormTextField from './form/FormTextField';
import InputAttachmentContainer from './InputAttachmentContainer';
import Button from './ui/Button';
import { Spinner } from './ui/Spinner';
import SendMessageIcon from '../assets/Icon_SendMessage.png';

//icon
import AttachIcon from '../assets/Icon_Attach.png';
import { selectTranslations } from '../reducers/translations';

interface IChatFooterProps {
  instance: any;
  baseURL: string;
  chatDetails: ChatDetails | null;
  setChatDetails: React.Dispatch<React.SetStateAction<ChatDetails | null>>;
  endpoints: Endpoints;
  sendMessageFormType: FormTypes;
  sendMessageAdditionalParams: any;
  colorSchema: ColorSchema;
  allowAttachments: boolean;
  attachmentMaxSizeInMB?: number;
  attachmentTypesAllowed?: string[];
  mobile: boolean;
  useDispatch: any;
  useSelector: any;
}

const ChatFooter: React.FC<IChatFooterProps> = ({
  instance,
  baseURL,
  chatDetails,
  setChatDetails,
  endpoints,
  sendMessageFormType,
  sendMessageAdditionalParams,
  colorSchema,
  allowAttachments,
  attachmentMaxSizeInMB,
  attachmentTypesAllowed,
  mobile,
  useSelector,
  useDispatch,
}) => {
  const dispatch = useDispatch();

  const selectedChatId = useSelector(selectChatId);
  const chatSignalRConnection = useSelector(selectChatsSignalRConnection);
  const footerHeight = useSelector(selectFooterHeight);
  const translations = useSelector(selectTranslations);

  const [submitError, setSubmitError] = useState<string>('');

  const validationSchema = Yup.object({
    message: Yup.string(),
    attachments: Yup.array(),
  });

  const inputFile: any = useRef(null);

  const handleFileUpload = (
    e: any,
    attachments: File[],
    setFieldValue: (field: string, value: File[]) => void,
    setFieldError: (field: string, error: string) => void
  ) => {
    setFieldError('attachments', '');
    const file = e.target.files[0];

    if (!file || checkForDuplicate(inputFile, file, attachments, setFieldError))
      return;
    if (
      !isAttachmentTypeAndSizeAllowed(
        file,
        attachmentMaxSizeInMB,
        attachmentTypesAllowed
      )
    ) {
      setFieldError(
        'attachments',
        translations?.chatFooterAttachmentError ??
          'File extension or size not allowed'
      );
      return;
    }
    const newState = [...attachments, file];
    setFieldValue('attachments', newState);
    updateFooterHeight(newState);
    inputFile.current.value = '';
  };

  const removeAttachment = (
    index: number,
    attachments: File[],
    setFieldValue: (field: string, value: File[]) => void
  ) => {
    const newValue = attachments;
    newValue.splice(index, 1);
    setFieldValue('attachments', newValue);
    updateFooterHeight(newValue);
  };

  const updateFooterHeight = (attachments: File[]) => {
    let height: number;
    if (attachments.length === 0) {
      height = mobile ? FooterHeight.MOBILE : FooterHeight.DEFAULT;
    } else {
      height = mobile
        ? FooterHeight.MOBILE_EXPANDED
        : FooterHeight.DEFAULT_EXPANDED;
    }
    dispatch(setFooterHeight(height));
  };

  const handleSubmitMessage = async (
    values: MessageDetails,
    formikActions: FormikHelpers<MessageDetails>
  ) => {
    setSubmitError('');
    if (!selectedChatId || !chatDetails) return;

    const result = await ChatsApi.sendMessage(
      instance,
      baseURL + endpoints.chats.sendChatMessage,
      selectedChatId,
      values.message,
      values.attachments,
      chatSignalRConnection?.connectionId,
      sendMessageFormType,
      sendMessageAdditionalParams
    );

    if (result.data) {
      formikActions.setFieldValue('message', '');
      formikActions.setFieldValue('attachments', []);
      updateFooterHeight([]);
      const message = ChatsParser.createSuccessfullySentMessage(result.data);
      setChatDetails((prevState: ChatDetails | null) => {
        const newState = prevState
          ? {
              ...prevState,
              messages: [message, ...prevState.messages],
            }
          : null;
        return newState;
      });
      dispatch(setShowLastMessage(true));
    }
    if (result.error) {
      setSubmitError(
        translations?.chatFooterSubmitError ??
          'Something went wrong when submitting your message. Please try again.'
      );
    }
  };

  if (footerHeight)
    return (
      <div
        className="ChatFooter WidthContent"
        style={{
          height: footerHeight,
          background: colorSchema.secondaryColor,
        }}
      >
        <Formik
          initialValues={ChatsParser.createEmptyMessageDetails()}
          onSubmit={(values, formikActions) =>
            handleSubmitMessage(values, formikActions)
          }
          validationSchema={validationSchema}
        >
          {({ values, errors, setFieldValue, setFieldError, isSubmitting }) => (
            <Form>
              <div className="InputContainer">
                <div className="GeneralError">{errors.attachments}</div>
                <div className="GeneralError">{submitError}</div>
                <div className="MessageInput">
                  <FormTextField
                    field="message"
                    type="text"
                    rows={1}
                    placeholder={
                      translations?.chatFooterPlaceholder ??
                      'Type your message here'
                    }
                    required={true}
                    clearWhenChanged={selectedChatId}
                  />
                  <InputAttachmentContainer
                    attachments={values.attachments}
                    removeAttachment={removeAttachment}
                    updateFooterHeight={updateFooterHeight}
                    clearWhenChanged={selectedChatId}
                  />
                </div>
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  onChange={e =>
                    handleFileUpload(
                      e,
                      values.attachments,
                      setFieldValue,
                      setFieldError
                    )
                  }
                  style={{ display: 'none' }}
                  multiple
                />
                {allowAttachments && (
                  <Button
                    color={colorSchema.secondaryColor}
                    border={colorSchema.primaryColor}
                    type="button"
                    onClick={() =>
                      inputFile.current && inputFile.current.click()
                    }
                    disabled={!selectedChatId}
                  >
                    <img src={AttachIcon} alt="Attach" />
                  </Button>
                )}
              </div>
              <Button
                color={colorSchema.primaryColor}
                type="submit"
                disabled={
                  isSubmitting ||
                  (!values.message &&
                    (!values.attachments ||
                      (values.attachments &&
                        values.attachments.length === 0))) ||
                  !selectedChatId ||
                  (chatDetails &&
                  chatDetails?.messages.length > 0 &&
                  chatDetails?.status === 'Requested'
                    ? true
                    : false)
                }
              >
                {isSubmitting ? (
                  <Spinner color={'white'} size={1} />
                ) : (
                  <>
                    <img className="SendMessageIcon" src={SendMessageIcon} />
                    <span className="SendMessageText">
                      {translations?.chatFooterSendMessage ?? 'Send message'}
                    </span>
                  </>
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    );
  return null;
};

export default ChatFooter;
