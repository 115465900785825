import * as signalR from '@microsoft/signalr';
import { ChatDetails } from '../models/Chats';
import { ChatsList, ChatsListItem } from '../models/CommunicationActionPanel';
import { FilterOptions, SignalRConnection } from '../reducers/chats';

export const createSignalRConnection = (
  signalRBaseURL: string,
  chatId: number,
  accessToken: string
) =>
  new signalR.HubConnectionBuilder()
    .withUrl(signalRBaseURL + chatId, {
      accessTokenFactory: () => accessToken,
    })
    .withAutomaticReconnect()
    .build();

export const receiveMessages = (
  connection: SignalRConnection,
  setChatDetails: React.Dispatch<React.SetStateAction<ChatDetails | null>>,
  userId: number,
  showLastMessage: () => void
) =>
  connection.on('ReceiveMessage', message => {
    setChatDetails(prevState => {
      const updatedMessage = {
        ...message,
        type:
          message.senderCommunicationsModuleIdentifier === userId
            ? 'Sent'
            : 'Received',
        attachments: message.attachments ?? [],
      };
      const newState = prevState && {
        ...prevState,
        messages: [updatedMessage, ...prevState.messages],
      };
      return newState;
    });
    showLastMessage();
  });

export const updateRecentChatList = (
  connection: SignalRConnection,
  selectedFilter: FilterOptions,
  chatsList: ChatsList,
  setChatsList: React.Dispatch<React.SetStateAction<ChatsList>>,
  selectedChatId: number
) =>
  connection.on('UpdateRecentChatOnMessage', message => {
    if (selectedFilter === 'Contact') return;

    const updatedChat = chatsList.filter(
      (c: ChatsListItem) => c.chatsId === message.chatsId
    )[0];
    let newState;
    if (updatedChat) {
      newState = chatsList.map((c: ChatsListItem) => {
        if (c.chatsId === updatedChat.chatsId) {
          return {
            ...updatedChat,
            ...message,
            unreadMessageCount:
              message.chatsId === selectedChatId
                ? 0
                : message.unreadMessageCount,
          };
        }
        return c;
      });
    } else {
      newState = [message, ...chatsList];
    }
    setChatsList(newState);
  });

export const startConnection = (connection: SignalRConnection) =>
  connection
    .start()
    .then(() => {
      console.log('SignalR for chat connected');
    })
    .catch(err => console.log('SignalR connection error', err));
