import React from "react";
import { ErrorM } from "./ErrorM";
import { Input } from "./Input";

interface FormTextFieldProps<T> {
  field: string;
  label?: string;
  required: boolean;
  type?: string;
  rows?: number;
  hint?: string;
  optionalTag?: string;
  disabled?: boolean;
  placeholder?: string;
  sideBySide?: boolean;
  isVisible?: boolean;
  maxLength?: number;
  icon?: string;
  showIcon?: boolean;
  autofocus?: boolean;
  instructions?: string;
}

const FormTextField = function <T>({
  field,
  label,
  required,
  type,
  rows,
  hint,
  optionalTag,
  disabled,
  placeholder,
  sideBySide,
  isVisible = true,
  maxLength,
  icon,
  showIcon = false,
  autofocus = false,
  instructions,
}: FormTextFieldProps<T>) {

  return (
    <div
      className={`FormBox ${sideBySide && "SideBySide"} ${
        isVisible ? "" : "Hide"
      }`}
    >
      {label && (
        <div className="FormLabel">
          <label>
            {label}{" "}
            {!required && <span className="Optional">- {optionalTag}</span>}
          </label>
          {hint && <div className="FormHint">{hint}</div>}
        </div>
      )}
      <div className="FormField">
        <Input
          name={field}
          type={type ? type : "text"}
          rows={rows ? rows : 8}
          disabled={disabled}
          placeholder={placeholder}
          maxLength={maxLength}
          autoFocus={autofocus}
        />
        {showIcon && icon && <img src={icon} alt="" />}
        {instructions && <span>{instructions}</span>}
        <ErrorM name={field} />
      </div>
    </div>
  );
};

export default FormTextField;
