import React, { ReactChild } from 'react';
import { useLockBody } from '../hooks/useLockBody';
import { Transition } from 'react-transition-group';
import Button from './Button';
import CloseIcon from '../assets/Icon_Menu_02.png';
import { Spinner } from './Spinner';
import '../styles/RightSideMenu.scss';
import { useDispatch } from 'react-redux';
import { setCloseMenu } from '../reducers/calendarRightMenu';

type Props = {
  title: string;
  isOpen: boolean;
  children: ReactChild;
  disabled?: boolean;
  className?: string;
  hideFooter?: boolean;
  buttonText1?: string;
  buttonText2?: string;
  buttonType2?: 'button' | 'submit';
  buttonAction2?: () => void;
  resetButton?: JSX.Element;
  acceptDeclineButtons?: JSX.Element;
  hideButton2?: boolean;
  errors?: string[];
  button2Loading?: boolean;
  showMenu?: boolean;
};

const RightSideMenu: React.FC<Props> = ({
  title,
  isOpen,
  children,
  disabled = false,
  className,
  hideFooter,
  buttonText1,
  buttonText2,
  buttonType2 = 'submit',
  buttonAction2,
  resetButton,
  acceptDeclineButtons,
  hideButton2 = false,
  button2Loading = false,
  errors,
  showMenu,
}) => {
  const dispatch = useDispatch();

  const closeMenu = () => {
    dispatch(setCloseMenu());
  };

  const lock = useLockBody(isOpen);

  return (
    <Transition in={isOpen} timeout={100} mountOnEnter unmountOnExit>
      {transitionState => (
        <>
          <div
            className={`RightPanelUnderlay ${transitionState}`}
            onClick={closeMenu}
          />
          <div
            className={`RightSideMenu ${
              className ? className : ''
            } ${transitionState}`}
          >
            <div {...lock}>
              <div className="RightSideMenuHeader WidthContent">
                <h2>{title}</h2>
                <img src={CloseIcon} alt="Close" onClick={closeMenu} />
              </div>
              <div
                className={`RightSideMenuContent WidthContent ${hideFooter &&
                  'FullHeight'}`}
              >
                {children}
              </div>

              {!hideFooter && (
                <div className={`RightSideMenuFooter WidthContent`}>
                  {errors && (
                    <div className="GeneralErrors InnerWidthContent">
                      {errors.map((e: string) => (
                        <p>{e}</p>
                      ))}
                    </div>
                  )}
                  <Button color="transparent" type="button" onClick={closeMenu}>
                    {buttonText1}
                  </Button>
                  {/*filter panel on serach pages*/}
                  {resetButton}
                  {/*details panel on serach pages*/}
                  {acceptDeclineButtons}
                  {!hideButton2 && (
                    <>
                      {buttonType2 === 'submit' && (
                        <Button color="blue" type="submit" disabled={disabled}>
                          {buttonText2}
                        </Button>
                      )}
                      {buttonType2 === 'button' && (
                        <Button
                          color="blue"
                          type="button"
                          disabled={disabled}
                          onClick={buttonAction2}
                        >
                          {buttonText2}
                        </Button>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </Transition>
  );
};

export default RightSideMenu;
