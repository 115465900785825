import React, { useState } from "react";

import { SelectOption } from "./SelectDropdown";
import { useFormikContext } from "formik";
import { ErrorM } from "./ErrorM";
import SearchBox from "./SearchBox";

type Props = {
  id?: number;
  field: string;
  label?: string;
  required: boolean;
  type?: string;
  select: string;
  sideBySide?: boolean;
  options: SelectOption[] | any;
  selectedValues: SelectOption[];
  isVisible?: boolean;
  searchTermChange?: (values: string) => void;
  formSearchKey?: string;
  handleKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  clearSearch?: boolean;
  handleClearSearch?: () => void;
  handleSearchSubmit?: () => void;
  placeholder?: string;
  readOnly?: boolean;
};

const FormMultiselectDropdown: React.FC<Props> = ({
  id,
  field,
  label,
  required,
  type,
  select,
  sideBySide,
  options,
  selectedValues,
  isVisible = true,
  formSearchKey,
  searchTermChange,
  handleKeyUp,
  clearSearch,
  handleClearSearch,
  handleSearchSubmit,
  placeholder,
  readOnly = false,
}) => {
  const formikContext: any = useFormikContext();

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchResult, setSearchResult] = useState<SelectOption[]>(options);

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;

    //for frontend search
    setSearchTerm(value);
    const searchOptions = value
      ? options.filter((o: SelectOption) =>
          o.label.toLowerCase().includes(value.toLowerCase())
        )
      : options;
    setSearchResult(searchOptions);
  };

  const selectOrUnselectOption = (value: any) => {
    if (readOnly) return;
    if (select === "multiSelect") {
      const newValueField = formikContext.values[field];

      if (formikContext.values[field].includes(value)) {
        const indexOfValue = newValueField.indexOf(value);
        newValueField.splice(indexOfValue, 1);
      } else {
        newValueField.push(value);
      }
      formikContext.setFieldValue(field, newValueField);
    } else {
      const currentValue = formikContext.values[field];
      if (currentValue === value) {
        formikContext.setFieldValue(field, "");
      } else {
        formikContext.setFieldValue(field, value);
      }
    }
  };

  return (
    <div className="FormBox SideBySide MultiSelectDropdown">
      <div
        className="FormLabel"
        // style={{ marginBottom: "auto" }}
      >
        <label>{label}</label>
      </div>
      <div className={`FormField ${readOnly && "ReadOnly"}`}>
        {/* <div
          className={`SelectedOptionsContainer ${
            selectedValues.length > 0 && "Selected"
          }`}
        >
          <div>
            {select === "singleSelect"
              ? selectedValues
              : searchTermChange
              ? searchResult
                  .filter((o: any) => selectedValues.includes(o.value))
                  .map((f: any) => f.label)
                  .join(",")
              : options
                  .filter((o: any) => selectedValues.includes(o.value))
                  .map((f: any) => f.label)
                  .join(",")}
          </div>
          <img src={Down_Chevron} alt="Dropdown" />
        </div> */}

        <div className="OptionsContainer">
          {!searchTermChange ? (
            <div className="SearchBoxSection">
              <input
                className="SearchBox"
                type="text"
                placeholder={placeholder ?? ""}
                value={formSearchKey ? formSearchKey : searchTerm}
                onChange={handleSearchTermChange}
                disabled={readOnly}
              />

              <div className="SearchIcon" />
            </div>
          ) : (
            <div className="SearchBoxSection">
              <SearchBox
                handleSearchTermChange={searchTermChange}
                searchTerm={formSearchKey}
                clearSearch={clearSearch}
                handleSearchSubmit={handleSearchSubmit}
                handleKeyUp={handleKeyUp}
                handleClearSearch={handleClearSearch}
                placeholder={placeholder}
                readOnly={readOnly}
              />
            </div>
          )}

          <div className="FilterOptions">
            {searchTermChange
              ? options.map((o: SelectOption, i: number) => (
                  <div
                    className={`FilterOption ${
                      select === "singleSelect" && "Rounded"
                    }`}
                    key={i}
                    onClick={() => selectOrUnselectOption(o.value)}
                  >
                    <input
                      name={field}
                      type="checkbox"
                      id={`${label}${i}`}
                      checked={
                        select === "singleSelect"
                          ? formikContext.values[field] === o.value
                            ? true
                            : false
                          : formikContext.values[field].includes(o.value)
                          ? true
                          : false
                      }
                      onChange={() => formikContext.values[field]}
                      disabled={readOnly}
                    />
                    <label className="CustomInput" htmlFor={field}>
                      {o.label}
                    </label>
                  </div>
                ))
              : searchResult.map((o: SelectOption, i: number) => (
                  <div
                    className={`FilterOption ${
                      select === "singleSelect" && "Rounded"
                    }`}
                    key={i}
                    onClick={() => selectOrUnselectOption(o.value)}
                  >
                    <input
                      name={field}
                      type="checkbox"
                      id={`${label}${i}`}
                      checked={
                        select === "singleSelect"
                          ? formikContext.values[field] === o.value
                            ? true
                            : false
                          : formikContext.values[field].includes(o.value)
                          ? true
                          : false
                      }
                      onChange={() => formikContext.values[field]}
                      disabled={readOnly}
                    />
                    <label className="CustomInput" htmlFor={field}>
                      {o.label}
                    </label>
                  </div>
                ))}
          </div>
        </div>
        <ErrorM name={field} />
      </div>
    </div>
  );
};

export default FormMultiselectDropdown;
