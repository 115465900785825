import {
  getTimeSlotTimeStamp,
  maxGetMaximumOfOverlappingMeetingPerDay,
} from '../helpers/calendarViews';
import React from 'react';
import HalfHourSlot from './HalfHourSlot';
import { EventItem } from '../models/types';
import { AvailableSlotItem } from '../reducers/calendar';
import { CalendarProps } from './Calendar';
type Props = {
  date: Date;
  hour: number;
  events: EventItem[];
  timeSlotMinWidth: number;
  signOut: () => {};
  cancelMeetingUrl: string;
  acceptMeetingUrl: string;
  createUrl: string;
  searchContactsUrl: string;
  editUrl: string;
  declineMeetingUrl: string;
  instance: any;
  useDispatch: any;
  useSelector: any;
  availability: AvailableSlotItem[];
};

const TimeSlot: React.FC<Props &
  Pick<CalendarProps, 'CustomSlotTile' | 'CustomMeetingTile'>> = ({
  date,
  hour,
  events,
  timeSlotMinWidth,
  signOut,
  cancelMeetingUrl,
  acceptMeetingUrl,
  declineMeetingUrl,
  createUrl,
  searchContactsUrl,
  editUrl,
  instance,
  useDispatch,
  useSelector,
  availability,
  CustomSlotTile,
  CustomMeetingTile,
}) => {
  // Call useSelector and find if date and hour props match the array of dates and hours returned from a useSelector
  // call to get the assessor's available slots from the redux store.

  const numberOfOverlappingMeetings = maxGetMaximumOfOverlappingMeetingPerDay(
    date,
    events
  );
  const timeSlotWidth = numberOfOverlappingMeetings
    ? numberOfOverlappingMeetings * timeSlotMinWidth
    : timeSlotMinWidth;

  return (
    // <div className="TimeSlot" style={{ minWidth: `${timeSlotWidth}px` }}>
    <div className="TimeSlot" style={{ minWidth: `${timeSlotMinWidth}px` }}>
      <HalfHourSlot
        timeStamp={getTimeSlotTimeStamp(date, hour, 0)}
        events={events}
        signOut={signOut}
        cancelMeetingUrl={cancelMeetingUrl}
        acceptMeetingUrl={acceptMeetingUrl}
        declineMeetingUrl={declineMeetingUrl}
        createUrl={createUrl}
        editUrl={editUrl}
        availability={availability}
        searchContactsUrl={searchContactsUrl}
        instance={instance}
        useDispatch={useDispatch}
        useSelector={useSelector}
        CustomSlotTile={CustomSlotTile}
        CustomMeetingTile={CustomMeetingTile}
      />
      <HalfHourSlot
        timeStamp={getTimeSlotTimeStamp(date, hour, 30)}
        events={events}
        availability={availability}
        signOut={signOut}
        cancelMeetingUrl={cancelMeetingUrl}
        acceptMeetingUrl={acceptMeetingUrl}
        declineMeetingUrl={declineMeetingUrl}
        createUrl={createUrl}
        editUrl={editUrl}
        searchContactsUrl={searchContactsUrl}
        instance={instance}
        useDispatch={useDispatch}
        useSelector={useSelector}
        CustomSlotTile={CustomSlotTile}
        CustomMeetingTile={CustomMeetingTile}
      />
    </div>
  );
};

export default TimeSlot;
