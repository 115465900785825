import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { ValueType } from 'react-select/src/types';
import '../styles/SelectDropdown.scss';

export type SelectOption = {
  value: string | number;
  label: string;
};

function SelectDropdown(props: {
  isClearable?: boolean;
  isCreatable?: boolean;
  isMulti?: boolean;
  isSearchable?: boolean;
  placeholder?: string;
  options: SelectOption[];
  value: SelectOption;
  onChange(selectedOption: ValueType<SelectOption, boolean> | string): void;
  error?: boolean;
}): JSX.Element {
  if (props.isCreatable)
    return (
      <CreatableSelect
        formatCreateLabel={value => value}
        isSearchable={props.isSearchable}
        className={`SelectContainer${props.error ? ' ErrorDropdown' : ''}`}
        classNamePrefix="SelectContent"
        value={props.value}
        onChange={props.onChange}
        options={props.options}
        placeholder={props.placeholder}
        isMulti={props.isMulti ?? false}
      />
    );

  return (
    <Select
      isSearchable={props.isSearchable}
      isClearable={props.isClearable}
      className={`SelectContainer${props.error ? ' ErrorDropdown' : ''}`}
      classNamePrefix="SelectContent"
      value={props.value}
      onChange={props.onChange}
      options={props.options}
      placeholder={props.placeholder}
      isMulti={props.isMulti ?? false}
    />
  );
}

export default SelectDropdown;
