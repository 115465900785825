import React from 'react';
import { useFormikContext } from 'formik';
import { ErrorM } from './ErrorM';
import { deepGet } from '../helpers/forms';

interface FormCheckboxFieldProps<T> {
  field: string;
  label: string;
  labelLinkText?: string;
  labelLinkHref?: string;
  clearDiscount?: () => void;
  readOnly?: boolean;
}

const FormCheckboxField = function<T>({
  field,
  label,
  labelLinkText,
  labelLinkHref,
  clearDiscount,
  readOnly = false,
}: FormCheckboxFieldProps<T>) {
  const formikContext = useFormikContext<T>();
  return (
    <div className="FormBox FormSideBySide">
      <div className={`Checkbox ${readOnly && 'ReadOnly'}`}>
        <input
          name={field}
          type="checkbox"
          id={field}
          disabled={readOnly}
          checked={deepGet(formikContext.values, field)}
          onChange={e => {
            formikContext.setFieldValue(
              field as any,
              !deepGet(formikContext.values, field)
            );
            clearDiscount && clearDiscount();
          }}
        />
        <label className="CustomInput" htmlFor={field}>
          {label}
        </label>
        <ErrorM name={field} />
      </div>
    </div>
  );
};

export { FormCheckboxField };
