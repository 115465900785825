import { Store } from './rootReducers';

/*STATE*/
export type State = {
  room: any;
};

export const initialState: State = {
  room: null,
};

/* ACTIONS */

export enum Actions {
  SET_ROOM = 'app/chats/SET_ROOM',
  CLEAR_ROOM = 'app/chats/CLEAR_ROOM',
}

export type Action =
  | { type: Actions.SET_ROOM; payload: any }
  | { type: Actions.CLEAR_ROOM };

export const calls = (state = initialState, action: Action): State => {
  switch (action.type) {
    case Actions.SET_ROOM:
      return {
        ...state,
        room: action.payload,
      };
    case Actions.CLEAR_ROOM:
      return {
        ...state,
        room: null,
      };

    default:
      return state;
  }
};

// Action creatores

export const setRoom = (payload: any): Action => ({
  type: Actions.SET_ROOM,
  payload,
});

export const clearRoom = (): Action => ({
  type: Actions.CLEAR_ROOM,
});

// Selectors

export const selectRoom = (store: Store) => store.calls.room;
