export async function downloadFile(
  instance: any,
  url: string,
  displayName: string,
  spinner?: any,
  icon?: any,
  includeExtentsion?: boolean
) {
  if (spinner && icon) {
    spinner.style.display = 'block';
    icon.style.display = 'none';
  }

  try {
    const { data } = await instance.get(url, {
      responseType: 'blob',
      headers: {
        'Cache-Control': 'max-age=0',
      },
    });

    let fileExtention;
    if (data.type === 'application/pdf') fileExtention = '.pdf';
    if (
      data.type ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    )
      fileExtention = '.docx';
    if (data.type === 'application/msword') fileExtention = '.doc';

    const downloadUrl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute(
      'download',
      `${displayName}${includeExtentsion ? fileExtention : ''}`
    );
    document.body.appendChild(link);
    if (spinner && icon) {
      spinner.style.display = 'none';
      icon.style.display = 'block';
    }
    link.click();
    setTimeout(function() {
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    }, 100);
  } catch (err) {
    if (spinner && icon) {
      spinner.style.display = 'none';
      icon.style.display = 'block';
    }
    throw err;
  }
}
