import { Day, EventItem } from '../models/types';
import {
  add,
  addMinutes,
  formatISO,
  getDate,
  getDay,
  getHours,
  getMinutes,
  getMonth,
  getTime,
  getYear,
  isBefore,
  isEqual,
  isSameDay,
  isSameHour,
  isSameMinute,
  isWithinInterval,
  parseISO,
  startOfWeek,
} from 'date-fns';
import { SelectOption } from '../components/SelectDropdown';
import { translations } from '../helpers/translations';

export const times = [
  {
    displayName: '9:00 AM',
    code: 9,
  },
  {
    displayName: '10:00 AM',
    code: 10,
  },
  {
    displayName: '11:00 AM',
    code: 11,
  },
  {
    displayName: '12:00 PM',
    code: 12,
  },
  {
    displayName: '1:00 PM',
    code: 13,
  },
  {
    displayName: '2:00 PM',
    code: 14,
  },
  {
    displayName: '3:00 PM',
    code: 15,
  },
  {
    displayName: '4:00 PM',
    code: 16,
  },
  {
    displayName: '5:00 PM',
    code: 17,
  },
  {
    displayName: '6:00 PM',
    code: 18,
  },
];

export const meetingBookingTime: SelectOption[] = [
  { label: '8:00 AM', value: '8:00' },
  { label: '8:30 AM', value: '8:30' },
  { label: '9:00 AM', value: '9:00' },
  { label: '9:30 AM', value: '9:30' },
  { label: '10:00 AM', value: '10:00' },
  { label: '10:30 AM', value: '10:30' },
  { label: '11:00 AM', value: '11:00' },
  { label: '11:30 AM', value: '11:30' },
  { label: '12:00 PM', value: '12:00' },
  { label: '12:30 PM', value: '12:30' },
  { label: '1:00 PM', value: '13:00' },
  { label: '1:30 PM', value: '13:30' },
  { label: '2:00 PM', value: '14:00' },
  { label: '2:30 PM', value: '14:30' },
  { label: '3:00 PM', value: '15:00' },
  { label: '3:30 PM', value: '15:30' },
  { label: '4:00 PM', value: '16:00' },
  { label: '4:30 PM', value: '16:30' },
  { label: '5:00 PM', value: '17:00' },
  { label: '5:30 PM', value: '17:30' },
  { label: '6:00 PM', value: '18:00' },
];

export const getSelectedDay = (date: any) => {
  const day = {
    date: date,
    dateStamp: formatISO(date),
    weekDayName: getDayOfTheWeek(date),
  };

  return day;
};

export const getDayOfTheWeek = (day: any) => {
  const weekDayCode = getDay(day);
  if (weekDayCode === 0) return translations.days.sunday;
  if (weekDayCode === 1) return translations.days.monday;
  if (weekDayCode === 2) return translations.days.tuesday;
  if (weekDayCode === 3) return translations.days.wednesday;
  if (weekDayCode === 4) return translations.days.thursday;
  if (weekDayCode === 5) return translations.days.friday;
  if (weekDayCode === 6) return translations.days.saturday;
  return '';
};

export const getMonthName = (month: number) => {
  if (month === 0) return translations.months.january ?? 'January';
  if (month === 1) return translations.months.february ?? 'February';
  if (month === 2) return translations.months.march ?? 'March';
  if (month === 3) return translations.months.april ?? 'April';
  if (month === 4) return translations.months.may ?? 'May';
  if (month === 5) return translations.months.june ?? 'June';
  if (month === 6) return translations.months.july ?? 'July';
  if (month === 7) return translations.months.august ?? 'August';
  if (month === 8) return translations.months.september ?? 'September';
  if (month === 9) return translations.months.october ?? 'October';
  if (month === 10) return translations.months.november ?? 'November';
  if (month === 11) return translations.months.december ?? 'December';
  return '';
};

export const getDaysOfTheWeek = (
  currentDate: any,
  startOn: 'Mon' | 'Sun' | null = null
) => {
  // startOfWeek() returns a sunday as first day of a week
  const weekStart =
    startOn === 'Mon'
      ? add(startOfWeek(currentDate), { days: 1 })
      : startOfWeek(currentDate);

  const days = Array.from(Array(7))
    .map((d: any, i: any) => i)
    .map((day: any) => add(weekStart, { days: day }))
    .map((day: any) => ({
      date: day,
      dateStamp: formatISO(day),
      weekDayName: getDayOfTheWeek(day),
    }));

  return days;
};

export const getHeaderMonthAndYear = (
  selectedView: 'day' | 'week',
  day: Day,
  week: Day[]
) => {
  if (selectedView === 'day') {
    const month = getMonthName(getMonth(day.date));
    const year = getYear(day.date);

    return `${month} ${year}`;
  } else if (selectedView === 'week') {
    const firstDayMonth = getMonthName(getMonth(week[0].date));
    const firstDayYear = getYear(week[0].date);
    const lastDayMonth = getMonthName(getMonth(week[6].date));
    const lastDayYear = getYear(week[6].date);

    if (firstDayMonth === lastDayMonth)
      return `${firstDayMonth} ${firstDayYear}`;
    return `${firstDayMonth} ${firstDayYear} - ${lastDayMonth} ${lastDayYear}`;
  }
  return '';
};

export const getTimeSlotTimeStamp = (
  date: Date,
  hour: number,
  minutes: number
) => {
  const day = getDate(date);
  const month = getMonth(date);
  const year = getYear(date);

  return new Date(year, month, day, hour, minutes, 0, 0);
};

export const findNumberOfEvents = (timeStamp: Date, events: EventItem[]) => {
  let numberOfEvent: number = 0;
  events.map((e: EventItem) => {
    const isIncluded = isWithinInterval(timeStamp, {
      start: parseISO(e.startDate),
      end: parseISO(e.endDate),
    });
    if (isIncluded) {
      numberOfEvent = numberOfEvent + 1;
    }
  });

  return numberOfEvent;
};

export const meetingStartTime = (timeStamp: Date, events: EventItem[]) => {
  const eventsStarting = events.filter(
    (e: EventItem) =>
      isSameDay(parseISO(e.startDate), timeStamp) &&
      isSameHour(parseISO(e.startDate), timeStamp) &&
      isWithinInterval(parseISO(e.startDate), {
        start: timeStamp,
        end: addMinutes(timeStamp, 30),
      })
  );
  return eventsStarting;
};

export const getNumberOfSlotsPerMeeting = (
  startDate: string,
  endDate: string,
  minIntervalInMinutes: number
) => {
  const intervalInMillisenconds = minIntervalInMinutes * 60 * 1000;

  // getTime returns milliseconds
  const startTime = getTime(parseISO(startDate));
  const endTime = getTime(parseISO(endDate));

  const numberOfSlots = (endTime - startTime) / intervalInMillisenconds;

  if (numberOfSlots % 1 !== 0) {
    return Math.ceil(numberOfSlots);
  } else {
    return numberOfSlots;
  }
};

export const getTimeIntervalArrays = (
  startDate: string,
  endDate: string,
  minIntervalInMinutes: number
) => {
  const numberOfSlots = getNumberOfSlotsPerMeeting(
    startDate,
    endDate,
    minIntervalInMinutes
  );

  let date = parseISO(startDate);
  if (numberOfSlots === 1) {
    return [addMinutes(date, minIntervalInMinutes)];
  }
  return Array.from(Array(numberOfSlots - 1)).map((i: any, k: number) => {
    date = addMinutes(date, minIntervalInMinutes);
    return date;
  });
};

export const getMaximumNumberOfOverlappingMeetings = (
  startDate: string,
  endDate: string,
  minIntervalInMinutes: number,
  events: EventItem[]
) => {
  const timesArray = getTimeIntervalArrays(
    startDate,
    endDate,
    minIntervalInMinutes
  );

  const overlapingEventsArray = timesArray.map((d: Date) =>
    findNumberOfEvents(d, events)
  );

  return Math.max(...overlapingEventsArray) - 1;
};

export const getOverlappingMeetings = (
  startDate: string,
  endDate: string,
  minIntervalInMinutes: number,
  events: EventItem[],
  eventsId: number
) => {
  const eventsWithoutOriginal = events.filter(
    (e: EventItem) => e.eventsId !== eventsId
  );
  const eventsOverlappingStart = eventsWithoutOriginal.filter((e: EventItem) =>
    isWithinInterval(parseISO(startDate), {
      start: parseISO(e.startDate),
      end: parseISO(e.endDate),
    })
  );
  const eventsOverlappingEnd = eventsWithoutOriginal.filter((e: EventItem) =>
    isWithinInterval(parseISO(endDate), {
      start: parseISO(e.startDate),
      end: parseISO(e.endDate),
    })
  );
  const eventsOverlappingStartOther = eventsWithoutOriginal.filter(
    (e: EventItem) =>
      isWithinInterval(parseISO(e.startDate), {
        start: parseISO(startDate),
        end: parseISO(endDate),
      })
  );
  const eventsOverlappingEndOther = eventsWithoutOriginal.filter(
    (e: EventItem) =>
      isWithinInterval(parseISO(e.endDate), {
        start: parseISO(startDate),
        end: parseISO(endDate),
      })
  );
  const totalOverlappingEvents = [
    ...eventsOverlappingStart,
    ...eventsOverlappingEnd,
    ...eventsOverlappingStartOther,
    ...eventsOverlappingEndOther,
  ];
  const filteredTotalOverlappingEvents = totalOverlappingEvents.filter(
    (value, index, self) =>
      index === self.findIndex(e => e.eventsId === value.eventsId)
  );
  return filteredTotalOverlappingEvents;
};

export const getNumberOfOverlappingMeetingsStartingBefore = (
  id: number,
  startDate: string,
  events: EventItem[],
  overlappingEvents: EventItem[]
) => {
  const eventsStartingBefore = overlappingEvents.filter((event: EventItem) =>
    isBefore(parseISO(event.startDate), parseISO(startDate))
  );

  const eventsStartingSameTime = overlappingEvents.filter((event: EventItem) =>
    isEqual(parseISO(event.startDate), parseISO(startDate))
  );

  const eventsShowingFirst = eventsStartingSameTime.filter(
    (event: EventItem) => event.eventsId < id
  );

  const total = eventsStartingBefore.length + eventsShowingFirst.length;

  return total;
};

export const getMeetingWidth = (numberOfOverlappingEvents: number) => {
  const maxWidth = 100;
  if (numberOfOverlappingEvents === 0) return maxWidth;
  return maxWidth / (numberOfOverlappingEvents + 1);
};

export const getISODate = (date: string, time: string) => {
  const dateArray = date.split('/').map((i: string) => parseInt(i));
  const timeArray = time.split(':').map((i: string) => parseInt(i));

  const newDate = new Date(
    dateArray[2],
    dateArray[1] - 1,
    dateArray[0],
    timeArray[0],
    timeArray[1],
    0,
    0
  );

  return formatISO(newDate);
};

export const getSelectedParticipantsList = (
  selectedParticipants: SelectOption[],
  contactsList: any
) => {
  return contactsList.filter((c: any) =>
    selectedParticipants.includes(c.siteUsersId)
  );
};
export const getResourcesIds = (
  resourcesTypes: string[],
  resourcesList: any
) => {
  return resourcesTypes.map(
    (t: any) =>
      resourcesList.map((r: any) => {
        if (r.code.toLowerCase() === t) return r.id;
        return;
      })[0]
  );
};

export const getStringDate = (dateISO: string) => {
  const date = parseISO(dateISO);
  const day = getDate(date);
  const month = getMonth(date) + 1;
  const year = getYear(date);
  return `${day}/${month}/${year}`;
};

export const getStringTime = (dateISO: string) => {
  const date = parseISO(dateISO);
  const hours = getHours(date);
  const minutes = getMinutes(date);
  return `${hours}:${minutes === 0 ? '00' : minutes}`;
};

export const getParticipantsOptions = (
  contactsList: any,
  participantsIds: any
) => {
  const selectedContacts = contactsList.filter((c: any) =>
    participantsIds.includes(c.siteUsersId)
  );
  return selectedContacts.map((c: any) => ({
    label: c.name,
    value: c.siteUsersId,
  }));
};

const getSelectedResources = (resourcesIds: any, resourcesList: any) => {
  return resourcesList.map((r: any) => {
    if (resourcesIds.includes(r.id)) {
      return r.code.toLowerCase();
    }
  });
};

export const isResourceSelected = (
  resourceName: string,
  resourcesIds: any,
  resourcesList: any
) => {
  const selectedResourcesNames: string[] = getSelectedResources(
    resourcesIds,
    resourcesList
  );
  if (selectedResourcesNames.includes(resourceName)) return true;
  return false;
};

export const setMinimumMeetingInterval = (formik: any, value: string) => {
  if (formik.values.timeEnd) return;

  const hour = value.split(':')[0];
  const minutes = value.split(':')[1];

  if (minutes === '00') return formik.setFieldValue('endTime', `${hour}:30`);
  return formik.setFieldValue('endTime', `${parseInt(hour) + 1}:00`);
};

export const updatedMeetingStatus = (
  events: EventItem[],
  status: string,
  id: number
) => {
  return events.map((e: EventItem) => {
    if (e.eventsId === id) {
      return { ...e, status: status };
    }
    return e;
  });
};

export const maxGetMaximumOfOverlappingMeetingPerDay = (
  date: any,
  events: EventItem[]
) => {
  const dayEvents = events.filter((e: EventItem) =>
    isSameDay(date, parseISO(e.startDate))
  );

  const day = getDate(date);
  const month = getMonth(date);
  const year = getYear(date);
  let startDayDate = new Date(year, month, day, 7, 30, 0);
  const daysTimeSlots = Array.from(Array(18)).map(i => {
    startDayDate = addMinutes(startDayDate, 30);
    return startDayDate;
  });

  const array = daysTimeSlots.map((t: any) => {
    return findNumberOfEvents(t, dayEvents);
  });
  return Math.max(...array);
};

export const ISODateWithoutTimezone = (date: string) => {
  return date
    .split('')
    .slice(0, 19)
    .join('');
};

export const getCalendarDate = (
  date: Date,
  hour: number,
  minutes: number = 0
) => {
  const day = getDate(date);
  const month = getMonth(date);
  const year = getYear(date);
  const newDate = new Date(year, month, day, hour, minutes, 0, 0);
  return ISODateWithoutTimezone(formatISO(newDate));
};

export const isCurrentMetting = (startDate: string, endDate: string) => {
  const currentTime = new Date();
  const isIncluded = isWithinInterval(currentTime, {
    start: parseISO(startDate),
    end: parseISO(endDate),
  });
  return isIncluded;
};

export const getLengthOfMeeting = (startDate: string, endDate: string) => {
  let diff = new Date(endDate).getTime() - new Date(startDate).getTime();
  return diff / 60000;
};

export const getHeightOfMeetingTile = (meetingLengthMins: number) => {
  let adjustedMeetingLength: number;
  if (meetingLengthMins < 30) {
    adjustedMeetingLength = 30;
  } else {
    adjustedMeetingLength = meetingLengthMins;
  }
  return `${(adjustedMeetingLength / 30) * 26}px`;
};

export const getVerticalOffsetOfMeetingTile = (
  differenceInMinutes: number,
  interval: number
) => {
  const proportionOfInterval = differenceInMinutes / interval;
  const verticalOffset = proportionOfInterval * 100;
  return `${verticalOffset}%`;
};
