export const endpoints = {
  communicationModule: {
    calls: {
      joincall: '/communicationsmodule/calls/joincall',
      startChatCall: '/communicationsmodule/calls/startchatcall',
      joincallwithpassword: '/communicationsmodule/calls/joincallwithpassword',
      inviteexternalcallparticipant:
        '/communicationsmodule/calls/inviteexternalcallparticipant',
    },
    chats: {
      accept: '/communicationsmodule/chats/accept',
      decline: '/communicationsmodule/chats/decline',
      details: '/communicationsmodule/chats/details',
      getmessageattachment: '/communicationsmodule/chats/getmessageattachment',
      recentChats: '/api/v1/adminrole/messagingmodule/recent',
      sendChatMessage: '/communicationsmodule/chats/sendmessage',
      createForContact: '/communicationsmodule/chats/createforcontact',
    },

    contacts: {
      contacts: '/communicationsmodule/contacts',
    },
  },
};
