import { useRef, useEffect } from 'react';

/**
 * Detect when user has clicked outside a component, and
 * run a callback. By default the callback will also be called
 * when the user presses the Esc key, which makes sense if the
 * hook is being used for cancellation (it usually is). To disable
 * this behaviour, call the hook with the `callOnEsc` parameter set to
 * false.
 *
 *  @example
 *  const bind = useOnClickOutside(() => setShowDropdown(false));
 *
 *  return (
 *    // This div is presumably some sort of dropdown.
 *    <div {...bind}>...</div>
 *  )
 *
 */
export const useOnClickOutside = (callback: () => any, callOnEsc = true) => {
  const ref = useRef<any>(null);
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      // If we we haven't set the node yet, or a click occurs within the target, return.
      if (ref.current == null || ref.current.contains(e.target)) {
        return;
      }
      // If the click is a left click and outside the target,
      // run the callback.
      if (e.buttons === 1) {
        callback();
      }
    };

    const handleTouch = (e: TouchEvent) => {
      // If we we haven't set the node yet, or a click occurs within the target, return.
      if (ref.current == null || ref.current.contains(e.target)) {
        return;
      }

      callback();
    };

    const handleEsc = (e: KeyboardEvent) => {
      if (ref.current != null && callOnEsc) {
        if (e.keyCode === 27) {
          callback();
        }
      }
    };

    let root = document.getElementById('root');

    if (root) {
      // Add event listener.
      root.addEventListener('mousedown', handleClick);
      root.addEventListener('touchstart', handleTouch);
      root.addEventListener('keydown', handleEsc);
    }

    // Tear down event listener on cleanup.
    return () => {
      if (root) {
        root.removeEventListener('mousedown', handleClick);
        root.addEventListener('touchstart', handleTouch);
        root.removeEventListener('keydown', handleEsc);
      }
    };
  }, [callback]);

  return { ref };
};
