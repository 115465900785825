import React, { ReactChild, ReactElement } from 'react';
import { useOnClickOutside } from '@avamae/hooks';
import { useLockBody } from '../hooks/useLockBody';
import CloseIcon from '../assets/Icon_Menu_02.png';
import Button from './Button';
import '../styles/Modal.scss';

type Props = {
  children: ReactChild;
  header: string;
  buttonText1: string;
  buttonText2: string;
  closeModal: () => void;
  action: any;
  isOpen: boolean;
  colorButton2?: 'blue' | 'lightBlue' | 'white' | 'transparent' | 'red';
  loading?: ReactElement | null;
  disableButton2?: boolean;
};

const Modal: React.FC<Props> = ({
  children,
  header,
  buttonText1,
  buttonText2,
  closeModal,
  action,
  isOpen,
  colorButton2 = 'blue',
  loading,
  disableButton2,
}) => {
  const lock = useLockBody(isOpen);
  const bind = useOnClickOutside(closeModal);

  return (
    <div className="ModalUnderlay">
      <div {...lock}>
        <div className="Modal" {...bind}>
          <div className="ModalHeader InnerWidthContent">
            <h2>{header}</h2>
            <img src={CloseIcon} alt="Close" onClick={closeModal} />
          </div>
          <div className="ModalContent InnerWidthContent">{children}</div>
          <div className="ModalFooter InnerWidthContent">
            <Button
              type="button"
              color="transparent"
              variety="full"
              onClick={closeModal}
            >
              {buttonText1}
            </Button>
            <Button
              color={colorButton2}
              variety="full"
              type="button"
              onClick={action}
              disabled={disableButton2 ? disableButton2 : false}
            >
              {loading ? loading : buttonText2}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
