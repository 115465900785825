import {
  setToastBackgroundColor,
  setToastMessage,
  toggleToast,
} from '../reducers/toast';

export function useToast(
  message: string,
  backgrounColor: string = 'green',
  interval: number = 3600,
  useDispatch: any
) {
  const dispatch = useDispatch();

  return () => {
    dispatch(setToastBackgroundColor(backgrounColor));
    dispatch(toggleToast());
    dispatch(setToastMessage(message));

    window.setTimeout(() => {
      dispatch(toggleToast());
      dispatch(setToastMessage(''));
    }, interval);
  };
}
