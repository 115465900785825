import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CHATS_HEADER, NUMBER_OF_MESSAGES_PER_PAGE } from '../appConstants';
import { ChatDetails, IPagination, MessageItem } from '../models/Chats';
import { ColorSchema, Endpoints } from '../models/types';
import {
  selectChatId,
  selectFooterHeight,
  selectShowLastMessage,
  setShowLastMessage,
} from '../reducers/chats';
import Message from './Message';
import { ChatsApi } from '../api/ChatsApi';
import sortMessages from '../helpers/sortMessages';
import { Spinner } from './ui/Spinner';
import { selectTranslations } from '../reducers/translations';

interface IMessagesContainerProps {
  chatDetails: ChatDetails | null;
  setChatDetails: React.Dispatch<React.SetStateAction<ChatDetails | null>>;
  error: string;
  colorSchema: ColorSchema;
  instance: any;
  baseURL: any;
  endpoints: Endpoints;
  pagination: IPagination;
  setPagination: React.Dispatch<React.SetStateAction<IPagination>>;
  useDispatch: any;
  useSelector: any;
  chatSelected: boolean;
}

const MessagesContainer: React.FC<IMessagesContainerProps> = ({
  chatDetails,
  setChatDetails,
  error,
  colorSchema,
  instance,
  baseURL,
  endpoints,
  pagination,
  setPagination,
  useSelector,
  useDispatch,
  chatSelected,
}) => {
  const dispatch = useDispatch();
  const footerHeight = useSelector(selectFooterHeight);
  const selectedChatId = useSelector(selectChatId);
  const showLastMessage = useSelector(selectShowLastMessage);
  const translations = useSelector(selectTranslations);

  const [containerHeight, setContainerHeight] = useState<number>(() => {
    const fHeight = footerHeight ? footerHeight : 0;
    return CHATS_HEADER + fHeight;
  });

  const [hasMoreMessages, setHasMoreMessages] = useState<boolean>(true);

  useEffect(() => {
    const fHeight = footerHeight ? footerHeight : 0;
    setContainerHeight(CHATS_HEADER + fHeight);
  }, [footerHeight]);

  const getMoreMessages = async () => {
    if (!selectedChatId || !chatDetails) return;
    const result = await ChatsApi.getChatsDetails(
      instance,
      baseURL + endpoints.chats.details,
      selectedChatId,
      pagination
    );
    if (result.data) {
      if (result.data.messages.length === 0) {
        setHasMoreMessages(false);
      }
      const combinedMessages = [
        ...chatDetails?.messages,
        ...result.data.messages,
      ];
      const sortedCombinedMessages = sortMessages(combinedMessages);
      setChatDetails({ ...result.data, messages: sortedCombinedMessages });
      setPagination({ ...pagination, pageNumber: pagination.pageNumber + 1 });
    }
    if (result.error) {
    }
  };

  const messagesEndRef: any = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({
      behavior: 'auto',
      block: 'end',
    });
  };

  useEffect(() => {
    if (!hasMoreMessages) setHasMoreMessages(true);
  }, [selectedChatId]);

  useEffect(() => {
    if (showLastMessage) {
      messagesEndRef.current && scrollToBottom();
      dispatch(setShowLastMessage(false));
    }
  }, [messagesEndRef, showLastMessage, footerHeight]);

  return (
    <div
      id="ChatMessagesContainer"
      className={`ChatMessagesContainer ${!chatSelected ? 'NoScroll' : ''}`}
      style={{
        position: 'relative',
        height: `calc(100% - ${containerHeight}px)`,
        display: 'flex',
        flexDirection: 'column-reverse',
      }}
    >
      <div id={'messagesEndRef'} ref={messagesEndRef} />
      {error && (
        <div className="Alert">
          <p>
            {translations?.messagesContainerError ??
              'Something went wrong, please try again.'}
          </p>
        </div>
      )}
      {chatSelected && chatDetails && chatDetails.messages.length === 0 && (
        <div className="Alert">
          <p>
            {translations?.messagesContainerNoMessagesYet ?? 'No messages yet'}
          </p>
        </div>
      )}
      {!chatSelected && (
        <div className="Alert">
          <p>
            {translations?.messagesContainerNoChatSelected ??
              'No chat selected'}
          </p>
        </div>
      )}
      {chatSelected && !chatDetails && !error && <Spinner positionAbsolute />}

      <InfiniteScroll
        next={() => {
          getMoreMessages();
        }}
        hasMore={hasMoreMessages}
        loader={<Spinner positionAbsolute style={{ top: '15px' }} />}
        dataLength={chatDetails?.messages.length ?? 0}
        inverse={true}
        className="Messages WidthContent"
        scrollableTarget={'ChatMessagesContainer'}
        endMessage={
          chatDetails?.messages &&
          chatDetails.messages.length >= NUMBER_OF_MESSAGES_PER_PAGE ? (
            <p style={{ textAlign: 'center' }}>
              {translations?.messagesContainerNoMoreMessages ??
                'No more messages'}
            </p>
          ) : null
        }
        style={{
          display: 'flex',
          position: 'relative',
          flexDirection: 'column-reverse',
          marginTop: 20,
        }}
      >
        {chatDetails &&
          chatDetails.messages.length > 0 &&
          chatDetails.messages.map((message: MessageItem, index: number) => {
            return (
              <Message
                key={index}
                message={message}
                colorSchema={colorSchema}
                instance={instance}
                baseURL={baseURL}
                endpoints={endpoints}
                useDispatch={useDispatch}
                useSelector={useSelector}
              />
            );
          })}
      </InfiniteScroll>
    </div>
  );
};

export default MessagesContainer;
