import { number } from 'yup';

/*State*/
type State = {
  open: boolean;
  eventId?: number | null;
};

type EventPayload = { eventId?: number };

const initialState: State = {
  open: false,
  eventId: null,
};

/* ACTIONS */
const SET_CLOSE_MENU = 'app/SET_CLOSE_MENU';
const SET_OPEN_MENU = 'app/SET_OPEN_MENU';

type Action =
  | {
      type: typeof SET_CLOSE_MENU;
    }
  | {
      type: typeof SET_OPEN_MENU;
      payload: EventPayload;
    };

/* REDUCER */
export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case SET_CLOSE_MENU:
      return {
        ...state,
        open: false,
      };
    case SET_OPEN_MENU:
      return {
        ...state,
        eventId: action.payload.eventId,
        open: true,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS */
export const setOpenMenu = (payload: EventPayload): Action => ({
  type: SET_OPEN_MENU,
  payload,
});
export const setCloseMenu = (): Action => ({
  type: SET_CLOSE_MENU,
});

/* SELECTORS */
export const selectRightMenuStatus = (app: any) => app.calendarRightMenu.open;
export const selectRightMenuEventId = (app: any) =>
  app.calendarRightMenu.eventId;
