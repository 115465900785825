import React from 'react';
import { ErrorM } from './ErrorM';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useFormikContext } from 'formik';
import { format, isValid, parse } from 'date-fns';
import ClockIcon from '../assets/Icon_Time.png';
import SelectDropdown, { SelectOption } from './SelectDropdown';

interface FormDateTimeFieldProps<T> {
  dateField: string;
  timeField: string;
  label: string;
  required: boolean;
  hint?: string;
  optionalTag?: string;
  placeholder?: string;
  sideBySide?: boolean;
  isVisible?: boolean;
  icon?: string;
  showIcon?: boolean;
  nestedValue?: boolean;
  timeOptions: SelectOption[];
  action?: (formik: any, value: string) => {};
  id?: number | undefined;
}

const FormDateTimeField = function<T>({
  dateField,
  timeField,
  label,
  required,
  hint,
  optionalTag,
  placeholder,
  sideBySide,
  isVisible = true,
  icon,
  showIcon = false,
  nestedValue = false,
  timeOptions,
  action,
  id,
}: FormDateTimeFieldProps<T>) {
  const formikContext: any = useFormikContext<T>();
  const values: any = formikContext.values;
  let selectedDate = new Date();
  let isDateValid;

  if (values[dateField]) {
    selectedDate = parse(values[dateField].value, 'dd/MM/yyyy', new Date());
    isDateValid = isValid(selectedDate);
  }

  const hasErrors = () => {
    return (
      (Object.keys(formikContext.errors).indexOf(dateField) !== -1 &&
        Object.keys(formikContext.touched).indexOf(dateField) !== -1) ||
      (Object.keys(formikContext.errors).indexOf(timeField) !== -1 &&
        Object.keys(formikContext.touched).indexOf(timeField) !== -1)
    );
  };

  const findTimeValues = () => {
    if (formikContext.values[timeField]) {
      const value: SelectOption = timeOptions.find(
        v => v.value === formikContext.values[timeField].value
      ) as any;
      return value;
    } else return timeOptions[0];
  };
  return (
    <div
      className={`FormBox ${sideBySide && 'SideBySide'} ${
        isVisible ? '' : 'Hide'
      }`}
    >
      <div className="FormLabel">
        <label>
          {label}{' '}
          {!required && <span className="Optional">- {optionalTag}</span>}
        </label>
        {hint && <div className="FormHint">{hint}</div>}
      </div>
      <div className="FormField">
        <div className={`${hasErrors() && 'DatePickerError'} DoubleField`}>
          <div className="FirstField">
            <DatePicker
              selected={isDateValid ? new Date(selectedDate) : null}
              onChange={(value: any) => {
                value
                  ? formikContext.setFieldValue(dateField, {
                      id,
                      value: format(value, 'dd/MM/yyyy'),
                    })
                  : formikContext.setFieldValue(dateField, '');
              }}
              onSelect={(value: any) => {
                value
                  ? formikContext.setFieldValue(dateField, {
                      id,
                      value: format(value, 'dd/MM/yyyy'),
                    })
                  : formikContext.setFieldValue(dateField, '');
              }}
              dateFormat="dd/MM/yyyy"
              dateFormatCalendar="MM/yyyy"
              placeholderText={placeholder}
              minDate={new Date(1, 1, 1900)}
              showDisabledMonthNavigation
            />
            <img src={icon} alt="Date" />
            <ErrorM name={dateField} />
          </div>
          <div className="SecondField">
            <SelectDropdown
              error={hasErrors()}
              value={findTimeValues()}
              onChange={(e: any) => {
                formikContext.setFieldValue(timeField, { id, value: e.value });
              }}
              options={timeOptions}
              isSearchable={false}
              isCreatable={false}
              isClearable={false}
              placeholder={'hh:mm'}
              isMulti={false}
            />
            <img src={ClockIcon} alt="Time" />
            <ErrorM name={timeField} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormDateTimeField;
