import { isFuture } from 'date-fns';

export const storeInLocalStorage = (content: any, name: string) => {
  content = JSON.stringify(content);
  window.localStorage.setItem(name, content);
};

export const getItemFromLocalStorage = (item: string) => {
  const content = window.localStorage.getItem(item);
  return content && JSON.parse(content);
};

export const getPathname = (cultureCode: string) => {
  let pathname = window.location.pathname;
  let pathnameArray = pathname.split('/');
  pathnameArray[1] = cultureCode;
  pathname = pathnameArray.join('/');
  return pathname;
};

const ttl: number = 24;

export const getExpiryDate = () => {
  const currentTime = new Date();
  currentTime.setHours(currentTime.getHours() + ttl);
  return currentTime.toISOString();
};

export const useLocalStorageContent = (
  content: any,
  languageId: string | null
) => {
  if (
    content &&
    languageId &&
    content.languageId === languageId &&
    isFuture(new Date(content.expiryDate))
  )
    return true;
  return false;
};
