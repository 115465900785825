import React, { useState } from 'react';
import { useOnClickOutside } from '../hooks/useOnClickOutside';
import Timer from './Timer';
// import CloseIcon from '../../../assets/Icon_Menu_02.png';
// import AudioOnIcon from '../../../assets/Icon_Window_AudioCall_01.png';
// import AudioOffIcon from '../../../assets/Icon_Window_AudioCall_02.png';
// import CameraOffIcon from '../../../assets/Icon_Window_VideoCall_02.png';
// import CameraOnIcon from '../../../assets/Icon_Window_VideoCall_01.png';
// import LeaveCallIcon from '../../../assets/Icon_Window_LeaveCall.png';
import Button from './ui/Button';


type Props = {
  room: any;
  toggleVideoTrack: () => void;
  isVideoDisabled: boolean;
  toggleAudioTrack: () => void;
  isAudioDisabled: boolean;
  leaveRoom: () => void;
};

const RoomHeader: React.FC<Props> = ({
  room,
  toggleVideoTrack,
  isVideoDisabled,
  toggleAudioTrack,
  isAudioDisabled,
  leaveRoom,
}) => {
  const [isAddMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const toogleAddMenu = () => setIsMenuOpen(prevState => !prevState);

  const bind = useOnClickOutside(() => isAddMenuOpen && toogleAddMenu());

  return (
    <div className="RoomHeader">
      <div className="RoomDetails">
        {room.imageUrl && (
          <div
            className="RoomImage"
            style={{ backgroundImage: `url(${room.imageUrl})` }}
          ></div>
        )}
        <div className="RoomInfo">
          <span>{room.title}</span>
          <Timer startTime={room.callStartDate} />
        </div>
      </div>
      <div className="RoomActions">
        <div className="AddButtonContainer" {...bind}>
          {/* <Button color="lightBlue" onClick={toogleAddMenu}>
            <img src={AddParticipantIcon} alt="Add" />
          </Button>
          {isAddMenuOpen && (
            <AddParticipantMenu
              onClose={toogleAddMenu}
              callsId={room.callsId}
            />
          )} */}
        </div>
        {room.type === 'Video' && (
          <Button color="lightBlue" onClick={toggleVideoTrack}>
            {/* <img
              src={isVideoDisabled ? CameraOffIcon : CameraOnIcon}
              alt="Camera"
            /> */}
          </Button>
        )}
        <Button color="lightBlue" onClick={toggleAudioTrack}>
          {/* <img src={isAudioDisabled ? AudioOffIcon : AudioOnIcon} alt="Audio" /> */}
        </Button>
        <Button color="red" onClick={leaveRoom}>
          {/* <img src={LeaveCallIcon} alt="Leave call" /> */}
        </Button>
        <div className="Close" onClick={leaveRoom}>
          {/* <img src={CloseIcon} alt="Close" /> */}
        </div>
      </div>
    </div>
  );
};

export default RoomHeader;
