import React from "react";
import { ErrorMessage } from "formik";

const ErrorM: React.FC<{ name: string }> = (props) => (
  <ErrorMessage name={props.name}>
    {(msg) => <div className="ErrorLabel">{msg}</div>}
  </ErrorMessage>
);

export { ErrorM };
