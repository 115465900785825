import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import useMedia from 'use-media';
// import moment from 'moment-timezone';
import { selectToastMessage, selectToastStatus } from '../reducers/toast';
import {
  EventItem,
  // AvailableSlotItem,
  selectEvents,
  selectUpdateEvents,
  setAvailableSlots,
  setEventsList,
  setHideEvents,
  // selectUpdateEvents,
  // setEventsList,
  setUpdateEvents,
} from '../reducers/calendar';
import { add, sub, isWithinInterval, setHours, setMinutes } from 'date-fns';
import {
  // getCalendarDate,
  getDaysOfTheWeek,
  getHeaderMonthAndYear,
  getSelectedDay,
} from '../helpers/calendarViews';
import { Day } from '../models/types';
import Button from './Button';
import AddIcon from '../assets/Icon_Add.png';
import PreviousIcon from '../assets/Icon_Pagination_02.png';
import NextIcon from '../assets/Icon_Pagination_03.png';
import SelectDropdown, { SelectOption } from './SelectDropdown';
import WeekView from './WeekView';
import DayView from './DayView';
import Toast from './Toast';
import { translations } from '../helpers/translations';
import '../styles/Communication.scss';
import CalendarSidePanel from './CalendarSidePanel';
import CustomMeetingFormWrapper from './CustomMeetingFormWrapper';
import {
  selectRightMenuStatus,
  setCloseMenu,
  setOpenMenu,
} from '../reducers/calendarRightMenu';
import { parseEventsDetails } from '../helpers/calendarModuleParser';
import { Spinner } from './Spinner';

export type Props = RouteComponentProps;

export type CalendarProps = {
  endpoints: {
    searchContactsUrl: string;
    createUrl: string;
    editUrl: string;
    eventsSummaryUrl: string;
    cancelMeetingUrl: string;
    declineMeetingUrl: string;
    acceptMeetingUrl: string;
    createAvailability: string;
    editAvailability: string;
    getAvailability: string;
  };
  instance: any;
  signOut: () => {};
  useDispatch: any;
  useSelector: any;
  setAvailabilityOnly?: boolean;
  CustomForm?: React.ComponentType<any>;
  CustomSlotTile?: React.ComponentType<any>;
  CustomMeetingTile?: React.ComponentType<any>;
  customFormDetails?: { title: string; addBtn: string };
  selectCustomAvailableSlots?: (state: any) => any;
  selectCustomMeetingSlots?: (state: any) => any;
  toggleSideMenuStatus?: () => any;
  selectSideMenuStatus?: (state: any) => any;
  externalEventsFetch?: boolean;
  externalAvailabilitiesFetch?: boolean;
  bookableMeetings: EventItem[];
  mainButtonText?: string;
  hideEvents?: boolean;
  responsiveButton?: boolean;
};

const Calendar: React.FC<Props & CalendarProps> = ({
  endpoints,
  signOut,
  instance,
  useDispatch,
  useSelector,
  setAvailabilityOnly,
  CustomForm,
  customFormDetails,
  CustomSlotTile,
  CustomMeetingTile,
  selectCustomAvailableSlots,
  selectCustomMeetingSlots,
  toggleSideMenuStatus,
  selectSideMenuStatus,
  externalEventsFetch,
  externalAvailabilitiesFetch,
  bookableMeetings,
  mainButtonText,
  responsiveButton = true,
  hideEvents,
}) => {
  const dispatch = useDispatch();
  const events = useSelector(selectEvents);
  const updateEvents = useSelector(selectUpdateEvents);

  const toastStatus = useSelector(selectToastStatus);
  const toastMessage = useSelector(selectToastMessage);
  const isRightMenuOpen = useSelector(selectRightMenuStatus);

  const tablet = useMedia({ maxWidth: 1024 });
  const mobile = useMedia({ maxWidth: 768 });

  const currentDay = new Date();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // const timeZone = moment.tz.guess(true);

  const [loading, setLoading] = useState<boolean>(true);
  const [selectedView, setSelectedView] = useState<'day' | 'week'>('day');
  const [generalErrors, setGeneralErrors] = useState<string>('');
  const [day, setDay] = useState<Day>(getSelectedDay(new Date()));
  const [menuKey, setMenuKey] = useState(0);
  const [weekDays, setWeekDays] = useState<Day[]>(
    getDaysOfTheWeek(new Date(), 'Mon')
  );
  const eventDispatchRef = useRef<NodeJS.Timeout>();

  let sideMenuStatus: boolean | null = null;
  if (selectSideMenuStatus) {
    sideMenuStatus = useSelector(selectSideMenuStatus);
  }

  useEffect(() => {
    const checkSize = () => {
      if (window.innerWidth < 1024 && selectedView === 'week') {
        setSelectedView('day');
      }
    };
    window.addEventListener('resize', checkSize);
    return () => {
      window.removeEventListener('resize', checkSize);
    };
  }, [selectedView]);

  const viewOptions: SelectOption[] = tablet
    ? [
        {
          label: translations.periods.day ?? '',
          value: 'day',
        },
      ]
    : [
        {
          label: translations.periods.week ?? '',
          value: 'week',
        },
        {
          label: translations.periods.day ?? '',
          value: 'day',
        },
      ];

  //get translations
  useEffect(() => {
    setDay(getSelectedDay(new Date()));
  }, []);

  useEffect(() => {
    dispatch(setHideEvents(!!hideEvents));
  }, [hideEvents]);

  const getNewEvents = () => {
    if (eventDispatchRef.current) clearTimeout(eventDispatchRef.current);
    eventDispatchRef.current = setTimeout(() => {
      fetchEvents();
    }, 300);
  };

  const goToNext = () => {
    if (selectedView === 'day') {
      const nextDay = add(day.date, { days: 1 });
      setDay(() => getSelectedDay(nextDay));
    } else if (selectedView === 'week') {
      const nextWeekFistDay = add(weekDays[0].date, { days: 7 });
      setWeekDays(() => getDaysOfTheWeek(nextWeekFistDay, 'Mon'));
    }
  };

  const goToPrevious = () => {
    if (selectedView === 'day') {
      const previousDay = sub(day.date, { days: 1 });
      setDay(() => getSelectedDay(previousDay));
    } else if (selectedView === 'week') {
      const previousWeekFistDay = sub(weekDays[0].date, { days: 7 });
      setWeekDays(() => getDaysOfTheWeek(previousWeekFistDay, 'Mon'));
    }
  };

  const changeView = (view: 'day' | 'week') => {
    try {
      if (selectedView === 'week') {
        const isCurrentDayIncluded = isWithinInterval(day.date, {
          start: weekDays[0].date,
          end: weekDays[6].date,
        });
        if (isCurrentDayIncluded) {
          setDay(() => getSelectedDay(currentDay));
        } else {
          setDay(() => getSelectedDay(weekDays[0].date));
        }
      } else if (selectedView === 'day') {
        setWeekDays(() => getDaysOfTheWeek(day.date, 'Mon'));
      }
    } finally {
      setSelectedView(view);
      dispatch(setUpdateEvents(true));
    }
  };

  const fetchEvents = () => {
    let dateFrom;
    let dateTo;

    const setTime = (date: Date, hours: number, minutes: number): Date => {
      return setMinutes(setHours(date, hours), minutes);
    };
    if (selectedView === 'week') {
      dateFrom = setTime(weekDays[0].date, 0, 0).toISOString();
      dateTo = setTime(weekDays[6].date, 23, 59).toISOString();
    } else {
      dateFrom = setTime(day.date, 0, 0).toISOString();
      dateTo = setTime(day.date, 23, 59).toISOString();
    }
    instance
      .get(
        endpoints.eventsSummaryUrl +
          `?dateFrom=${dateFrom}&dateTo=${dateTo}&timeZone=${timeZone}`
      )
      .then((res: any) => {
        if (res.status === 200) {
          if (res.data.status === '1') {
            const parsedEventsDetails = parseEventsDetails(res.data.details);

            let eventsList: EventItem[] = [];

            if (
              parsedEventsDetails &&
              Array.isArray(parsedEventsDetails) &&
              parsedEventsDetails.length > 0
            ) {
              eventsList = parsedEventsDetails;
            }

            if (
              bookableMeetings &&
              Array.isArray(bookableMeetings) &&
              bookableMeetings.length > 0
            ) {
              eventsList = eventsList.concat(bookableMeetings);
            }

            if (eventsList.length > 0) {
              dispatch(setEventsList(eventsList));
              dispatch(setUpdateEvents(false));
            }
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      })
      .catch((err: any) => {
        console.log(err);
        if (err.response && err.response.status === 403) {
        }
        setGeneralErrors(
          'An error ocurred while loading your meetings. Please try later.'
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    // replace this with selector getting the availability slot and use this dispatch when setting availability

    // selectAvailableSlots
    if (externalAvailabilitiesFetch) {
      return;
    }

    const dateFrom = new Date();
    const dateTo = add(new Date(), { years: 5 });

    const bodyObj = {
      params: {
        dateFrom,
        dateTo,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };

    instance
      .get(endpoints.getAvailability, bodyObj)
      .then((res: any) => {
        if (res.status === 200) {
          if (res.data.status === '1') {
            const { availabilityType, ...rest } = res.data.details;
            const slots: any = Object.entries(rest).map(([label, value]) => {
              return value;
            });
            dispatch(setAvailableSlots(slots));
          }
        }
      })
      .catch((err: any) => {
        console.log(err);
        if (err.response && err.response.status === 403) {
          // dispatch(signOut());
          console.log('failed');
        }
      });
  }, [externalAvailabilitiesFetch]);

  useEffect(() => {
    if (externalEventsFetch) {
      return;
    }
    if (!updateEvents) {
      dispatch(setEventsList(events));
      return;
    }

    if (generalErrors) setGeneralErrors('');

    fetchEvents();
    return () => {
      dispatch(setEventsList([]));
      dispatch(setUpdateEvents(false));
    };
  }, [updateEvents, externalEventsFetch]);

  useEffect(() => {
    getNewEvents();
  }, [day, weekDays]);

  const getButtonText = () => {
    if (mainButtonText) return mainButtonText;
    if (!setAvailabilityOnly) {
      return translations.meetings.new_meeting;
    } else {
      return translations.meetings.add_edit_availability;
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="MainContent HeightContent WidthContent CalendarPage">
        <div className="CalendarHeader">
          <div className="HeaderOptions">
            <div className="HeaderOptions__left">
              <SelectDropdown
                isSearchable={false}
                onChange={(e: any) => changeView(e.value)}
                value={
                  viewOptions.filter(
                    (o: SelectOption) => o.value === selectedView
                  )[0]
                }
                options={viewOptions}
              />
              <div className="CurrentView">
                <span>
                  {getHeaderMonthAndYear(selectedView, day, weekDays)}
                </span>
              </div>
              <div className="CalendarButtons">
                <Button color="transparent">
                  <img
                    src={PreviousIcon}
                    alt="Previous"
                    onClick={goToPrevious}
                  />
                </Button>
                <Button color="transparent" onClick={goToNext}>
                  <img src={NextIcon} alt="Next" />
                </Button>
              </div>
            </div>
          </div>

          {/* check whether endpoint url is empty string and determines which button and panel to show */}
          <Button
            color="lightBlue"
            variety="full"
            onClick={() => {
              dispatch(setOpenMenu({}));
              setMenuKey(menuKey + 1);
            }}
          >
            <img src={AddIcon} alt="Add meeting" />
            {tablet && responsiveButton
              ? ''
              : <span>{getButtonText()}</span> ?? ''}
          </Button>
        </div>
        {/* {generalErrors && <div className="GeneralError">{generalErrors}</div>} */}
        <div className="CalendarTable" style={{ minWidth: '100%' }}>
          {selectedView === 'week' && (
            <WeekView
              weekDays={weekDays}
              events={events}
              cancelMeetingUrl={endpoints.cancelMeetingUrl}
              acceptMeetingUrl={endpoints.acceptMeetingUrl}
              signOut={signOut}
              declineMeetingUrl={endpoints.declineMeetingUrl}
              createUrl={endpoints.createUrl}
              editUrl={endpoints.editUrl}
              searchContactsUrl={endpoints.searchContactsUrl}
              instance={instance}
              useDispatch={useDispatch}
              useSelector={useSelector}
              CustomSlotTile={CustomSlotTile}
              CustomMeetingTile={CustomMeetingTile}
              selectCustomAvailableSlots={selectCustomAvailableSlots}
              selectCustomMeetingSlots={selectCustomMeetingSlots}
            />
          )}
          {selectedView === 'day' && (
            <DayView
              day={day}
              events={events}
              signOut={signOut}
              cancelMeetingUrl={endpoints.cancelMeetingUrl}
              acceptMeetingUrl={endpoints.acceptMeetingUrl}
              declineMeetingUrl={endpoints.declineMeetingUrl}
              createUrl={endpoints.createUrl}
              editUrl={endpoints.editUrl}
              searchContactsUrl={endpoints.searchContactsUrl}
              instance={instance}
              useDispatch={useDispatch}
              useSelector={useSelector}
              CustomSlotTile={CustomSlotTile}
              CustomMeetingTile={CustomMeetingTile}
              selectCustomAvailableSlots={selectCustomAvailableSlots}
              selectCustomMeetingSlots={selectCustomMeetingSlots}
            />
          )}
        </div>
      </div>
      {/* {isNewMeetingPanelOpen && !setAvailabilityOnly ? (
        <NewMeetingForm
          isOpen={isNewMeetingPanelOpen}
          // disabled={false}
          closeMenu={dispatch(setCloseMenu())}
          action={'create'}
          signOut={signOut}
          searchContactsUrl={endpoints.searchContactsUrl}
          createUrl={endpoints.createUrl}
          editUrl={endpoints.editUrl}
          instance={instance}
          useDispatch={useDispatch}
          useSelector={useSelector}
        />
      ) : (
        ''
      )} */}
      {CustomForm && customFormDetails ? (
        <CustomMeetingFormWrapper
          isOpen={isRightMenuOpen}
          closeMenu={setCloseMenu}
          signOut={signOut}
          CustomForm={CustomForm}
          customFormDetails={customFormDetails}
          showMenu={!!isRightMenuOpen && !!CustomForm && !!customFormDetails}
        ></CustomMeetingFormWrapper>
      ) : (
        <CalendarSidePanel
          isOpen={isRightMenuOpen}
          action={'create'}
          signOut={signOut}
          searchContactsUrl={endpoints.searchContactsUrl}
          createUrl={endpoints.createAvailability}
          detailsUrl={endpoints.getAvailability}
          editUrl={endpoints.editUrl}
          instance={instance}
          useDispatch={useDispatch}
          useSelector={useSelector}
          key={menuKey}
        />
      )}
      {toastStatus && toastMessage && <Toast useSelector={useSelector} />}
    </>
  );
};

export default Calendar;
