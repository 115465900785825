import React, { useEffect, useRef } from 'react';

type Props = {
  track: any;
  isLocalAudioTrackDisabled: boolean;
  isLocalVideoTrackDisabled: boolean;
  localParticipant: boolean;
  participant: any;
};

const Track: React.FC<Props> = ({
  track,
  isLocalAudioTrackDisabled,
  isLocalVideoTrackDisabled,
  localParticipant,
  participant,
}) => {
  const trackRef: any = useRef(null);

  useEffect(() => {
    if (localParticipant) {
      if (track.kind === 'audio') {
        if (isLocalAudioTrackDisabled) {
          track.disable();
        } else {
          track.enable();
        }
      }
    }
  }, [isLocalAudioTrackDisabled, isLocalVideoTrackDisabled]);

  useEffect(() => {
    if (track !== null && !localParticipant) {
      const child = track.attach();
      trackRef.current.classList.add(track.kind);
      trackRef.current.appendChild(child);
    }
    if (track.kind === 'audio' && localParticipant) {
      const child = track.attach();
      trackRef.current.classList.add(track.kind);
      trackRef.current.appendChild(child);
    }
  }, []);

  return (
    <div
      className={`Track ${track.kind}`}
      id={`${localParticipant ? 'Local' : ''}Track ${track.kind}`}
      ref={trackRef}
    ></div>
  );
};

export default Track;
