import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

//icons
import RemoveIcon from '../assets/Icon_Search_02.png';

type InputAttachmentContainerProps = {
  attachments: File[];
  removeAttachment: (
    index: number,
    attachments: File[],
    setFieldValue: (field: string, value: File[]) => void
  ) => void;
  clearWhenChanged?: any;
  updateFooterHeight: (attachments: File[]) => void;
};

const InputAttachmentContainer: React.FC<InputAttachmentContainerProps> = ({
  attachments,
  removeAttachment,
  clearWhenChanged,
  updateFooterHeight,
}) => {
  const formikContext = useFormikContext();

  useEffect(() => {
    if (!clearWhenChanged) return;
    formikContext.setFieldValue('attachments', []);
    updateFooterHeight([]);
  }, [clearWhenChanged]);

  return (
    <>
      {attachments.length > 0 && (
        <div className="Attachments">
          {attachments.map((a: File, i: number) => (
            <div key={i} className="AttachmentContainer">
              <div className="Attachment">
                <span>{a.name}</span>
                <img
                  src={RemoveIcon}
                  alt="Remove"
                  onClick={() =>
                    removeAttachment(
                      i,
                      attachments,
                      formikContext.setFieldValue
                    )
                  }
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default InputAttachmentContainer;
