// import { CustomError } from '../models/customError';

import { CustomError } from '../models/CustomError';

export const checkForDuplicate = (
  inputFile: any,
  file: File,
  attachments: File[],
  setFieldError: (field: string, error: string) => void
) => {
  const attachment = attachments.find((a: File) => a.name === file.name);
  if (!attachment) return false;
  setFieldError(
    'attachments',
    'A file with the same name has already been included'
  );
  inputFile.current.value = '';
  return true;
};

export const isAttachmentTypeAndSizeAllowed = (
  file: File,
  attachmentMaxSizeInMB: number | undefined,
  attachmentTypesAllowed: string[] | undefined
) => {
  if (!attachmentMaxSizeInMB || !attachmentTypesAllowed) {
    throw new CustomError(
      'Please provide the attachmentMaxSizeInMB and attachmentTypesAllowed props'
    );
  }
  console.log('file.type', file.type);
  console.log('attachmentTypesAllowed', attachmentTypesAllowed);
  const type = file.type;
  const sizeInMb = file.size / 1000000;

  if (
    attachmentTypesAllowed.includes(type) &&
    sizeInMb <= attachmentMaxSizeInMB
  )
    return true;
  return false;
};
