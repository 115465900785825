import { differenceInSeconds, format } from 'date-fns';
import React, { useEffect, useState } from 'react';

type Props = {
  startTime: Date;
};

const Timer: React.FC<Props> = ({ startTime }) => {
  const localStartTime = new Date(
    format(new Date(startTime), 'MM/dd/yyyy hh:mm:ss a') + ' UTC'
  );
  const ellepseTime = differenceInSeconds(new Date(), new Date(localStartTime));

  const [totalSeconds, setTotalSeconds] = useState(ellepseTime);

  useEffect(() => {
    const timerId = setInterval(
      () => setTotalSeconds(prevState => prevState + 1),
      1000
    );

    return () => clearInterval(timerId);
  }, []);

  const getTimePassed = () => {
    let hours: number | string = Math.floor(totalSeconds / 3600);
    let minutes: number | string = Math.floor(
      (totalSeconds - hours * 3600) / 60
    );
    let seconds: number | string = totalSeconds - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    return hours + ':' + minutes + ':' + seconds;
  };

  return <div className="Timer">{getTimePassed()}</div>;
};

export default Timer;
