export const parseDateAndTime = (date: string, format: 'date' | 'dateTime') => {
  const newDate = new Date(date);

  const dateFormat =
    (newDate.getDate() < 10 ? '0' : '') +
    newDate.getDate() +
    '/' +
    (newDate.getMonth() + 1 < 10 ? '0' : '') +
    (newDate.getMonth() + 1) +
    '/' +
    newDate.getFullYear();

  if (format === 'date') return dateFormat;

  if (format === 'dateTime')
    return (
      dateFormat +
      ' ' +
      newDate.getHours() +
      ':' +
      (newDate.getMinutes() < 10 ? '0' : '') +
      newDate.getMinutes()
    );
  return '';
};

export const decodeHTML = (html: string) => {
  var txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};
