import React from 'react';
import { times } from '../helpers/calendarViews';
import { Day, EventItem } from '../models/types';
import { AvailableSlotItem, selectAvailableSlots } from '../reducers/calendar';
import { CalendarProps } from './Calendar';
import DayHeader from './DayHeader';
import TimeSlot from './TimeSlot';
type Props = {
  day: Day;
  events: EventItem[];
  signOut: () => {};
  cancelMeetingUrl: string;
  acceptMeetingUrl: string;
  createUrl: string;
  searchContactsUrl: string;
  editUrl: string;
  declineMeetingUrl: string;
  instance: any;
  useDispatch: any;
  useSelector: any;
};

const DayView: React.FC<Props &
  Pick<
    CalendarProps,
    | 'CustomSlotTile'
    | 'selectCustomAvailableSlots'
    | 'selectCustomMeetingSlots'
    | 'CustomMeetingTile'
  >> = ({
  day,
  events,
  signOut,
  cancelMeetingUrl,
  acceptMeetingUrl,
  declineMeetingUrl,
  createUrl,
  searchContactsUrl,
  editUrl,
  instance,
  useDispatch,
  useSelector,
  CustomSlotTile,
  CustomMeetingTile,
  selectCustomAvailableSlots,
  selectCustomMeetingSlots,
}) => {
  const getAvailability = () => {
    // if (CustomSlotTile && selectCustomAvailableSlots) {
    //   return useSelector(selectCustomAvailableSlots);
    // } else if (CustomMeetingTile && selectCustomMeetingSlots) {
    //   return useSelector(selectCustomMeetingSlots);
    // } else {
    return useSelector(selectAvailableSlots);
    // }
  };

  const availability: AvailableSlotItem[] = getAvailability();

  return (
    <div className="DayView">
      <DayHeader day={day} />
      {times.map((t: any, i: number) => (
        <div className="CalendarRow" key={i}>
          <div className="TimeCell">{t.displayName}</div>
          <TimeSlot
            date={day.date}
            hour={t.code}
            events={events}
            timeSlotMinWidth={120}
            signOut={signOut}
            cancelMeetingUrl={cancelMeetingUrl}
            acceptMeetingUrl={acceptMeetingUrl}
            declineMeetingUrl={declineMeetingUrl}
            createUrl={createUrl}
            editUrl={editUrl}
            availability={availability}
            searchContactsUrl={searchContactsUrl}
            instance={instance}
            useDispatch={useDispatch}
            useSelector={useSelector}
            CustomSlotTile={CustomSlotTile}
            CustomMeetingTile={CustomMeetingTile}
          />
        </div>
      ))}
    </div>
  );
};

export default DayView;
