import { isDate, parse } from "date-fns";

export const decodeHTML = (html: string) => {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

export const uniqueItemsArray = (array: string[]) => {
  return array.filter((value, index, self) => self.indexOf(value) === index);
};

export function parseDateString(value: any, originalValue: any) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, "dd/MM/yyyy", new Date());

  return parsedDate;
}

export const roundWithTwoDecimalPlaces = (num: number) => {
  const str = num.toString();
  return parseFloat(str).toFixed(2);
};

export const getPreferencesArray = (string: string) => {
  if(!string) return []
  return string.split("|");
};

export const getPipeSeparetedString = (array: string[]) => {
  if (array.length === 0) return "";
  return array.join("|");
};

export const parseDateAndTime = (date: string, format: "date" | "dateTime") => {
  const newDate = new Date(date);

  const dateFormat =
    (newDate.getDate() < 10 ? "0" : "") +
    newDate.getDate() +
    "/" +
    (newDate.getMonth() + 1 < 10 ? "0" : "") +
    (newDate.getMonth() + 1) +
    "/" +
    newDate.getFullYear();

  if (format === "date") return dateFormat;

  if (format === "dateTime")
    return (
      dateFormat +
      " " +
      newDate.getHours() +
      ":" +
      (newDate.getMinutes() < 10 ? "0" : "") +
      newDate.getMinutes()
    );
  return "";
};
