import { Store } from './rootReducers';

export enum FilterOptions {
  RECENT = 'Recent',
  CONTACT = 'Contact',
}

export type SignalRConnection = signalR.HubConnection;

/*STATE*/
export type State = {
  userId: number | null;
  selectedChatId: number | null;
  selectedListFilter: FilterOptions;
  showActionsPanel: boolean;
  chatsSignalRConnection: SignalRConnection | null;
  chatsFooterHeight: number | null;
  showLastMessage: boolean;
  labels: { name: string; value: string }[] | null;
};

export const initialState: State = {
  userId: null,
  selectedChatId: null,
  selectedListFilter: FilterOptions.RECENT,
  showActionsPanel: true,
  chatsSignalRConnection: null,
  chatsFooterHeight: null,
  showLastMessage: true,
  labels: null,
};

/* ACTIONS */

export enum Actions {
  SET_USER_ID = 'app/chats/SET_USER_ID',
  SELECT_CHAT = 'app/chats/SELECT_CHAT',
  SELECT_LIST_FILTER = 'app/chats/SELECT_LIST_FILTER',
  SET_SHOW_COMMUNICATION_PANEL = `app/chats/SET_SHOW_COMMUNICATION_PANEL`,
  SET_CHATS_CONNECTION = `app/chats/SET_CHATS_CONNECTION`,
  CLEAR_CHATS_CONNECTION = `app/chats/CLEAR_CHATS_CONNECTION`,
  SET_FOOTER_HEIGHT = `app/chats/SET_FOOTER_HEIGHT`,
  SHOW_LAST_MESSAGE = 'app/chats/SHOW_LAST_MESSAGE ',
  SET_LABELS = 'app/chats/SET_LABELS',
}

export type Action =
  | { type: Actions.SET_USER_ID; payload: number }
  | { type: Actions.SELECT_CHAT; payload: number | null }
  | { type: Actions.SELECT_LIST_FILTER; payload: FilterOptions }
  | { type: Actions.SET_SHOW_COMMUNICATION_PANEL; payload: boolean }
  | { type: Actions.SET_CHATS_CONNECTION; payload: SignalRConnection }
  | { type: Actions.CLEAR_CHATS_CONNECTION }
  | { type: Actions.SET_FOOTER_HEIGHT; payload: number }
  | { type: Actions.SHOW_LAST_MESSAGE; payload: boolean }
  | {
      type: Actions.SET_LABELS;
      payload: { name: string; value: string }[] | null;
    };

export const chats = (state = initialState, action: Action): State => {
  switch (action.type) {
    case Actions.SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
    case Actions.SELECT_CHAT:
      return {
        ...state,
        selectedChatId: action.payload,
      };
    case Actions.SELECT_LIST_FILTER:
      return {
        ...state,
        selectedListFilter: action.payload,
      };
    case Actions.SET_SHOW_COMMUNICATION_PANEL:
      return {
        ...state,
        showActionsPanel: action.payload,
      };
    case Actions.SET_CHATS_CONNECTION:
      return {
        ...state,
        chatsSignalRConnection: action.payload,
      };
    case Actions.CLEAR_CHATS_CONNECTION:
      return {
        ...state,
        chatsSignalRConnection: null,
      };
    case Actions.SET_FOOTER_HEIGHT:
      return {
        ...state,
        chatsFooterHeight: action.payload,
      };
    case Actions.SHOW_LAST_MESSAGE:
      return {
        ...state,
        showLastMessage: action.payload,
      };
    case Actions.SET_LABELS:
      return {
        ...state,
        labels: action.payload,
      };
    default:
      return state;
  }
};

// Action creatores

export const setUserId = (payload: number): Action => ({
  type: Actions.SET_USER_ID,
  payload,
});

export const setSelectedChatId = (payload: number | null): Action => ({
  type: Actions.SELECT_CHAT,
  payload,
});

export const selectListFilter = (payload: FilterOptions): Action => ({
  type: Actions.SELECT_LIST_FILTER,
  payload,
});

export const setShowActionsPanel = (payload: boolean): Action => ({
  type: Actions.SET_SHOW_COMMUNICATION_PANEL,
  payload,
});

export const setChatsConnection = (payload: SignalRConnection): Action => ({
  type: Actions.SET_CHATS_CONNECTION,
  payload,
});

export const clearChatsConnection = (): Action => ({
  type: Actions.CLEAR_CHATS_CONNECTION,
});

export const setFooterHeight = (payload: number): Action => ({
  type: Actions.SET_FOOTER_HEIGHT,
  payload,
});

export const setShowLastMessage = (payload: boolean): Action => ({
  type: Actions.SHOW_LAST_MESSAGE,
  payload,
});

export const setLabels = (
  payload: { name: string; value: string }[] | null
): Action => ({
  type: Actions.SET_LABELS,
  payload,
});

// Selectors

export const selectUserId = (store: Store) => store.chats.userId;

export const selectChatId = (store: Store) => store.chats.selectedChatId;

export const selectFilterOption = (store: Store) =>
  store.chats.selectedListFilter;

export const selectShowActionsPanel = (store: Store) =>
  store.chats.showActionsPanel;

export const selectChatsSignalRConnection = (store: Store) =>
  store.chats.chatsSignalRConnection;

export const selectFooterHeight = (store: Store) =>
  store.chats.chatsFooterHeight;

export const selectShowLastMessage = (store: Store) =>
  store.chats.showLastMessage;

export const selectLabels = (state: Store) => state.chats.labels;
