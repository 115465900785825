import { differenceInMinutes, parseISO } from 'date-fns';
import React from 'react';
import {
  getHeightOfMeetingTile,
  getLengthOfMeeting,
  getMeetingWidth,
  getNumberOfOverlappingMeetingsStartingBefore,
  getOverlappingMeetings,
  getVerticalOffsetOfMeetingTile,
  meetingStartTime,
} from '../helpers/calendarViews';
import { EventItem } from '../models/types';
import { AvailableSlotItem, selectHideEvents } from '../reducers/calendar';
import { CalendarProps } from './Calendar';
import MeetingNotification from './MeetingNotification';

type Props = {
  events: EventItem[];
  timeStamp: Date;
  signOut: () => {};
  cancelMeetingUrl: string;
  acceptMeetingUrl: string;
  declineMeetingUrl: string;
  createUrl: string;
  searchContactsUrl: string;
  editUrl: string;
  instance: any;
  useDispatch: any;
  useSelector: any;
  availability: AvailableSlotItem[];
};

const HalfHourSlot: React.FC<Props &
  Pick<CalendarProps, 'CustomSlotTile' | 'CustomMeetingTile'>> = ({
  events,
  timeStamp,
  signOut,
  cancelMeetingUrl,
  acceptMeetingUrl,
  declineMeetingUrl,
  createUrl,
  searchContactsUrl,
  editUrl,
  instance,
  useDispatch,
  useSelector,
  availability,
  CustomSlotTile,
  CustomMeetingTile,
}) => {
  const meetingsStartingTimes = meetingStartTime(timeStamp, events);

  const hideEvents = useSelector(selectHideEvents);

  const isSlotAvailable = () => {
    const currentDate = new Date(timeStamp).getTime();
    return (
      availability &&
      Array.isArray(availability) &&
      availability.some(
        (slot: AvailableSlotItem) =>
          currentDate >= new Date(slot.startDate).getTime() &&
          currentDate < new Date(slot.endDate).getTime()
      )
    );
  };

  const getFilteredAvailability = () => {
    let filteredAvailability: any;
    if (availability && Array.isArray(availability)) {
      filteredAvailability = availability.filter(slot => {
        const currentDate = new Date(timeStamp).getTime();
        return (
          currentDate >= new Date(slot.startDate).getTime() &&
          currentDate < new Date(slot.endDate).getTime()
        );
      });
    } else {
      filteredAvailability = [];
    }
    return filteredAvailability;
  };
  // TODO Optimise with useMemo;
  // const slotAvailable = isSlotAvailable();
  // let filteredAvailability: any;
  // if (availability && Array.isArray(availability) && slotAvailable) {
  //   filteredAvailability = availability.filter(slot => {
  //     const currentDate = new Date(timeStamp).getTime();
  //     return (
  //       currentDate >= new Date(slot.startDate).getTime() &&
  //       currentDate < new Date(slot.endDate).getTime()
  //     );
  //   });
  // } else {
  //   filteredAvailability = [];
  // }

  if (hideEvents) {
    return (
      <div
        className={`Half ${
          availability && isSlotAvailable() && !CustomSlotTile
            ? 'Available'
            : ''
        }`}
      >
        {isSlotAvailable() && CustomSlotTile ? (
          <CustomSlotTile availability={getFilteredAvailability()} />
        ) : (
          ''
        )}
      </div>
    );
  }

  return (
    <div
      className={`Half ${
        availability && isSlotAvailable() && !CustomSlotTile ? 'Available' : ''
      }`}
    >
      {isSlotAvailable() && CustomSlotTile ? (
        <CustomSlotTile availability={getFilteredAvailability()} />
      ) : (
        ''
      )}
      {meetingsStartingTimes.map((e: EventItem, k: number) => {
        if (CustomMeetingTile) {
          const lengthOfMeeting = getLengthOfMeeting(e.startDate, e.endDate);
          const height = getHeightOfMeetingTile(lengthOfMeeting);
          const meetingStartTimeOffset = differenceInMinutes(
            parseISO(e.startDate),
            timeStamp
          );
          const verticalOffset = getVerticalOffsetOfMeetingTile(
            meetingStartTimeOffset,
            30
          );
          const minIntervalInMinutes = 30;

          const overlappingMeetings = getOverlappingMeetings(
            e.startDate,
            e.endDate,
            minIntervalInMinutes,
            events,
            e.eventsId
          );

          const maxNumberOfOverlappingMeetings = overlappingMeetings.length;

          const meetingOrder = getNumberOfOverlappingMeetingsStartingBefore(
            e.eventsId,
            e.startDate,
            events,
            overlappingMeetings
          );

          const meetingWidth = getMeetingWidth(maxNumberOfOverlappingMeetings);

          const leftPosition = 100 / (maxNumberOfOverlappingMeetings + 1);

          return (
            <CustomMeetingTile
              event={e}
              key={k}
              height={height}
              width={`${meetingWidth}%`}
              top={verticalOffset}
              left={`${meetingOrder === 0 ? 0 : leftPosition * meetingOrder}%`}
            />
          );
        } else {
          return (
            <MeetingNotification
              event={e}
              events={events}
              key={k}
              signOut={signOut}
              cancelMeetingUrl={cancelMeetingUrl}
              acceptMeetingUrl={acceptMeetingUrl}
              declineMeetingUrl={declineMeetingUrl}
              createUrl={createUrl}
              editUrl={editUrl}
              searchContactsUrl={searchContactsUrl}
              instance={instance}
              useDispatch={useDispatch}
              useSelector={useSelector}
            />
          );
        }
      })}
    </div>
  );
};

export default HalfHourSlot;
