import React from 'react';
import { Day, EventItem } from '../models/types';
import { AvailableSlotItem, selectAvailableSlots } from '../reducers/calendar';
import { CalendarProps } from './Calendar';
import TimeSlot from './TimeSlot';

type Props = {
  events: EventItem[];
  hour: number;
  weekDays: Day[];
  weekViewTimeSlotMinWidth: number;
  cancelMeetingUrl: string;
  acceptMeetingUrl: string;
  signOut: () => {};
  createUrl: string;
  searchContactsUrl: string;
  editUrl: string;
  declineMeetingUrl: string;
  instance: any;
  useDispatch: any;
  useSelector: any;
};

const WeekTimeSlots: React.FC<Props &
  Pick<
    CalendarProps,
    | 'CustomSlotTile'
    | 'CustomMeetingTile'
    | 'selectCustomAvailableSlots'
    | 'selectCustomMeetingSlots'
  >> = ({
  events,
  hour,
  weekDays,
  weekViewTimeSlotMinWidth,
  cancelMeetingUrl,
  acceptMeetingUrl,
  signOut,
  declineMeetingUrl,
  createUrl,
  searchContactsUrl,
  editUrl,
  instance,
  useDispatch,
  useSelector,
  CustomSlotTile,
  CustomMeetingTile,
  selectCustomAvailableSlots,
  selectCustomMeetingSlots,
}) => {
  // TODO Add redux state representing whether availabile slots are selected
  const getAvailability = () => {
    return useSelector(selectAvailableSlots);
  };

  const availability: AvailableSlotItem[] = getAvailability();

  return (
    <>
      {weekDays.map((day: any, idx: number) => (
        <TimeSlot
          key={idx}
          date={day.date}
          hour={hour}
          events={events}
          availability={availability}
          timeSlotMinWidth={weekViewTimeSlotMinWidth}
          cancelMeetingUrl={cancelMeetingUrl}
          acceptMeetingUrl={acceptMeetingUrl}
          signOut={signOut}
          declineMeetingUrl={declineMeetingUrl}
          createUrl={createUrl}
          editUrl={editUrl}
          searchContactsUrl={searchContactsUrl}
          instance={instance}
          useDispatch={useDispatch}
          useSelector={useSelector}
          CustomSlotTile={CustomSlotTile}
          CustomMeetingTile={CustomMeetingTile}
        />
      ))}
    </>
  );
};

export default WeekTimeSlots;
