import React, { useEffect, useState } from 'react';
import Participant from './Participant';
import RoomHeader from './RoomHeader';

type Props = {
  room: any;
  leaveCall: () => void;
  externalParticipantDisplayName?: string;
  callSignalRConnection?: any;
};

const Room: React.FC<Props> = ({
  room,
  leaveCall,
  externalParticipantDisplayName,
  callSignalRConnection,
}) => {
  const [remoteParticipants, setRemoteParticipants] = useState<any[]>(
    Array.from(room.room.participants.values())
  );
  const [isAudioDisabled, setIsAudioDisabled] = useState(false);
  const [isVideoDisabled, setIsVideoDisabled] = useState(false);

  const toggleAudioTrack = () => setIsAudioDisabled(prevState => !prevState);
  const toggleVideoTrack = () => setIsVideoDisabled(prevState => !prevState);

  const leaveRoom = () => {
    window.localStorage.removeItem('ongoingCall');

    const endCallData = externalParticipantDisplayName
      ? {
          callsId: room.callsId,
          externalParticipantDisplayName: externalParticipantDisplayName,
        }
      : { callsId: room.callsId };

    if (remoteParticipants.length === 0 && callSignalRConnection) {
      callSignalRConnection.invoke('EndCall', endCallData).catch((err: any) => {
        console.error(err.toString());
      });
    }
    room.room.disconnect();
    leaveCall();
  };

  const addParticipant = (participant: any) => {
    setRemoteParticipants(prevState => [...prevState, participant]);
  };
  const removeParticipant = (participant: any) => {
    const newState = remoteParticipants.filter((p: any) => {
      return p.identity !== participant.identity;
    });
    setRemoteParticipants(newState);
  };

  useEffect(() => {
    room.room.on('participantConnected', (participant: any) =>
      addParticipant(participant)
    );
    room.room.on('participantDisconnected', (participant: any) => {
      removeParticipant(participant);
    });

    window.addEventListener('beforeunload', leaveCall);

    return () => leaveCall();
  }, []);

  //grid layouts
  const [layouts, setLayouts] = useState<any>(null);

  useEffect(() => {
    setCallParticipantsLayout(remoteParticipants.length, setLayouts);
  }, [remoteParticipants]);

  const [participantWidth, setParticipantWidth] = useState<number>(100);

  const getParticipantsWidth = () => {
    if (remoteParticipants.length === 0) setParticipantWidth(100);
    if (remoteParticipants.length === 1) setParticipantWidth(50);
    if (remoteParticipants.length >= 2 && remoteParticipants.length <= 5) {
      const participantsPerRow =
        100 / Math.ceil((remoteParticipants.length + 1) / 2);
      setParticipantWidth(participantsPerRow);
    }
    if (remoteParticipants.length >= 7) {
      const participantsPerRow =
        100 / Math.ceil((remoteParticipants.length + 1) / 3);
      setParticipantWidth(participantsPerRow);
    }
  };

  const [participantHeight, setParticipantHeight] = useState<number>(100);

  const getParticipantsHeight = () => {
    if (remoteParticipants.length === 0) setParticipantWidth(100);
    if (remoteParticipants.length === 1) setParticipantWidth(50);
    if (remoteParticipants.length >= 2 && remoteParticipants.length <= 5) {
      setParticipantHeight(100 / 2);
    }
    if (remoteParticipants.length >= 7) {
      setParticipantHeight(100 / 3);
    }
  };

  useEffect(() => {
    getParticipantsWidth();
    getParticipantsHeight();
  }, [remoteParticipants]);

  if (layouts)
    return (
      <div className="Underlay">
        <div className="Room">
          <RoomHeader
            room={room}
            toggleVideoTrack={toggleVideoTrack}
            isVideoDisabled={isVideoDisabled}
            toggleAudioTrack={toggleAudioTrack}
            isAudioDisabled={isAudioDisabled}
            leaveRoom={leaveRoom}
          />
          <div className="Participants">
            <div
              key={'-1'}
              className={'ParticipantContainer'}
              style={{
                width: participantWidth + '%',
                height: participantHeight + '%',
              }}
            >
              <Participant
                key={room.room.localParticipant.identity + '-1'}
                index={'-1'}
                localParticipant={true}
                participant={room.room.localParticipant}
                isLocalVideoTrackDisabled={isVideoDisabled}
                isLocalAudioTrackDisabled={isAudioDisabled}
                callType={room.type}
              />
            </div>

            {remoteParticipants.map((participant, i) => (
              <div
                key={i.toString()}
                className={'ParticipantContainer'}
                style={{
                  width: participantWidth + '%',
                  height: participantHeight + '%',
                }}
              >
                <Participant
                  key={participant.identity + i.toString()}
                  index={i.toString()}
                  participant={participant}
                  localParticipant={false}
                  isLocalVideoTrackDisabled={isVideoDisabled}
                  isLocalAudioTrackDisabled={isAudioDisabled}
                  callType={room.type}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  return null;
};

export default Room;
