import { FormTypes } from './types';

export interface ChatDetails {
  chatsId: number;
  title: string;
  description: string;
  imageUrl: string;
  messages: MessageItem[];
  status: string | null;
  labels: { name: string; value: string }[];
}

export interface MessageItem {
  Id: number;
  senderSiteUsersId: number;
  senderName: string;
  content: string;
  type: string;
  addDate: string;
  bUnread: boolean;
  attachments: {
    fileName: string;
    displayFileName: string;
  }[];
}

export interface MessageDetails {
  message: string;
  attachments: [];
}

export interface ISentMessageResponse {
  addDate: string;
  attachments: {
    fileName: string;
    displayFileName: string;
  }[];
  chatsId: number;
  content: string;
  id: number;
  senderCommunicationsModuleIdentifier: number;
  senderName: string;
}

export interface IPagination {
  pageNumber: number;
  pageSize: number;
}

export class ChatsParser {
  public static createEmptyMessageDetails: () => MessageDetails = () => ({
    message: '',
    attachments: [],
  });

  public static buildSendMassagePayload = (
    selectedChatId: number,
    message: string,
    attachments: File[],
    connectionId: any,
    formType: FormTypes,
    sendMessageAdditionalParams: any
  ) => {
    const bodyObj = {
      content: message
        ? message
        : !message && attachments && attachments.length > 0
        ? 'Attachments only'
        : '',
      chatsId: selectedChatId,
      signalRConnectionId: connectionId,
      ...sendMessageAdditionalParams,
    };

    const formData = new FormData();

    const entries = Object.entries(bodyObj);
    const attachmentEntries = attachments.map((a: File) => ['attachments', a]);
    const newEntries = [...entries, ...attachmentEntries];

    newEntries.map((e: any[]) => formData.append(e[0], e[1]));

    return formType === 'multipart'
      ? formData
      : { ...bodyObj, attachments: [] };
  };

  public static createSuccessfullySentMessage = ({
    addDate,
    attachments,
    content,
    id,
    senderCommunicationsModuleIdentifier,
    senderName,
  }: ISentMessageResponse) => ({
    Id: id,
    senderSiteUsersId: senderCommunicationsModuleIdentifier,
    senderName: senderName,
    content: content,
    type: 'Sent',
    addDate: addDate,
    bUnread: false,
    attachments: attachments,
  });

  public static parseError = (errors: any) => {
    let error: string;
    switch (errors[0].messageCode) {
      default:
        error = 'Sorry, something went wrong. Please try again';
    }
    return error;
  };
}
