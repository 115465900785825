import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { subDays } from 'date-fns';
// import moment from 'moment';
import { setUpdateEvents } from '../reducers/calendar';
import { useToast } from '../hooks/useToast';
import {
  getISODate,
  getResourcesIds,
  getSelectedParticipantsList,
  getStringDate,
  getStringTime,
  ISODateWithoutTimezone,
  isResourceSelected,
  meetingBookingTime,
  setMinimumMeetingInterval,
} from '../helpers/calendarViews';
import { parseDateString } from '../helpers/formatting';
import FormTextField from './FormTextField';
import { FormCheckboxField } from './FormCheckboxField';
import { ErrorM } from './ErrorM';
import FormDateTimeField from './FormDateTimeField';
import FormMultiselectDropdownField from './FormMultiselectDropdownField';
import RightSideMenu from './RightSideMenu';
import { Spinner } from './Spinner';
import Button from './Button';
import DateIcon from '../assets/Icon_Date.png';
import VideoIcon from '../assets/Icon_VideoCall_01.png';
import PhoneIcon from '../assets/Icon_PhoneCall_01.png';
import ActiveVideoIcon from '../assets/Icon_VideoCall_02.png';
import ActivePhoneIcon from '../assets/Icon_PhoneCall_02.png';
import { translations } from '../helpers/translations';
type Props = {
  isOpen: boolean;
  // disabled: boolean;
  closeMenu: () => void;
  signOut: () => {};
  action: 'create' | 'edit';
  id?: number;
  createUrl: string;
  editUrl: string;
  searchContactsUrl: string;
  instance: any;
  useDispatch: any;
  useSelector: any;
};

const NewMeetingForm: React.FC<Props> = ({
  isOpen,
  // disabled,
  closeMenu,
  action,
  id,
  createUrl,
  editUrl,
  searchContactsUrl,
  signOut,
  instance,
  useDispatch,
  useSelector,
}) => {
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState<any>(null);
  const [details, setDetails] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [clearSearch, setClearSearch] = useState<boolean>(false);
  const [generalErrors, setGeneralErrors] = useState<string[]>([]);

  const handleToast = useToast(
    'The mediation will only take place if all participants agree to the terms of mediations. The mediation agreement will be emailed to all participants shortly',
    'green',
    7000,
    useDispatch
  );

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const emptyValues = {
    title: '',
    date: '',
    startTime: '',
    endTime: '',
    eventTypesId: '',
    participantContacts: [],
    mediator: false,
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(
      translations.errors.required ?? 'This is required field'
    ),
    date: Yup.date()
      .required(translations.errors.required ?? 'This is required field')
      .typeError(translations.errors.invalid_date ?? 'Date is invalid')
      .transform(parseDateString)
      .min(
        subDays(new Date(), 1),
        translations.errors.invalid_date ?? 'Date is invalid'
      ),
    startTime: Yup.string()
      .required(translations.errors.required ?? 'This is required field')
      .test(
        'isValid',
        translations.errors.invalid_date ?? 'Date is invalid',
        function(value) {
          if (!this.parent.endTime || !value) return true;

          const startHour = value?.split(':')[0];
          const startMinutes = value?.split(':')[1];
          const endHour = this.parent.endTime?.split(':')[0];
          const endMinutes = this.parent.endTime?.split(':')[1];

          if (startHour === endHour) {
            if (startMinutes === endMinutes || startMinutes === '30')
              return false;
            return true;
          } else if (parseInt(startHour) > parseInt(endHour)) return false;
          return true;
        }
      ),
    endTime: Yup.string().required(
      translations.errors.required ?? 'This is required field'
    ),
    eventTypesId: Yup.number().required(
      translations.errors.required ?? 'This is required field'
    ),
    participantContacts: Yup.array().test(
      'isArrayEmpty',
      translations.errors.required ?? 'This is required field',
      (value: any) => {
        if (value.length === 0) return false;
        return true;
      }
    ),
    mediator: Yup.boolean(),
  });

  const detailsUrl =
    action === 'create'
      ? createUrl
      : editUrl + `?id=${id}&timeZone=${timeZone}`;

  //get form types of events and contacts list
  useEffect(() => {
    instance
      .get(detailsUrl)
      .then((res: any) => {
        if (res.status === 200) {
          if (res.data.status === '1') {
            // const contactOptions = res.data.details.contacts.map((c: any) => ({
            //   label: c.name,
            //   value: c.siteUsersId,
            // }));
            if (action === 'create') {
              setDetails({
                ...res.data.details,
                // contactOptions: contactOptions,
                contactOptions: [],
              });
              setInitialValues(emptyValues);
            } else if (action === 'edit') {
              setDetails({
                contacts: res.data.details.contacts,
                // contactOptions: contactOptions,
                contactOptions: [],
                resources: res.data.details.resources,
                types: res.data.details.types,
              });
              setInitialValues({
                title: res.data.details.title,
                date: getStringDate(res.data.details.startDate),
                startTime: getStringTime(res.data.details.startDate),
                endTime: getStringTime(res.data.details.endDate),
                participantContacts:
                  res.data.details.selectedContactsSiteUsersIds,
                eventTypesId: res.data.details.eventTypesId,
                mediator: isResourceSelected(
                  'mediator',
                  res.data.details.selectedResourcesIds,
                  res.data.details.resources
                ),
              });
            }
          }
        }
      })
      .catch((err: any) => {
        console.log(err);
        if (err.response && err.response.status === 403) {
          dispatch(signOut());
        }
      });
  }, []);

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  //functions related to search contacts
  const handleSearchTermChange = (value: string) => {
    if (value === '') {
      return handleClearSearch();
    }
    setSearchTerm(value);
  };

  const handleSearchSubmit = () => {
    setClearSearch(true);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (searchTerm) handleSearchSubmit();
    }
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setClearSearch(false);
  };

  //search contacts
  useEffect(() => {
    if (!initialValues) return;
    instance
      .get(searchContactsUrl + `?search=${searchTerm}`)
      .then((res: any) => {
        setDetails((prevState: any) => {
          const contactOptions = res.data.details.map((c: any) => ({
            label: c.displayLabel,
            value: c.siteUsersId,
          }));
          const newState = { ...prevState, contactOptions: contactOptions };
          return newState;
        });
      })
      .catch((err: any) => {
        console.log(err);
        if (err.response && err.response.status === 403) {
          dispatch(signOut());
        }
      });
  }, [initialValues, clearSearch]);

  const submitUrl = action === 'create' ? createUrl : editUrl;
  //create or update meeting
  const handleSubmitForm = (values: any, formik: FormikHelpers<any>) => {
    if (generalErrors.length > 0) setGeneralErrors([]);

    const startISODate = ISODateWithoutTimezone(
      getISODate(values.date, values.startTime)
    );
    const endISODate = ISODateWithoutTimezone(
      getISODate(values.date, values.endTime)
    );

    let bodyObj: any = {
      title: values.title,
      eventTypesId: values.eventTypesId,
      timeZone: timeZone,
      startDate: startISODate,
      endDate: endISODate,
      participantContacts: getSelectedParticipantsList(
        values.participantContacts,
        details.contacts
      ),
      resourcesIds: getResourcesIds(
        values.mediator ? ['mediator'] : [],
        details.resources
      ),
    };

    if (action === 'edit' && id) {
      bodyObj = { ...bodyObj, id };
    }

    instance
      .post(submitUrl, bodyObj)
      .then((res: any) => {
        if (res.status === 200) {
          if (res.data.status === '1') {
            dispatch(setUpdateEvents(true));
            values.mediator && handleToast();
            closeMenu();
          }
        }
      })
      .catch((err: any) => {
        console.log(err);
        if (err.response && err.response.status === 403) {
          dispatch(signOut());
        }
        setGeneralErrors([
          translations.errors.something_went_wrong_try_again ?? '',
        ]);
        formik.setSubmitting(false);
      });
  };

  if (initialValues && details.contactOptions.length > 0)
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values, formik) => handleSubmitForm(values, formik)}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form onKeyDown={onKeyDown}>
            <RightSideMenu
              title={
                action === 'create'
                  ? translations.meetings.new_meeting ?? ''
                  : translations.meetings.update_meeting ?? ''
              }
              isOpen={isOpen}
              buttonText1={translations.general_messages.cancel ?? ''}
              buttonText2={translations.general_messages.apply ?? ''}
              errors={generalErrors}
            >
              <div className="NewMeetingPanel">
                {details ? (
                  <>
                    <FormTextField
                      field={'title'}
                      label={translations.general_messages.title ?? ''}
                      type="text"
                      required={true}
                      sideBySide={true}
                    />
                    <FormDateTimeField
                      icon={DateIcon}
                      dateField={'date'}
                      timeField={'startTime'}
                      label={translations.general_messages.start_date ?? ''}
                      required={true}
                      placeholder={'dd / mm / yyyy'}
                      sideBySide={true}
                      timeOptions={meetingBookingTime}
                      action={setMinimumMeetingInterval}
                    />
                    <FormDateTimeField
                      icon={DateIcon}
                      dateField={'date'}
                      timeField={'endTime'}
                      label={translations.general_messages.end_date ?? ''}
                      required={true}
                      placeholder={'dd / mm / yyyy'}
                      sideBySide={true}
                      timeOptions={meetingBookingTime.slice(
                        0,
                        meetingBookingTime.length - 1
                      )}
                    />
                    <div className="FormBox SideBySide">
                      <div className="FormLabel">
                        <label>
                          {translations.general_messages.communication_type ??
                            ''}
                        </label>
                      </div>
                      <div className="FormField">
                        <div className="ButtonContainer">
                          {details.types.map((t: any, k: number) => (
                            <Button
                              key={k}
                              color={
                                values.eventTypesId === t.id
                                  ? 'paleBlue'
                                  : 'transparent'
                              }
                              style={{
                                width: `${100 / details.types.length}%`,
                                marginRight: `${
                                  k !== details.types.length - 1 ? 5 : 0
                                }px`,
                                marginLeft: `${k !== 0 ? 5 : 0}px`,
                              }}
                              onClick={() =>
                                setFieldValue('eventTypesId', t.id)
                              }
                              type={'button'}
                            >
                              <img
                                src={
                                  t.code === 'VOICE'
                                    ? values.eventTypesId === t.id
                                      ? ActivePhoneIcon
                                      : PhoneIcon
                                    : values.eventTypesId === t.id
                                    ? ActiveVideoIcon
                                    : VideoIcon
                                }
                                alt="Phone call"
                              />
                              {t.code === 'VOICE'
                                ? translations.general_messages.voice ?? ''
                                : t.code === 'VIDEO'
                                ? translations.general_messages.video ?? ''
                                : ''}
                            </Button>
                          ))}
                        </div>
                        <ErrorM name={'eventTypesId'} />
                      </div>
                    </div>
                    <FormMultiselectDropdownField
                      label={translations.general_messages.contacts ?? ''}
                      field={'participantContacts'}
                      selectedValues={values['participantContacts']}
                      sideBySide={true}
                      required={true}
                      select={'multiSelect'}
                      options={details.contactOptions}
                      formSearchKey={searchTerm}
                      searchTermChange={value => handleSearchTermChange(value)}
                      clearSearch={clearSearch}
                      handleSearchSubmit={handleSearchSubmit}
                      handleKeyUp={handleKeyUp}
                      handleClearSearch={handleClearSearch}
                      readOnly={initialValues.mediator}
                      placeholder={
                        translations.general_messages.type_your_search_here ??
                        ''
                      }
                    />
                    {details.resources.map((r: any, k: number) => (
                      <FormCheckboxField
                        key={k}
                        field={r.code.toLowerCase()}
                        label={
                          r.code === 'MEDIATOR'
                            ? translations.general_messages.mediator_present ??
                              ''
                            : ''
                        }
                        readOnly={initialValues.mediator}
                      />
                    ))}
                  </>
                ) : (
                  <Spinner color="blue" size={10} positionAbsolute={true} />
                )}
              </div>
            </RightSideMenu>
          </Form>
        )}
      </Formik>
    );
  return null;
};

export default NewMeetingForm;
