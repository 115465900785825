import React from 'react';
import { clearRoom, selectRoom } from '../reducers/calls';
import Room from './Room';

type Props = {
  callSignalRConnection?: any;
  useDispatch: any;
  useSelector: any;
};

const Calls: React.FC<Props> = ({
  callSignalRConnection,
  useSelector,
  useDispatch,
}) => {
  const dispatch = useDispatch();
  const room = useSelector(selectRoom);
  if (room)
    return (
      <Room
        leaveCall={() => dispatch(clearRoom())}
        room={room}
        callSignalRConnection={callSignalRConnection}
      />
    );
  return null;
};

export default Calls;
