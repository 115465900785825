import React from 'react';
import {
  maxGetMaximumOfOverlappingMeetingPerDay,
  times,
} from '../helpers/calendarViews';
import { Day, EventItem } from '../models/types';
import { CalendarProps } from './Calendar';
import WeekHeader from './WeekHeader';
import WeekTimeSlots from './WeekTimeSlots';

type Props = {
  weekDays: Day[];
  events: EventItem[];
  cancelMeetingUrl: string;
  acceptMeetingUrl: string;
  signOut: () => {};
  createUrl: string;
  searchContactsUrl: string;
  editUrl: string;
  declineMeetingUrl: string;
  instance: any;
  useDispatch: any;
  useSelector: any;
};

const WeekView: React.FC<Props &
  Pick<
    CalendarProps,
    | 'CustomSlotTile'
    | 'selectCustomAvailableSlots'
    | 'selectCustomMeetingSlots'
    | 'CustomMeetingTile'
  >> = ({
  weekDays,
  events,
  cancelMeetingUrl,
  acceptMeetingUrl,
  signOut,
  declineMeetingUrl,
  createUrl,
  searchContactsUrl,
  editUrl,
  instance,
  useDispatch,
  useSelector,
  CustomSlotTile,
  CustomMeetingTile,
  selectCustomAvailableSlots,
  selectCustomMeetingSlots,
}) => {
  const weekViewTimeSlotMinWidth = 120;
  const updatedWeekDays = weekDays.map(d => ({
    ...d,
    overlappingMeetings: maxGetMaximumOfOverlappingMeetingPerDay(
      d.date,
      events
    ),
  }));

  return (
    <div className="WeekView">
      <WeekHeader
        weekDays={updatedWeekDays}
        weekViewTimeSlotMinWidth={weekViewTimeSlotMinWidth}
      />
      {times.map((t: any, i: number) => (
        <div className="CalendarRow">
          <div className="TimeCell">{t.displayName}</div>
          <WeekTimeSlots
            key={i}
            weekDays={weekDays}
            hour={t.code}
            events={events}
            weekViewTimeSlotMinWidth={weekViewTimeSlotMinWidth}
            cancelMeetingUrl={cancelMeetingUrl}
            acceptMeetingUrl={acceptMeetingUrl}
            signOut={signOut}
            declineMeetingUrl={declineMeetingUrl}
            createUrl={createUrl}
            editUrl={editUrl}
            searchContactsUrl={searchContactsUrl}
            instance={instance}
            useDispatch={useDispatch}
            useSelector={useSelector}
            CustomSlotTile={CustomSlotTile}
            CustomMeetingTile={CustomMeetingTile}
            selectCustomAvailableSlots={selectCustomAvailableSlots}
            selectCustomMeetingSlots={selectCustomMeetingSlots}
          />
        </div>
      ))}
    </div>
  );
};

export default WeekView;
