import React from 'react';
import classNames from 'classnames';

type ButtonVariety = 'full' | 'small' | 'blue' | 'slim' | '';

type Button = JSX.IntrinsicElements['button'] & {
  color?: string;
  variety?: ButtonVariety;
  border?: string;
  disabled?: boolean;
  onClick?: () => void;
  type?: string;
};

function varietyToClassname(v: ButtonVariety): string {
  switch (v) {
    case 'full':
      return 'BtnFull';
    case 'small':
      return 'BtnSmall';
    case 'slim':
      return 'BtnSlim';
    default:
      return '';
  }
}

const Button: React.FC<Button> = ({
  variety = '',
  color,
  border = '',
  className,
  ...props
}) => (
  <button
    style={{ background: color, borderColor: border ? border : color }}
    className={classNames('Btn', varietyToClassname(variety), className)}
    {...props}
  />
);

export default Button;
