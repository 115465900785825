import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import {
  FilterOptions,
  selectChatId,
  selectChatsSignalRConnection,
  selectFilterOption,
  selectShowActionsPanel,
  setSelectedChatId,
  setShowActionsPanel,
} from '../reducers/chats';
import { parseDateAndTime } from '../helpers/parseData';
import { ChatsApi } from '../api/ChatsApi';
import { ChatsList } from '../models/CommunicationActionPanel';
import { updateRecentChatList } from '../helpers/signalR';
import { ColorSchema, Endpoints } from '../models/types';
import SearchBox from './form/SearchBox';
import { Spinner } from './ui/Spinner';

//icons
import DefaultImage from '../assets/Img_Default.jpg';
import Filters from './Filters';
import { setLastMessageToRead } from '../helpers/chats';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IPagination } from '../models/Chats';
import { INITIAL_CONTACTS_PAGINATION } from '../appConstants';
import { selectTranslations } from '../reducers/translations';

type Props = {
  instance: any;
  baseURL: string;
  pagePath?: string;
  endpoints: Endpoints;
  colorSchema: ColorSchema;
  hasContactList: boolean;
  urlChatId: number | null;
  useDispatch: any;
  useSelector: any;
};

const CommunicationActionPanel: React.FC<Props> = ({
  instance,
  baseURL,
  pagePath,
  endpoints,
  colorSchema,
  hasContactList,
  urlChatId,
  useSelector,
  useDispatch,
}) => {
  const dispatch = useDispatch();
  const selectedFilter = useSelector(selectFilterOption);
  const showCommunicationPanel = useSelector(selectShowActionsPanel);
  const selectedChatId = useSelector(selectChatId);
  const chatsConnection = useSelector(selectChatsSignalRConnection);

  const [chatsList, setChatsList] = useState<ChatsList>([]);
  const [error, setError] = useState<string>('');

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [updateChatsList, setUpdateChatsList] = useState<boolean>(true);
  const [hasMoreContacts, setHasMoreContacts] = useState<boolean>(true);
  const [pagination, setPagination] = useState<IPagination>(
    INITIAL_CONTACTS_PAGINATION
  );
  const [searchResultsDisplaying, setSearchResultsDisplaying] = useState<
    boolean
  >(false);

  const translations = useSelector(selectTranslations);

  const getChatsList = async () => {
    //clear errors and list
    setError('');

    if (searchTerm) {
      setSearchResultsDisplaying(true);
    } else {
      setSearchResultsDisplaying(false);
    }

    const url = `${baseURL}${
      selectedFilter === FilterOptions.RECENT
        ? endpoints.chats.recentChats + '?search=' + searchTerm
        : endpoints.contacts?.contacts +
          '?search=' +
          searchTerm +
          '&pageNumber=' +
          pagination.pageNumber +
          '&pageSize=' +
          pagination.pageSize
    }`;
    const result = await ChatsApi.getChatsList(instance, url);
    if (result.data) {
      const list = result.data;
      if (
        !urlChatId &&
        !selectedChatId &&
        list.length > 0 &&
        selectedFilter === FilterOptions.RECENT
      ) {
        const id = list[0].chatsId;
        dispatch(setSelectedChatId(id));
        pagePath && navigate(`${pagePath}/${id}`);
      }
      if (selectedFilter === FilterOptions.CONTACT) {
        if (list.length > 0) {
          pagination.pageNumber === 1
            ? setChatsList(list)
            : setChatsList([...chatsList, ...list]);
          setPagination({
            ...pagination,
            pageNumber: pagination.pageNumber + 1,
          });
        } else {
          setHasMoreContacts(false);
        }
      } else if (selectedFilter === FilterOptions.RECENT) {
        setChatsList(list);
      }
    }

    if (result.error) {
      if (
        result.error.message === 'Sorry, something went wrong. Please try again'
      ) {
        setError(translations?.defaultError ?? result.error.message);
      } else {
        setError(result.error.message);
      }
      setError(result.error.message);
      setUpdateChatsList(false);
    }
    setUpdateChatsList(false);
  };

  useEffect(() => {
    if (!updateChatsList) return;
    setHasMoreContacts(true);
    getChatsList();
  }, [updateChatsList]);

  useEffect(() => {
    if (selectedFilter === FilterOptions.RECENT) {
      setPagination(INITIAL_CONTACTS_PAGINATION);
      setHasMoreContacts(true);
    }
  }, [selectedFilter]);

  const startChatForContact = async (contactsId: number) => {
    const result = await ChatsApi.getChatIdForContact(
      instance,
      baseURL + endpoints.chats.createForContact,
      contactsId
    );

    if (result.data) {
      const chatId = result.data.chatsId;
      dispatch(setSelectedChatId(chatId));
      pagePath && navigate(`${pagePath}/${chatId}`);
    }

    if (result.error) {
      //TODO: handle error
    }
  };

  useEffect(() => {
    if (!chatsConnection || !selectedChatId || !chatsList) return;
    updateRecentChatList(
      chatsConnection,
      selectedFilter,
      chatsList,
      setChatsList,
      selectedChatId
    );
  }, [chatsConnection, chatsList]);

  return (
    <div
      className={`ActionPanelContainer ${
        showCommunicationPanel ? 'Visible' : 'Hidden'
      }`}
    >
      <div className="InnerWidthContent">
        <div className="ActionsHeader">
          <h2>{translations?.communicationActionPanelChatHeader ?? 'Chat'}</h2>
          <Filters
            colorSchema={colorSchema}
            setUpdateChatsList={setUpdateChatsList}
            hasContactList={hasContactList}
            useDispatch={useDispatch}
            useSelector={useSelector}
          />
        </div>
        <SearchBox
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setUpdateChatsList={setUpdateChatsList}
          setPagination={setPagination}
        />

        {chatsList && chatsList.length > 0 && (
          <h2 className="RecentChatsHeader">
            {searchResultsDisplaying
              ? 'Search results'
              : `${chatsList.length} Recent ${
                  chatsList.length === 1 ? 'chat' : 'chats'
                }`}
          </h2>
        )}
      </div>

      <div id="MessagesContainer" className="MessagesContainer">
        {updateChatsList ? (
          <Spinner positionAbsolute={true} />
        ) : (
          <>
            {chatsList && chatsList.length === 0 && !error && (
              <div className="Alert">
                <p style={{ textAlign: 'center' }}>
                  {translations?.communicationActionPanelNoChats ?? 'No chats'}
                </p>
              </div>
            )}
            {error && (
              <div className="Alert">
                <p style={{ textAlign: 'center' }}>
                  {translations?.communicationActionPanelError ??
                    'Something went wrong. Please try again.'}
                </p>
              </div>
            )}

            <InfiniteScroll
              next={
                selectedFilter === FilterOptions.CONTACT
                  ? () => {
                      getChatsList();
                    }
                  : () => {}
              }
              hasMore={hasMoreContacts}
              loader={
                selectedFilter === FilterOptions.CONTACT ? (
                  <Spinner positionAbsolute style={{ bottom: '0px' }} />
                ) : null
              }
              dataLength={chatsList.length}
              scrollableTarget={'MessagesContainer'}
              style={{
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
                marginTop: 20,
              }}
            >
              {chatsList.map((i: any, k: number) => {
                return (
                  <div
                    className={`Message InnerWidthContent ${selectedChatId ===
                      i.chatsId && 'Selected'}`}
                    key={k}
                    onClick={() => {
                      if (selectedFilter === FilterOptions.RECENT) {
                        setLastMessageToRead(
                          i.chatsId,
                          chatsList,
                          setChatsList
                        );
                        dispatch(setSelectedChatId(i.chatsId));
                        pagePath && navigate(`${pagePath}/${i.chatsId}`);
                      } else if (selectedFilter === FilterOptions.CONTACT) {
                        startChatForContact(i.contactsId);
                      }
                      dispatch(setShowActionsPanel(false));
                    }}
                  >
                    {i.imageUrl ? (
                      <div
                        className="ImageContainer"
                        style={{
                          backgroundImage: `url(${i.imageUrl ??
                            DefaultImage}), url(${DefaultImage})`,
                        }}
                      ></div>
                    ) : (
                      <div className="MessagesSidePanelSelector__icon">
                        {i.title && typeof i.title && i.title.substr(0, 1)}
                      </div>
                    )}
                    <div className="MessageDetails">
                      <div className="MessageHeader">
                        <h4>
                          {selectedFilter === 'Recent' ? i.title : i.name}
                        </h4>
                        {selectedFilter === 'Recent' && (
                          <span>
                            {parseDateAndTime(i.lastActivityDate, 'date')}
                          </span>
                        )}
                      </div>
                      {i.lastMessageContent &&
                        i.status !== 'Pending' &&
                        i.status !== 'Declined' && (
                          <div className="LastMessage">
                            <span
                              className="Text"
                              style={{
                                fontWeight:
                                  i.unreadMessageCount > 0 ? 700 : 400,
                              }}
                            >
                              {i.lastMessageContent}
                            </span>
                            {i.unreadMessageCount > 0 && (
                              <span
                                className="UnreadMessages"
                                style={{ background: colorSchema.primaryColor }}
                              >
                                {i.unreadMessageCount}
                              </span>
                            )}
                          </div>
                        )}
                      {selectedFilter === 'Contact' && <span>{i.type}</span>}
                      <div className="Status">
                        {i.bRequireParticipantAcceptance &&
                          !i.lastMessageContent &&
                          i.status === 'Accepted' && (
                            <div className="Accepted">
                              {/* <img src={AcceptedIcon} alt="Accepted" /> */}
                              {translations?.communicationActionPanelAccepted ??
                                'Accepted'}
                            </div>
                          )}
                        {i.bRequireParticipantAcceptance &&
                          !i.lastMessageContent &&
                          i.status === 'Requested' && (
                            <div className="Requested">
                              {/* <img src={RequestedIcon} alt="Requested" /> */}
                              {translations?.communicationActionPanelRequested ??
                                'Requested'}
                            </div>
                          )}
                        {i.bRequireParticipantAcceptance &&
                          i.status === 'Declined' && (
                            <div className="Declined">
                              {/* <img src={DeclinedIcon} alt="Declined" /> */}
                              {translations?.communicationActionPanelDeclined ??
                                'Declined'}
                            </div>
                          )}
                      </div>
                      {i.bRequireParticipantAcceptance &&
                        i.status === 'Pending' && (
                          <div className="Request">
                            <div
                              className="Accept"
                              onClick={
                                () => {}
                                //   acceptOrDeclineChatRequests('accept', i.chatsId)
                              }
                            >
                              <span>
                                {translations?.communicationActionPanelAccept ??
                                  'Accept'}
                              </span>
                            </div>
                            <div
                              className="Decline"
                              onClick={
                                () => {}
                                //   acceptOrDeclineChatRequests('decline', i.chatsId)
                              }
                            >
                              <span>
                                {translations?.communicationActionPanelDecline ??
                                  'Decline'}
                              </span>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          </>
        )}
      </div>
    </div>
  );
};

export default CommunicationActionPanel;
