import React, { useEffect, useState } from 'react';
import useMedia from 'use-media';
import { ChatsApi } from '../api/ChatsApi';
import { FooterHeight, INITIAL_PAGINATION } from '../appConstants';
import {
  createSignalRConnection,
  receiveMessages,
  // receiveMessages,
  startConnection,
} from '../helpers/signalR';
import sortMessages from '../helpers/sortMessages';
import { ChatDetails, IPagination } from '../models/Chats';
import { ColorSchema, Endpoints, FormTypes } from '../models/types';
import {
  selectChatId,
  selectChatsSignalRConnection,
  selectUserId,
  setChatsConnection,
  setFooterHeight,
  setShowLastMessage,
  setUserId,
} from '../reducers/chats';
import { selectTranslations } from '../reducers/translations';
import Calls from './Calls';
import ChatFooter from './ChatFooter';
import ChatHeader from './ChatHeader';
import MessagesContainer from './MessagesContainer';

type Props = {
  instance: any;
  baseURL: string;
  signalRBaseURL: string;
  accessToken: string | null;
  callSignalRConnection?: any;
  endpoints: Endpoints;
  sendMessageFormType: FormTypes;
  sendMessageAdditionalParams: any;
  colorSchema: ColorSchema;
  videoCall: boolean;
  phoneCall: boolean;
  allowAttachments: boolean;
  attachmentMaxSizeInMB?: number;
  attachmentTypesAllowed?: string[];
  pagePath?: string;
  useDispatch: any;
  useSelector: any;
  chatHeaderButtonHandler?: any;
  chatHeaderToggleHandler?: any;
};

const Chat: React.FC<Props> = ({
  instance,
  baseURL,
  signalRBaseURL,
  accessToken,
  callSignalRConnection,
  endpoints,
  sendMessageFormType,
  sendMessageAdditionalParams,
  colorSchema,
  videoCall,
  phoneCall,
  allowAttachments,
  attachmentMaxSizeInMB,
  attachmentTypesAllowed,
  pagePath,
  useSelector,
  useDispatch,
  chatHeaderButtonHandler,
  chatHeaderToggleHandler,
}) => {
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId);
  const selectedChatId = useSelector(selectChatId);
  const chatsConnection = useSelector(selectChatsSignalRConnection);
  const [pagination, setPagination] = useState<IPagination>(INITIAL_PAGINATION);
  const translations = useSelector(selectTranslations);

  const [chatDetails, setChatDetails] = useState<ChatDetails | null>(null);
  const [error, setError] = useState<string>('');

  const mobile = useMedia({ maxWidth: 768 });

  useEffect(() => {
    const footerHeight = mobile ? FooterHeight.MOBILE : FooterHeight.DEFAULT;
    dispatch(setFooterHeight(footerHeight));
  }, [mobile]);

  const getChatsDetails = async (pagination: IPagination) => {
    if (!selectedChatId) return;
    const result = await ChatsApi.getChatsDetails(
      instance,
      baseURL + endpoints.chats.details,
      selectedChatId,
      pagination
    );
    if (result.data) {
      const sortedMessages = sortMessages(result.data.messages);
      setChatDetails({ ...result.data, messages: sortedMessages });
      dispatch(setUserId(result.data.communicationsModuleIdentifier));
      setPagination({ ...pagination, pageNumber: pagination.pageNumber + 1 });
    }
    if (result.error) {
      if (
        result.error.message === 'Sorry, something went wrong. Please try again'
      ) {
        setError(translations?.defaultError ?? result.error.message);
      } else {
        setError(result.error.message);
      }
    }
  };

  useEffect(() => {
    setPagination(INITIAL_PAGINATION);
    setChatDetails(null);

    getChatsDetails(INITIAL_PAGINATION);
  }, [selectedChatId]);

  // open signalR connection for active chat
  useEffect(() => {
    if (!accessToken || !selectedChatId) return;
    const connection: signalR.HubConnection = createSignalRConnection(
      signalRBaseURL,
      selectedChatId,
      accessToken
    );

    dispatch(setChatsConnection(connection));
  }, [accessToken, selectedChatId]);

  const showLastMessage = () => {
    dispatch(setShowLastMessage(true));
  };

  useEffect(() => {
    if (!chatsConnection || !userId) return;

    receiveMessages(chatsConnection, setChatDetails, userId, showLastMessage);

    startConnection(chatsConnection);

    return () => {
      chatsConnection.stop();
    };
  }, [chatsConnection, userId]);

  return (
    <div className="Chat">
      {!!selectedChatId && (
        <ChatHeader
          chatDetails={chatDetails}
          endpoints={endpoints}
          instance={instance}
          baseURL={baseURL}
          colorSchema={colorSchema}
          videoCall={videoCall}
          phoneCall={phoneCall}
          pagePath={pagePath}
          useDispatch={useDispatch}
          useSelector={useSelector}
          chatHeaderButtonHandler={chatHeaderButtonHandler}
          chatHeaderToggleHandler={chatHeaderToggleHandler}
        />
      )}
      <Calls
        callSignalRConnection={callSignalRConnection}
        useDispatch={useDispatch}
        useSelector={useSelector}
      />
      <MessagesContainer
        chatDetails={chatDetails}
        setChatDetails={setChatDetails}
        error={error}
        colorSchema={colorSchema}
        instance={instance}
        baseURL={baseURL}
        endpoints={endpoints}
        pagination={pagination}
        setPagination={setPagination}
        useDispatch={useDispatch}
        useSelector={useSelector}
        chatSelected={!!selectedChatId}
      />
      {selectedChatId && (
        <ChatFooter
          chatDetails={chatDetails}
          baseURL={baseURL}
          instance={instance}
          setChatDetails={setChatDetails}
          endpoints={endpoints}
          sendMessageFormType={sendMessageFormType}
          sendMessageAdditionalParams={sendMessageAdditionalParams}
          colorSchema={colorSchema}
          allowAttachments={allowAttachments}
          attachmentMaxSizeInMB={attachmentMaxSizeInMB}
          attachmentTypesAllowed={attachmentTypesAllowed}
          mobile={mobile}
          useDispatch={useDispatch}
          useSelector={useSelector}
        />
      )}
    </div>
  );
};

export default Chat;
