import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { selectEvents, setEventsList } from '../reducers/calendar';
import { updatedMeetingStatus } from '../helpers/calendarViews';
import FormTextField from './FormTextField';
import RightSideMenu from './RightSideMenu';
import { translations } from '../helpers/translations';
type Props = {
  id: number;
  isOpen: boolean;
  disabled: boolean;
  closeMenu: () => void;
  declineMeetingUrl: string;
  signOut: () => {};
  instance: any;

  useDispatch: any;
  useSelector: any;
};
const DeclineForm: React.FC<Props> = ({
  id,
  isOpen,
  disabled,
  closeMenu,
  declineMeetingUrl,
  instance,
  signOut,
  useDispatch,
  useSelector,
}) => {
  const dispatch = useDispatch();
  const events = useSelector(selectEvents);

  const [generalErrors, setGeneralErrors] = useState<string[]>([]);

  const initialValues = {
    note: '',
  };

  const validationSchema = Yup.object({
    note: Yup.string().required(translations.errors.required ?? ''),
  });

  const handleSubmitForm = (values: any, formik: any) => {
    const bodyObj = {
      ...values,
      eventsId: id,
    };

    instance
      .post(declineMeetingUrl, bodyObj)
      .then((res: any) => {
        if (res.status === 200) {
          if (res.data.status === '1') {
            const updatedList = updatedMeetingStatus(events, 'Declined', id);
            dispatch(setEventsList(updatedList));
            closeMenu();
          }
        }
      })
      .catch((err: any) => {
        console.log(err);
        if (err.response && err.response.status === 403) {
          dispatch(signOut());
        }

        setGeneralErrors([
          translations.errors.something_went_wrong_try_again ?? '',
        ]);
        formik.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, formik) => handleSubmitForm(values, formik)}
      validationSchema={validationSchema}
    >
      {formik => (
        <Form>
          <RightSideMenu
            title={translations.general_messages.add_note ?? ''}
            isOpen={isOpen}
            // closeMenu={closeMenu}
            disabled={false}
            buttonText1={translations.general_messages.cancel ?? ''}
            buttonText2={translations.general_messages.decline ?? ''}
            button2Loading={formik.isSubmitting}
            errors={generalErrors}
          >
            <div className="ConnetWithATherapistForm">
              <FormTextField
                field="note"
                type="textarea"
                placeholder={
                  translations.general_messages.type_your_message_here ?? ''
                }
                required={true}
                maxLength={1500}
                rows={15}
              />
            </div>
          </RightSideMenu>
        </Form>
      )}
    </Formik>
  );
};

export default DeclineForm;
