export enum FooterHeight {
  DEFAULT = 70,
  DEFAULT_EXPANDED = 132,
  MOBILE = 130,
  MOBILE_EXPANDED = 190,
}

export const CHATS_HEADER = 85;

export const NUMBER_OF_MESSAGES_PER_PAGE = 10;

export const INITIAL_PAGINATION = {
  pageNumber: 1,
  pageSize: NUMBER_OF_MESSAGES_PER_PAGE,
};

export const NUMBER_OF_CONTACTS_PER_PAGE = 100;

export const INITIAL_CONTACTS_PAGINATION = {
  pageNumber: 1,
  pageSize: NUMBER_OF_CONTACTS_PER_PAGE,
};
