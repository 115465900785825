import React from 'react';
import { ThreeBounce } from 'better-react-spinkit';

interface SpinnerProps {
  size?: number;
  color?: string;
  scaleStart?: number;
  scaleEnd?: number;
  positionAbsolute?: boolean;
  style?: { [key: string]: string | number };
}

const Spinner: React.FC<SpinnerProps> = ({
  size = 10,
  color = '#d5d4d4',
  positionAbsolute,
  style,
}) => {
  return (
    <div
      className={positionAbsolute ? 'Spinner Absolute' : 'Spinner'}
      style={
        { ...style, width: 'fit-content' } ?? {
          width: 'fit-content',
        }
      }
    >
      <ThreeBounce size={size} color={color} />
    </div>
  );
};

export { Spinner };
