import React, { useEffect } from 'react';
// import { navigate } from '@reach/router';
import {
  selectChatId,
  setLabels,
  setShowActionsPanel,
} from '../reducers/chats';
import { CallTypes } from '../models/Calls';
import { ChatDetails } from '../models/Chats';
import { ColorSchema, Endpoints } from '../models/types';
import { joinCall } from '../helpers/joinCall';
import { setRoom } from '../reducers/calls';
import { ChatsApi } from '../api/ChatsApi';
import Button from './ui/Button';

// icons;
import PhoneIcon from '../assets/Icon_PhoneCall_01.png';
import VideoIcon from '../assets/Icon_VideoCall_01.png';
import BackIcon from '../assets/Icon_Back.png';
import DeleteIcon from '../assets/Icon_Action_03.png';
import { selectTranslations } from '../reducers/translations';
import ToggleSwitch from './ToggleSwitch';

interface IChatHeader {
  instance: any;
  endpoints: Endpoints;
  chatDetails: ChatDetails | null;
  baseURL: string;
  colorSchema: ColorSchema;
  videoCall: boolean;
  phoneCall: boolean;
  pagePath?: string;
  useDispatch: any;
  useSelector: any;
  chatHeaderButtonHandler?: any;
  chatHeaderToggleHandler?: any;
}

const ChatHeader: React.FC<IChatHeader> = ({
  chatDetails,
  endpoints,
  instance,
  baseURL,
  colorSchema,
  videoCall,
  phoneCall,
  pagePath,
  useSelector,
  useDispatch,
  chatHeaderButtonHandler,
  chatHeaderToggleHandler,
}) => {
  const dispatch = useDispatch();
  const selectedChatId = useSelector(selectChatId);
  const translations = useSelector(selectTranslations);
  const setRoomInstance = (room: any) => dispatch(setRoom(room));

  useEffect(() => {
    if (chatDetails?.labels) {
      dispatch(setLabels(chatDetails.labels));
    }
  }, [chatDetails]);

  const handleDeleteChat = async () => {
    if (!selectedChatId || !endpoints.chats.deleteChat) return;
    const result = await ChatsApi.deleteChat(
      selectedChatId,
      endpoints.chats.deleteChat,
      instance
    );
    if (!result.error) {
      pagePath && window.open(pagePath, '_self');
    }
  };

  let chatHeaderButtonClickHandler = null;
  let chatHeaderButtonCheck = null;

  if (chatHeaderButtonHandler) {
    ({
      chatHeaderButtonClickHandler,
      chatHeaderButtonCheck,
    } = chatHeaderButtonHandler);
  }

  let chatHeaderToggleCheck = null;
  let chatHeaderToggleClickHandler = null;
  let chatHeaderToggleExternalValue = null;
  if (chatHeaderToggleHandler) {
    ({
      chatHeaderToggleCheck,
      chatHeaderToggleClickHandler,
      chatHeaderToggleExternalValue,
    } = chatHeaderToggleHandler);
  }

  return (
    <header className="ChatHeader WidthContent">
      <div className="ChatHeaderContainer">
        <Button
          color="transparent"
          className="BackButton"
          onClick={() => dispatch(setShowActionsPanel(true))}
        >
          <img src={BackIcon} alt="Back" />
        </Button>
        <div className="ChatDetails">
          {chatDetails && (
            <>
              <h2 title={chatDetails?.title}>{chatDetails?.title}</h2>
              <span>{chatDetails?.description}</span>
              {chatHeaderButtonCheck && chatHeaderButtonClickHandler && (
                <Button
                  color="transparent"
                  onClick={chatHeaderButtonClickHandler}
                >
                  {translations?.chatHeaderButtonText ??
                    'View Hiring Manager Details'}
                </Button>
              )}
              {chatHeaderToggleCheck && chatHeaderToggleExternalValue !== null && (
                <ToggleSwitch
                  onChange={chatHeaderToggleClickHandler}
                  externalValue={chatHeaderToggleExternalValue}
                >
                  {translations?.chatHeaderToggleText ??
                    'Share my details with Hiring Manager'}
                </ToggleSwitch>
              )}
            </>
          )}
        </div>

        {selectedChatId &&
          (videoCall || phoneCall || endpoints.chats.deleteChat) && (
            <div className="Actions">
              {videoCall && (
                <Button
                  color={colorSchema.primaryColor}
                  onClick={() =>
                    joinCall(
                      instance,
                      baseURL,
                      selectedChatId,
                      CallTypes.VIDEO,
                      setRoomInstance
                    )
                  }
                >
                  <img src={VideoIcon} alt={'Video call'} />
                  <span>
                    {translations?.chatHeaderVideoCall ?? 'Video call'}
                  </span>
                </Button>
              )}

              {phoneCall && (
                <Button
                  color={colorSchema.primaryColor}
                  onClick={() =>
                    joinCall(
                      instance,
                      baseURL,
                      selectedChatId,
                      CallTypes.VOICE,
                      setRoomInstance
                    )
                  }
                >
                  <img src={PhoneIcon} alt="Phone call" />
                  <span>
                    {translations?.chatHeaderPhoneCall ?? 'Phone call'}
                  </span>
                </Button>
              )}
              {endpoints.chats.deleteChat && pagePath && (
                <Button
                  color="transparent"
                  className="DeleteButton"
                  onClick={handleDeleteChat}
                >
                  <img src={DeleteIcon} alt="Back" />
                </Button>
              )}
            </div>
          )}
      </div>
    </header>
  );
};

export default ChatHeader;
