import { endpoints } from '../endpoints.config';
import { CallsParser, CallTypes } from '../models/Calls';
import { ChatsParser } from '../models/Chats';
import { BaseApi } from './baseApi';

const UNKNOWN_ERROR_MESSAGE = 'Sorry, something went wrong. Please try again';

export class CallsApi extends BaseApi {
  public static joinRoom = async (
    instance: any,
    baseURL: string,
    selectedChatId: number,
    type: CallTypes
  ): Promise<any> => {
    const payload = CallsParser.buildJoinRoomPayload(selectedChatId, type);
    try {
      const result = await instance.post(
        baseURL + endpoints.communicationModule.calls.startChatCall,
        payload
      );

      if (CallsApi.isResponseSuccessful(result.data)) {
        const data = result.data.details;
        return CallsApi.successResponse(data);
      } else {
        const errors = result.data.errors;
        if (errors) {
          const message = ChatsParser.parseError(errors);
          return CallsApi.errorResponse(message);
        } else {
          throw new Error(); // Caught below to trigger unknown error
        }
      }
    } catch (error) {
      if (error.response) {
        const errors = error.response.data.errors;
        if (errors) {
          const message = ChatsParser.parseError(errors);
          return CallsApi.errorResponse(message);
        }
      }
      return CallsApi.errorResponse(UNKNOWN_ERROR_MESSAGE);
    }
  };
}
