import React from "react";
import { FieldAttributes, useField } from "formik";

function Input<T>(props: FieldAttributes<T>) {
  const [field, meta] = useField(props);
  const showError = meta.touched && meta.error;
  if (props.type === "textarea") {
    return (
      <textarea
        {...(field as any)}
        className={`EditBox${showError ? " ErrorInput" : ""}`}
        {...props}
      ></textarea>
    );
  }
  return (
    <input
      {...(field as any)}
      className={`EditBox${showError ? " ErrorInput" : ""} ${
        props.disabled ? "ViewOnly" : ""
      }`}
      disabled={props.disabled === true}
      {...props}
    />
  );
}

export { Input };
