import React, { useEffect, useState } from 'react';
import Track from './Track';
// import DefaultImage from '../../../assets/Img_Default_Player.jpg';
import { createLocalVideoTrack } from 'twilio-video';

type Props = {
  localParticipant: boolean;
  index: string;
  participant: any;
  isLocalVideoTrackDisabled: boolean;
  isLocalAudioTrackDisabled: boolean;
  callType: string;
};

const Participant: React.FC<Props> = ({
  localParticipant,
  index,
  participant,
  isLocalAudioTrackDisabled,
  isLocalVideoTrackDisabled,
  callType,
}) => {
  const existingPublications = Array.from(participant.tracks.values());
  const existingTracks = existingPublications.map(
    (publication: any) => publication.track
  );
  const nonNullTracks = existingTracks.filter(track => track !== null);

  const [tracks, setTracks] = useState(nonNullTracks);

  const addTrack = (track: any) => {
    setTracks(prevState => {
      const newState = [...prevState, track];
      return newState;
    });
  };

  const removeTrack = (track: any) => {
    setTracks(prevState => {
      const newState = [...prevState].filter((t: any) => t.kind !== track.kind);
      return newState;
    });
  };
  useEffect(() => {
    if (!localParticipant) {
      participant.on('trackSubscribed', (track: any) => {
        addTrack(track);
      });
    }
  }, []);

  useEffect(() => {
    if (!localParticipant) {
      participant.on('trackUnsubscribed', (track: any) => {
        removeTrack(track);
      });
    }
  }, []);

  const toggleLocalVideo = (isLocalVideoTrackDisabled: boolean) => {
    if (!localParticipant) return;

    if (!isLocalVideoTrackDisabled) {
      if ([...participant.videoTracks.values()].length === 0) {
        createLocalVideoTrack().then(track => {
          const localMediaContainer = document.getElementById(
            'LocalTrack video'
          );
          localMediaContainer &&
            localMediaContainer.appendChild(track.attach());
          return participant.publishTrack(track);
        });
      } else {
        const track = [...participant.videoTracks.values()][0].track;
        const localMediaContainer = document.getElementById('LocalTrack video');
        localMediaContainer && localMediaContainer.appendChild(track.attach());
      }
    } else {
      const track = [...participant.videoTracks.values()][0].track;
      // track.disable();
      track.stop();
      track.detach().forEach((element: any) => element.remove());
      participant.unpublishTrack(track);
    }
  };

  useEffect(() => {
    if (callType === 'Voice') return;

    toggleLocalVideo(isLocalVideoTrackDisabled);
  }, [isLocalVideoTrackDisabled]);

  return (
    <div
      className="Participant"
      id={participant.identity + index}
    //   style={{ backgroundImage: `url(${DefaultImage})` }}
    >
      <div className="Identity">{participant.identity}</div>
      {tracks.map(track => (
        <Track
          participant={participant}
          key={track}
          track={track}
          isLocalAudioTrackDisabled={isLocalAudioTrackDisabled ?? false}
          isLocalVideoTrackDisabled={isLocalVideoTrackDisabled ?? false}
          localParticipant={localParticipant}
        />
      ))}
    </div>
  );
};

export default Participant;
