import { useRef, useLayoutEffect } from 'react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export function useLockBody<T extends HTMLDivElement>(toggle = true) {
  const ref = useRef<T>(null);

  useLayoutEffect(() => {
    if (toggle && ref.current !== null) {
      disableBodyScroll(ref.current);
    }
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [toggle]);

  return { ref };
}
