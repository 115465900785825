import React from 'react';
import {
  FilterOptions,
  selectFilterOption,
  selectListFilter,
} from '../reducers/chats';
import { ColorSchema } from '../models/types';
import { selectTranslations } from '../reducers/translations';

type FilterProps = {
  colorSchema: ColorSchema;
  setUpdateChatsList: React.Dispatch<React.SetStateAction<boolean>>;
  hasContactList: boolean;
  useDispatch: any;
  useSelector: any;
};

const Filters: React.FC<FilterProps> = ({
  colorSchema,
  setUpdateChatsList,
  hasContactList,
  useSelector,
  useDispatch,
}) => {
  const dispatch = useDispatch();
  const selectedFilter = useSelector(selectFilterOption);
  const translations = useSelector(selectTranslations);

  return (
    <div className="FiltersContainer">
      <div
        className={`Filter Left ${!hasContactList && 'Inactive'}`}
        style={{
          borderColor: colorSchema.primaryColor,
          color: `${
            selectedFilter === FilterOptions.RECENT
              ? '#fff'
              : colorSchema.primaryColor
          }`,
          background: `${
            selectedFilter === FilterOptions.RECENT
              ? colorSchema.primaryColor
              : '#fff'
          }`,
        }}
        onClick={() => {
          if (!hasContactList) return;
          dispatch(selectListFilter(FilterOptions.RECENT));
          setUpdateChatsList(true);
          // navigate(pagePath);
        }}
      >
        {translations?.filtersRecent ?? 'Recent'}
      </div>
      {hasContactList && (
        <div
          className={`Filter Right`}
          style={{
            borderColor: colorSchema.primaryColor,
            color: `${
              selectedFilter === FilterOptions.CONTACT
                ? '#fff'
                : colorSchema.primaryColor
            }`,
            background: `${
              selectedFilter === FilterOptions.CONTACT
                ? colorSchema.primaryColor
                : '#fff'
            }`,
          }}
          onClick={() => {
            dispatch(selectListFilter(FilterOptions.CONTACT));
            setUpdateChatsList(true);
            // navigate(pagePath);
          }}
        >
          {translations?.filtersContact ?? 'Contact'}
        </div>
      )}
    </div>
  );
};

export default Filters;
