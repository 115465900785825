import React from 'react';
import classNames from 'classnames';

type ButtonVariety = 'full' | 'small' | 'blue' | 'slim' | '';

type ButtonColor =
  | 'blue'
  | 'lightBlue'
  | 'white'
  | 'transparent'
  | 'red'
  | 'green'
  | 'yellow'
  | 'lightRed'
  | 'paleBlue'
  | 'darkGreen';

type ButtonBorder = 'dashed' | '';

type Button = JSX.IntrinsicElements['button'] & {
  color?: ButtonColor;
  variety?: ButtonVariety;
  border?: ButtonBorder;
  disabled?: boolean;
  onClick?: () => void;
  type?: string;
};

function varietyToClassname(v: ButtonVariety): string {
  switch (v) {
    case 'full':
      return 'BtnFull';
    case 'small':
      return 'BtnSmall';
    case 'slim':
      return 'BtnSlim';
    default:
      return '';
  }
}

function colorToClassname(c?: ButtonColor): string {
  switch (c) {
    case 'blue':
      return 'BtnBlue';
    case 'lightBlue':
      return 'BtnLightBlue';
    case 'white':
      return 'BtnWhite';
    case 'red':
      return 'BtnRed';
    case 'transparent':
      return 'BtnTransparent';
    case 'green':
      return 'BtnGreen';
    case 'yellow':
      return 'BtnYellow';
    case 'lightRed':
      return 'BtnLightRed';
    case 'paleBlue':
      return 'BtnPaleBlue';
    case 'darkGreen':
      return 'BtnDarkGreen';
    default:
      return '';
  }
}

function borderToClassname(b?: ButtonBorder): string {
  switch (b) {
    case 'dashed':
      return 'BtnDashed';
    default:
      return '';
  }
}

const Button: React.FC<Button> = ({
  variety = '',
  color,
  border = '',
  className,
  ...props
}) => (
  <button
    className={classNames(
      'CalendarBtn',
      colorToClassname(color),
      varietyToClassname(variety),
      borderToClassname(border),
      className
    )}
    {...props}
  />
);

export default Button;
