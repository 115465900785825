import { IPagination } from '../models/Calls';
import { ChatsParser } from '../models/Chats';
import { FormTypes } from '../models/types';
import { BaseApi } from './baseApi';

const UNKNOWN_ERROR_MESSAGE = 'Sorry, something went wrong. Please try again';

export class ChatsApi extends BaseApi {
  public static getChatsDetails = async (
    instance: any,
    url: string,
    selectedChatId: number,
    pagination: IPagination
  ): Promise<any> => {
    const { pageNumber, pageSize } = pagination;
    try {
      const result = await instance.get(
        url +
          '?chatsId=' +
          selectedChatId +
          '&pageNumber=' +
          pageNumber +
          '&pageSize=' +
          pageSize
      );

      if (ChatsApi.isResponseSuccessful(result.data)) {
        const data = result.data.details;
        return ChatsApi.successResponse(data);
      } else {
        const errors = result.data.errors;
        if (errors) {
          const message = ChatsParser.parseError(errors);
          return ChatsApi.errorResponse(message);
        } else {
          throw new Error(); // Caught below to trigger unknown error
        }
      }
    } catch (error) {
      if (error.response) {
        const errors = error.response.data.errors;
        if (errors) {
          const message = ChatsParser.parseError(errors);
          return ChatsApi.errorResponse(message);
        }
      }
      return ChatsApi.errorResponse(UNKNOWN_ERROR_MESSAGE);
    }
  };

  public static getChatsList = async (
    instance: any,
    url: string
  ): Promise<any> => {
    try {
      const result = await instance.get(
        url + '&MaxResults=999'
      );

      if (ChatsApi.isResponseSuccessful(result.data)) {
        const data = result.data.details;
        return ChatsApi.successResponse(data);
      } else {
        const errors = result.data.errors;
        if (errors) {
          const message = ChatsParser.parseError(errors);
          return ChatsApi.errorResponse(message);
        } else {
          throw new Error(); // Caught below to trigger unknown error
        }
      }
    } catch (error) {
      if (error.response) {
        const errors = error.response.data.errors;
        if (errors) {
          const message = ChatsParser.parseError(errors);
          return ChatsApi.errorResponse(message);
        }
      }
      return ChatsApi.errorResponse(UNKNOWN_ERROR_MESSAGE);
    }
  };

  public static getChatIdForContact = async (
    instance: any,
    url: string,
    contactsId: number
  ): Promise<any> => {
    const payload = { contactsId };
    try {
      const result = await instance.post(url, payload);

      if (ChatsApi.isResponseSuccessful(result.data)) {
        const data = result.data.details;
        return ChatsApi.successResponse(data);
      } else {
        const errors = result.data.errors;
        if (errors) {
          const message = ChatsParser.parseError(errors);
          return ChatsApi.errorResponse(message);
        } else {
          throw new Error(); // Caught below to trigger unknown error
        }
      }
    } catch (error) {
      if (error.response) {
        const errors = error.response.data.errors;
        if (errors) {
          const message = ChatsParser.parseError(errors);
          return ChatsApi.errorResponse(message);
        }
      }
      return ChatsApi.errorResponse(UNKNOWN_ERROR_MESSAGE);
    }
  };

  public static sendMessage = async (
    instance: any,
    baseURL: string,
    selectedChatId: number,
    message: string,
    attachments: File[],
    connectionId: any,
    formType: FormTypes,
    sendMessageAdditionalParams: any
  ): Promise<any> => {
    const payload = ChatsParser.buildSendMassagePayload(
      selectedChatId,
      message,
      attachments,
      connectionId,
      formType,
      sendMessageAdditionalParams
    );
    try {
      const result = await instance.post(baseURL, payload);

      if (ChatsApi.isResponseSuccessful(result.data)) {
        const data = result.data.details;
        return ChatsApi.successResponse(data);
      } else {
        const errors = result.data.errors;
        if (errors) {
          const message = ChatsParser.parseError(errors);
          return ChatsApi.errorResponse(message);
        } else {
          throw new Error(); // Caught below to trigger unknown error
        }
      }
    } catch (error) {
      if (error.response) {
        const errors = error.response.data.errors;
        if (errors) {
          const message = ChatsParser.parseError(errors);
          return ChatsApi.errorResponse(message);
        }
      }
      return ChatsApi.errorResponse(UNKNOWN_ERROR_MESSAGE);
    }
  };

  public static deleteChat = async (
    chatsId: number,
    url: string,
    instance: any
  ): Promise<any> => {
    const payload = { chatsId };
    try {
      const result = await instance.post(url, payload);

      if (ChatsApi.isResponseSuccessful(result.data)) {
        const data = result.data.details;
        return ChatsApi.successResponse(data);
      } else {
        const errors = result.data.errors;
        if (errors) {
          const message = ChatsParser.parseError(errors);
          return ChatsApi.errorResponse(message);
        } else {
          throw new Error(); // Caught below to trigger unknown error
        }
      }
    } catch (error) {
      if (error.response) {
        const errors = error.response.data.errors;
        if (errors) {
          const message = ChatsParser.parseError(errors);
          return ChatsApi.errorResponse(message);
        }
      }
      return ChatsApi.errorResponse(UNKNOWN_ERROR_MESSAGE);
    }
  };
}
