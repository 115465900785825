import React from 'react';
import { getDate } from 'date-fns';
import { Day } from '../models/types';

type Props = {
  day: Day;
};

const DayHeader: React.FC<Props> = ({ day }) => {
  return (
    <div>
      <div className="DayHeader">
        <div className="HourColumn"></div>
        <div className="SelectedDay">
          <span>{getDate(day.date)}</span>
          <span>{day.weekDayName}</span>
        </div>
      </div>
    </div>
  );
};

export default DayHeader;
