import React from 'react';
import { decodeHTML, parseDateAndTime } from '../helpers/parseData';
import { MessageItem } from '../models/Chats';
import { ColorSchema, Endpoints } from '../models/types';

//components
import AttachmentsContainer from './AttachmentsContainer';

type Props = {
  message: MessageItem;
  colorSchema: ColorSchema;
  instance: any;
  baseURL: string;
  endpoints: Endpoints;
  useDispatch: any;
  useSelector: any;
};

const Message: React.FC<Props> = ({
  message,
  colorSchema,
  instance,
  baseURL,
  endpoints,
  useSelector,
  useDispatch,
}) => {
  const { type, senderName, addDate, attachments, content } = message;
  return (
    <div
      className={`MessageContainer ${
        message.type === 'Sent' ? 'Sent' : 'Received'
      }`}
    >
      <div
        className={`Message`}
        style={{
          background: type === 'Sent' ? colorSchema.secondaryColor : '#f5f5f5',
        }}
      >
        <div className="MessageDetails">
          {message.type === 'Received' && <span>{senderName}</span>}
          <span>{parseDateAndTime(addDate, 'dateTime')}</span>
        </div>
        <div
          style={{ fontWeight: message.bUnread ? 700 : 400 }}
          dangerouslySetInnerHTML={{
            __html: decodeHTML(content.replace(/\n/gi, '<br/>')),
          }}
        ></div>
        <AttachmentsContainer
          attachments={attachments}
          instance={instance}
          baseURL={baseURL}
          endpoints={endpoints}
          useDispatch={useDispatch}
          useSelector={useSelector}
        />
      </div>
    </div>
  );
};

export default Message;
